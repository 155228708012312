import React from 'react';
import {motion} from 'framer-motion'
import jobScreen from '../assets/Icons/jobScreen.png'

const Jobs = () => {
    return (
        <motion.div className="relative min-h-screen items-center flex flex-col overflow-hidden text-center justify-center">
                <img src={jobScreen} className="h-96 w-96"/>
                <motion.div className="text-5xl font-bold text-black">
                We've been working on something special and it's almost ready! Stay tuned for the big reveal.
                </motion.div>
        </motion.div>
    )
}

export default Jobs