// import React, { Component } from 'react';
// import {motion} from 'framer-motion'
// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
// import {ImLocation} from 'react-icons/im'

// class MapContainer extends Component {
//   render() {
//     const mapStyles = {
//       width: '300px',
//       height: '200px',
//       // borderRadius: '50px'
//     };
//     const pinLocation = { lat: this.props.latitude, lng: this.props.longitude };

//     return (
//       <motion.div className="rounded-lg">
//         <Map
//           google={this.props.google}
//           zoom={14}
//           style={mapStyles}
//           initialCenter={pinLocation}
//           mapTypeControl={false}
//           keyboardShortcuts={false}
//           fullscreenControl={false}
//       streetViewControl={false}
//         zoomControl={false}
//         />
//         <Marker
//           position={pinLocation}
//           icon={<ImLocation className={'text-[red] text-2xl'}/>}
//         />
//       </motion.div>
//     );
//   }
// }

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyDx96RmVyQLW3UvDVqELBxFSJzOzC5DhtQ'
// })(MapContainer);


import React, { useEffect,useState } from 'react';
import { motion } from 'framer-motion';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { ImLocation } from 'react-icons/im';

function MapContainer(props) {
  const mapStyles = {
    width: '300px',
    height: '200px',
    // borderRadius: '50px'
  };

  const [pinLocation,setPinLocation] = useState()
  useEffect(()=>{
      setPinLocation({ lat: props.latitude, lng: props.longitude })
  },[props])

  return (
    <motion.div className="rounded-lg">
      <Map
        google={props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={pinLocation}
        mapTypeControl={false}
        keyboardShortcuts={false}
        fullscreenControl={false}
        streetViewControl={false}
        zoomControl={false}
      />
      <Marker position={pinLocation} icon={<ImLocation className={'text-[red] text-2xl'} />} />
    </motion.div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDx96RmVyQLW3UvDVqELBxFSJzOzC5DhtQ'
})(MapContainer);
