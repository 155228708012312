import React from "react";
import { motion } from "framer-motion";

const Photos = ({ imageSet }) => {
  return (
    <motion.div
      className={`relative w-full h-full flex-wrap grid md:grid-cols-6 sm:grid-cols-1 gap-5 sm:gap-3 mt-2`}
    >
      {imageSet.map((c, i) => {
        return (
          <motion.div className={`relative rounded-lg`} key={i}>
            <img
              src={c}
              alt=""
              className="rounded-2xl"
              style={{ height: "200px", width: "200px" }}
            />
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default Photos;
