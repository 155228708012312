import React from 'react'
import { motion } from 'framer-motion'
import { IoArrowBackCircleSharp } from 'react-icons/io5'
import logo from '../../assets/minlogo.jpeg'
import forgotPasss from '../../assets/forgotPasss.png'
import FieldInput from '../../Components/FieldInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from '../../Components/Button'
import API from '../../axios/API'
import { useHistory } from 'react-router-dom'

const initialFormValues = {
    emailOrPhone: ''
}

const ForgotPassword = () => {

    const history = useHistory()

    const forgotPassSchema = Yup.object().shape({
        emailOrPhone: Yup.string().required("Email or phone is required")
    })

    const forgotPass = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: forgotPassSchema,
        onSubmit: (values) => {
            let details = {
                email: values.emailOrPhone.split('').includes('@') ? values.emailOrPhone : null,
                phone: values.emailOrPhone.split('').includes('@') ? null : values.emailOrPhone,
                emailEnable: values.emailOrPhone.split('').includes('@') ? true : false
            }
            API.post('/auth/accountVerification', details).then((res) => {
                if (res.status === 200) {
                    history.push('/auth/otp', { state: { token: res.data.body.token, userId: values.emailOrPhone, emailEnable: details.emailEnable } })
                }
            })
        }
    })

    return (
        <motion.div className={'min-h-screen flex flex-col items-center justify-center  relative bg-[lightGrey]'}>
            <motion.div className={'bg-white relative dropshadow-lg rounded-2xl px-2 py-4 md:w-2/4 w-80'}>
                <motion.div className={'flex flex-row justify-between'}>
                    <motion.div className="sticky">
                        <IoArrowBackCircleSharp className="h-6 w-6 cursor-pointer" onClick={() => history.push('/auth/login')} />
                    </motion.div>
                    <motion.div>
                        <img src={logo} className="h-14 w-14" />
                    </motion.div>
                </motion.div>
                <motion.div className={'flex flex-col items-center text-center'}>
                    <motion.div>
                        <img src={forgotPasss} className="h-28 w-28" />
                    </motion.div>
                    <motion.div className="text-2xl mt-2">
                        <p>Forgot Password ?</p>
                    </motion.div>
                    <motion.div className="text-sm mt-2 text-[#A4A5AA]">
                        <p>Enter the email or phone number associated with your account and</p>
                        <p>we'll send an email or phone number with instructions to reset your password</p>
                    </motion.div>
                </motion.div>
                <motion.div className="mt-2 mb-2  px-14">
                    <FieldInput label={'Email or phone number'} fieldMeta={forgotPass.getFieldMeta('emailOrPhone')} fieldProps={forgotPass.getFieldProps("emailOrPhone")} fieldHelper={forgotPass.getFieldHelpers('emailOrPhone')} edit={true} dataLoaded={true} />
                </motion.div>
                <motion.div className='mt-2 mb-2 px-10 flex flex-row text-center justify-center items-center'>
                    <Button injectClass={'w-64 text-xl'} onClick={() => forgotPass.submitForm()}>Continue</Button>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default ForgotPassword