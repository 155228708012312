import React from "react"
import { motion } from 'framer-motion'
import { FaPen } from "react-icons/fa"
import defCert from '../../../assets/Icons/defCert.png'
import { useHistory } from "react-router-dom"

const licences = [
    { institutionName: 'University of cambridge', title: 'BEC Vantage', from: 'Nov 2016', to: '', isExpire: true }
]

const License = ({license,edit}) => {

    const history =  useHistory()
    return (
        <motion.div className={`relative w-full ${license === null || license === 'null' && 'h-48'} text-black flex flex-col md:drop-shadow-xl md:rounded-3xl bg-white mt-2 md:px-2 md:py-4`}>
            <motion.div className={`flex flex-row justify-between md:m-4 m-3`}>
                <motion.div className={`text-xl font-bold`}>
                    Licenses & Certificates
                </motion.div>
                {edit && <motion.div className={`relative cursor-pointer rounded-full backdrop-blur-sm bg-[#2593D2]/30`} style={{ height: '30px', width: '30px' }} onClick={()=>history.push("/editProfile")}>
                    <FaPen className="text-white text-sm m-[8px]" />
                </motion.div>}
            </motion.div>
            <>
                {
                    license ?  license.license.map((c) => {
                        return <>
                            {
                                c.isActive ? <motion.div className="flex flex-col px-5 md:px-10">
                                <motion.div className={`flex flex-row justify-start gap-5 mb-2`}>
                                    <motion.div className={`bg-white drop-shadow-lg rounded-xl h-fit`}>
                                        <img alt="logo" src={c && c.licenseLogo ? c.licenseLogo : defCert   } height={50} width={50} />
                                    </motion.div>
                                    <motion.div className="flex flex-col">
                                        <motion.div className={'text-black text-base'}>
                                            {c.title}
                                        </motion.div>
                                        <motion.div className="text-[grey] text-sm">
                                            {c.institutionName}
                                        </motion.div>
                                        <motion.div className="text-black text-base font-bold">
                                            Issued {c.fromMonth} {c.fromYear} . {!c.isExpired ? 'No Expiration Date' : `${c.toMonth} ${c.toYear}`}
                                        </motion.div>
                                    </motion.div>
                                </motion.div>
                                <motion.div className="h-[1px] bg-[grey] w-full mt-2"></motion.div>
                            </motion.div> : ""
                            }
                        </>
                    }) : edit ? <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">Add Licence & Certificates</motion.div>  : <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">No Licence & Certificates</motion.div>
                }
            </>
        </motion.div>
    )
}

export default License