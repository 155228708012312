import React, { useEffect, useState } from "react"
import { motion } from 'framer-motion'
import { FaPen } from 'react-icons/fa'

import defExp from '../../../assets/Icons/defExp.png'
import { useHistory } from "react-router-dom"
import API from "../../../axios/API"

const Experience = ({ exp, edit }) => {

    const history = useHistory()
    const [countries, setCountries] = useState([])

    useEffect(() => {
        API.get('/menu/countries').then((countryResp) => {
            setCountries(countryResp.data.body.map((c) => ({ text: c.name, value: c.code })))
        })
    }, [])


    return (
        <motion.div className={`relative w-full ${exp === null || exp === 'null' && 'h-72'} text-black flex flex-col md:drop-shadow-xl md:rounded-3xl bg-white mt-2 md:px-2 md:py-4`}>
            <motion.div className={`flex flex-row justify-between md:m-4 m-3`}>
                <motion.div className={`text-xl font-bold`}>
                    Experience
                </motion.div>
                {edit && <motion.div className={`relative cursor-pointer rounded-full backdrop-blur-sm bg-[#2593D2]/30`} style={{ height: '30px', width: '30px' }} onClick={() => history.push("/editProfile")}>
                    <FaPen className="text-white text-sm m-[8px]" />
                </motion.div>}
            </motion.div>
            <>
                {
                    exp ? exp.experience.map((c) => {
                        return <>
                        {
                            c.isActive ?  <motion.div className={`flex flex-row justify-start md:gap-5 px-2 md:px-10`}>
                            <motion.div className={`bg-white drop-shadow-lg rounded-xl mt-2 md:p-2 h-fit`}>
                                <img alt="" src={c && c.companyLogo ? c.companyLogo : defExp} height={30} width={30} />
                            </motion.div>
                            <motion.div className="flex flex-col mt-2">
                                <motion.div className={`flex flex-row justify-between gap-10 mb-2`}>
                                    <motion.div className={`flex flex-col`}>
                                        <motion.div className={'text-black text-base'}>
                                            {c.companyName}
                                        </motion.div>
                                        <motion.div className="text-[grey] text-sm">
                                            {countries && countries.length && countries.map((e) => { if (e.value === c.companyLocation) return e.text })}
                                        </motion.div>
                                        <motion.div className="text-black text-base font-bold">
                                            {c.jobs[0].jobType} - 1 yr 3 mos
                                        </motion.div>
                                    </motion.div>
                                    <motion.div>
                                        {
                                            c.jobs.map((e) => {
                                                return <>{
                                                    e.isActive ? <motion.div className={`flex flex-col`}>
                                                        <motion.div className={'text-black text-base'}>
                                                            {e.jobTitle}
                                                        </motion.div>
                                                        <motion.div className="text-[grey] text-sm">
                                                            {e.fromMonth} {e.fromYear} - {e.isPresent ? 'Present' : `${e.toMonth} ${e.toYear}`}
                                                        </motion.div>
                                                    </motion.div> : ''
                                                }
                                                </>
                                            })
                                        }
                                    </motion.div>
                                </motion.div>
                                <motion.div className="h-[1px] bg-[grey] w-[400px]"></motion.div>
                            </motion.div>
                        </motion.div> : ''
                        }
                        </>
                    }) : edit ? <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">Add Experience</motion.div> : <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">No Experience</motion.div>
                }
            </>
        </motion.div>
    )
}

export default Experience