import React, { useEffect,useState } from "react"
import {motion} from 'framer-motion'
import { FaPen } from "react-icons/fa"
import { useHistory } from "react-router-dom"
import API from "../../../axios/API"
import axios from "axios"

const Language = ({lang,edit}) =>{

    const history =  useHistory()
    const [languages,setLanguages] = useState([])
    const [langLevel,setLangLevel] = useState([])
    useEffect(()=>{
        // API.get('/menu/languages').then((res)=>{
        //     setLanguages(res.data.body.map((c) => ({ text: c.language, value: c.languageId })))
        // })
        axios.all([API.get('/menu/languages'),API.get('/menu/langLevel')]).then((axios.spread((langResp,levelResp)=>{
            setLanguages(langResp.data.body.map((c) => ({ text: c.language, value: c.languageId })))
            setLangLevel(levelResp.data.body.map((c) => ({ text: c.langLevel, value: c.langLevelTypeId })))
        })))
    },[])
    return (
        <motion.div className={`relative w-full flex ${lang===null || lang=== 'null' && 'h-40'} text-black flex-col md:drop-shadow-xl md:rounded-3xl bg-white mt-2 md:px-2 md:py-4`}>
            <motion.div className={`flex flex-row justify-between md:m-4 m-3`}>
                <motion.div className={`text-xl font-bold`}>
                    Languages Known
                </motion.div>
                {edit && <motion.div className={`relative cursor-pointer rounded-full backdrop-blur-sm bg-[#2593D2]/30`} style={{ height: '30px', width: '30px' }} onClick={()=>history.push("/editProfile")}>
                    <FaPen className="text-white text-sm m-[8px]" />
                </motion.div>}
            </motion.div>
            <>
                {
                    lang ? lang.language.map((c)=>{
                        return <>
                            {
                                c.isActive ? <motion.div className="flex flex-col px-5 md:px-10">
                                <motion.div className="flex flex-col">
                                    <motion.div className="text-black font-bold text-sm">
                                        {languages && languages.length && languages.map((e)=>{if(c.language === e.value)return e.text})}
                                    </motion.div>
                                    <motion.div className="text-black text-xs">
                                        {langLevel && langLevel.length && langLevel.map((e)=>{if(e.value === c.languageLevel)return e.text})}
                                    </motion.div>
                                </motion.div>
                                <motion.div className="h-[1px] bg-[grey] w-full mt-1 mb-3"></motion.div>
                            </motion.div> : ''
                            }
                        </>
                    }) :edit ? <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">Add Language</motion.div> : <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">No Language</motion.div>
                }
            </>
        </motion.div>
    )
}

export default Language