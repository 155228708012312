import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import rect1 from "../../../assets/Rect1.png";
import graphIcon from "../../../assets/Icons/graph.png";
import videoIcon from "../../../assets/Icons/videos.png";
import eventIcon from "../../../assets/Icons/events.png";
import shopIcon from "../../../assets/Icons/bshop.png";
import { MdOutlinePermMedia } from "react-icons/md";

const Media = ({ mediaDetails, imageCount, videoCount }) => {
  return (
    <motion.div
      className={`relative w-full flex flex-col md:drop-shadow-xl md:rounded-3xl bg-white md:px-2 md:py-4`}
    >
      <motion.div className={`flex flex-row justify-between md:m-4 m-3`}>
        <motion.div className={`text-xl text-black font-bold`}>
          Media
        </motion.div>
        {/* <motion.div className={`relative cursor-pointer rounded-full backdrop-blur-sm bg-[#2593D2]/30`} style={{ height: '30px', width: '30px' }} onClick={()=>history.push("/editProfile")}>
                    <FaPen className="text-white text-sm m-[8px]" />
                </motion.div> */}
      </motion.div>
      <motion.div
        className={`relative flex flex-row h-52 justify-start gap-10`}
      >
        <motion.div className={`relative`}>
          {mediaDetails &&
            mediaDetails !== undefined &&
            mediaDetails.length > 0 && (
              <img
                alt=""
                src={mediaDetails[0]}
                style={{ height: "200px", width: "200px" }}
              />
            )}
        </motion.div>
        <motion.div className={"relative flex flex-col"}>
          <motion.div className={"relative flex flex-row justify-around"}>
            <img alt="" src={graphIcon} height={50} width={50} />
            <motion.div className={"text-[#2593D2] text-2xl"}>
              {imageCount && imageCount !== undefined && imageCount.length}
            </motion.div>
          </motion.div>

          <motion.div className={"relative flex flex-row justify-around"}>
            <img alt="" src={videoIcon} height={50} width={50} />
            <motion.div className={"text-[#2593D2] text-2xl"}>
              {videoCount && videoCount !== undefined && videoCount.length}
            </motion.div>
          </motion.div>
        </motion.div>
        <motion.div className={"relative flex"}>
          <motion.div className={"relative text-black text-xl"}>
            View Media
          </motion.div>
        </motion.div>
      </motion.div>
      {/* <motion.div className="relative flex flex-col h-48 text-xl font-bold text-center justify-center items-center align-center">
        <>
          <MdOutlinePermMedia className="relative text-5xl" />
          Media Coming Soon...
        </>
      </motion.div> */}
    </motion.div>
  );
};

export default Media;
