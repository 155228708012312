import React, { useEffect, useState } from "react";
import API from "../axios/API";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Login from "../Modules/Auth/Login";
import { useSelector, useDispatch } from "react-redux";
import {
  setLoggedIn,
  setUser,
  setUserDetails,
  setToken,
} from "../store/auth/actions";
import HomeRoot from "./HomeRoot";
import Alert from "../Components/Alerts";
import { setAlert, setNotificationAlert } from "../store/theme/actions";
import Loaders from "../Loaders/Loaders";
import Signup from "../Modules/Auth/Signup";
import ForgotPassword from "../Modules/Auth/ForgotPassword";
import ResetPassword from "../Modules/Auth/ResetPassword";
import OTP from "../Modules/Auth/OTP";
import EndUser from "../Modules/Policies/EndUser";
import PrivacyPolicy from "../Modules/Policies/PrivacyPolicy";
import CookiesPolicy from "../Modules/Policies/CookiesPolicy";
import Termsandconditions from "../Modules/Policies/Termsandconditions";
import ProfileScreen from "../Modules/Profile/Profile";
import NotificationAlert from "../Components/NotificationAlert";
// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken } from 'firebase/messaging';
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import firebaseConfig from "../common/firebaseConfig";
import { Capacitor } from "@capacitor/core";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
} from "@capacitor/push-notifications";

const Root = () => {
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const [isLoggedChecked, setIsLoggedChecked] = useState(false);
  const setHideAlert = () => dispatch(setAlert({ active: false }));
  const setHideNotificationAlert = () =>
    dispatch(setNotificationAlert({ active: false }));
  const alert = useSelector((state) => state.theme.alert);
  const notificationAlert = useSelector(
    (state) => state.theme.notificationAlert
  );
  const [routes, setRoutes] = useState();

  const [notifications, setnotifications] = useState([]);

  useEffect(() => {
    // adding interceptors to api
    API.interceptors.request.use((request) => {
      setLoading(true);
      // dispatch(setLoading(true))
      return request;
    });
    API.interceptors.response.use(
      (response) => {
        setLoading(false);
        // dispatch(setLoading(false))
        return response;
      },
      (error) => {
        setLoading(false);
        // dispatch(setLoading(false))
        return Promise.reject(error);
      }
    );
  }, []); // eslint-disable-line

  const checkLoggedIn = () => {
    // dispatch(setAlert({title:"HLO",subtitle:"Hai",type:'success',active: true}))
    let token = localStorage.getItem("token");
    if (!token && isLoggedIn) {
      dispatch(setLoggedIn(false));
    } else if (token && token.split(".").length > 1) {
      let userDetails = JSON.parse(atob(token.split(".")[1]));
      setIsLoggedChecked(true);
      dispatch(setLoggedIn(true));
      dispatch(setToken(token));
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      dispatch(setUserDetails(userDetails));
    } else {
      setIsLoggedChecked(true);
    }
  };

  useEffect(() => {
    setIsLoggedChecked(false);
    checkLoggedIn();
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      setRoutes(
        <Switch>
          <Route path="/" component={HomeRoot} />
          <Redirect to="/" />
        </Switch>
      );
    } else {
      setRoutes(
        <Switch>
          <Route path="/auth/login" exact component={Login} />
          <Route
            path="/auth/forgot_password"
            exact
            component={ForgotPassword}
          />
          <Route path="/auth/otp" exact component={OTP} />
          <Route path="/auth/reset_password" exact component={ResetPassword} />
          <Route path="/auth/signup" exact component={Signup} />
          <Route path="/user-agreement" exact component={EndUser} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/cookie-policy" exact component={CookiesPolicy} />
          <Route path="/terms-condition" exact component={Termsandconditions} />
          <Route path="/user-profile" exact component={ProfileScreen} />
          <Route path="/" component={Login} />
        </Switch>
      );
    }
  }, [isLoggedIn]);
  return (
    <>
      {isLoggedChecked ? (
        <div className="h-100">
          <Alert
            title={alert.title}
            subtitle={alert.subtitle}
            active={alert.active}
            setActive={setHideAlert}
            type={alert.type}
          />
          <NotificationAlert
            subtitle={notificationAlert.subtitle}
            active={notificationAlert.active}
            setActive={setHideNotificationAlert}
            type={notificationAlert.type}
            imageUrl={notificationAlert.imageUrl}
          />
          {routes}
        </div>
      ) : (
        <Loaders />
      )}
    </>
  );
};

export default Root;
