import { FaBell, FaCheck, FaExclamationTriangle, FaTimes } from "react-icons/fa"
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect } from "react"
import dummyProfile from '../assets/dummyProfile.webp'

const AlertAnim = {
    hidden: {
        y: '-100vh',
        opacity: 0
    },
    visible: {
        y: 20,
        opacity: 1
    }
}

const TimerAnim = {
    hidden: {
        width: '92%'
    },
    visible: {
        width: 0,
        transition: {
            duration: 5
        }
    }
}

const AlertStyle = {
    minWidth: '400px'
}

var timeout = null

// error, warning, success
const NotificationAlert = ({ title, subtitle, active, setActive, type="post",imageUrl }) => {

    const toggleActive = () => {
        clearTimeout(timeout)
        if(typeof setActive == 'function') setActive(!active)
    }
    useEffect(() => {
        if(active) timeout = setTimeout(() => toggleActive(), 5000)
    }, [active]) // eslint-disable-line

    return (
        <AnimatePresence>
            { active && 
            <div className="fixed top-1 flex justify-center right-[-13%] transform -translate-x-1/2 z-[2000]">
                    <motion.div className={`relative ring-2 ${type === 'error' ? 'ring-red-500 dark:ring-red-400' : type === 'success' ? 'ring-green-400 dark:ring-green-400' : type === 'warning' ? 'ring-yellow-500 dark:ring-yellow-400' : ''} bg-indigo-50 dark:bg-ldark text-gray-600 dark:text-gray-300 flex py-2 pl-4 pr-3 justify-between items-center shadow-xl`} variants={ AlertAnim } initial="hidden" animate="visible" exit="hidden" style={ AlertStyle }>
                        <motion.div className={`absolute ${type === 'error' ? 'bg-red-500 dark:bg-red-700' : type === 'success' ? 'bg-green-500 dark:bg-green-700' : type === 'warning' ? 'bg-yellow-500 dark:bg-yellow-700' : ''} right-0 mx-4 z-50 rounded-full`} variants={ TimerAnim } style={{height: '3px', top: '-3px'}}></motion.div>
                        <div className="flex sticky items-center">
                            {
                                type === 'post' ? <img src={imageUrl !== null ? imageUrl : dummyProfile} className={'rounded'} height={30} width={30}/>: null
                            }
                            <div className="mx-10 font-medium text-left text-black">
                                {/* <div>{title}</div> */}
                                {/* <div className={`${type === 'post' ? 'text-red-500 dark:text-red-300' : type === 'success' ? 'text-green-500 dark:text-green-300' : type === 'warning' ? 'text-yellow-600 dark:text-yellow-300' : ''} text-xs font-bold`}>{ subtitle }</div> */}
                                <div className={`${type === 'post' && 'text-black'}`}>{subtitle}</div>
                            </div>
                        </div>
                        <div className="sticky mx-2 p-2">
                            <FaTimes className="cursor-pointer" onClick={ toggleActive } />
                        </div>
                    </motion.div>
                </div>
            }
        </AnimatePresence>
    )
}

export default NotificationAlert