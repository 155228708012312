import React from "react"
import { motion } from "framer-motion"
import { FaPen, FaGlobeAfrica, FaPhoneAlt } from "react-icons/fa"
import dummyMap from '../../../assets/map.png'
import { FiMail, FiClock } from 'react-icons/fi'
import { useHistory } from "react-router-dom"
import MapContainer from "../../../Components/MapContainer"


const Professional = ({professional,general,edit}) => {

    const history =  useHistory()
    return (
        <motion.div className={`relative w-full flex flex-col text-black md:drop-shadow-xl md:rounded-3xl ${professional || professional === "null" || professional === null && 'h-72'} bg-white mt-2 px-2 md:px-4 md:py-4`}>
            <motion.div className={`flex flex-row justify-between md:m-4 m-3`}>
                <motion.div className={`text-xl font-bold`}>
                    Professional Summary
                </motion.div>
                {edit && <motion.div className={`relative cursor-pointer rounded-full backdrop-blur-sm bg-[#2593D2]/30`} style={{ height: '30px', width: '30px' }} onClick={()=>history.push("/editProfile")}>
                    <FaPen className="text-white text-sm m-[8px]" />
                </motion.div>}
            </motion.div>
            <motion.div className={`grid md:grid-cols-2 sm:grid-cols-1 gap-5`}>
                <motion.div className={`relative h-32 rounded-lg`}>
                    <MapContainer latitude={professional && parseFloat(professional.latitude)} longitude={professional && parseFloat(professional.longitude)}/>
                </motion.div>
                <>
                    {
                        professional === null || professional === 'null' ?  edit  ? <motion.div className="hidden md:block relative flex flex-col justify-center items-center align-center text-center">
                                <motion.div className="text-xl text-black font-bold">
                                    Add Professional Details
                                </motion.div>
                        </motion.div>  : <motion.div className="text-xl text-black font-bold">
                                    No Professional Details
                                </motion.div> : <motion.div className={`relative flex flex-col flex-1 px-4 mt-[20%] sm:mt-0`}>
                        {/* website */}
                        {general && general.website && <motion.div className={`flex flex-row mt-1 mb-1 justify-start gap-10`}>
                            <motion.div className="relative mt-1 mb-1">
                                <FaGlobeAfrica className="text-base" />
                            </motion.div>
                            <motion.div className={`text-base relative cursor-pointer text-black`}>
                                {general && general.webSite}
                            </motion.div>
                        </motion.div>}
                        {/* Mail */}
                        {general && general.email  && <motion.div className={`flex flex-row mt-1 mb-1 justify-start gap-10`}>
                            <motion.div className="relative mt-1 mb-1">
                                <FiMail className="text-sm" />
                            </motion.div>
                            <motion.div className={`text-sm relative cursor-pointer text-black`}>
                                {general && general.email}
                            </motion.div>
                        </motion.div>}
                        {/* Phone */}
                        {general && general.phone && <motion.div className={`flex flex-row mt-1 mb-1 justify-start gap-10`}>
                            <motion.div className="relative mt-1 mb-1">
                                <FaPhoneAlt className="text-sm" />
                            </motion.div>
                            <motion.div className={`text-sm relative cursor-pointer text-black`}>
                                {general && general.phone}
                            </motion.div>
                        </motion.div>}
                        {/* Timings */}
                        <motion.div className={`flex flex-row mt-1 mb-1 justify-start gap-10`}>
                            <motion.div className="relative mt-1 mb-1">
                                <FiClock className="text-sm" />
                            </motion.div>
                            <motion.div className={`text-sm relative flex flex-col cursor-pointer text-black`}>
                                {professional && professional.timings && professional.timings.map((c, i) => {
                                    return (
                                        <>
                                            {!c.isHoliday ? <motion.div className={`flex flex-row justify-evenly`}>
                                            <motion.div className="text-sm text-black">
                                                {c.day}
                                            </motion.div>
                                            <motion.div className="text-sm text-black">
                                                :
                                            </motion.div>
                                            <motion.div className="text-sm flex flex-row text-black">
                                                {c.openingTime} {c.openZone} - {c.closingTime} {c.closeZone}
                                            </motion.div>
                                        </motion.div> : <motion.div className={`flex flex-row justify-evenly`}>
                                            <motion.div className="text-sm text-black">
                                                {c.day}
                                            </motion.div>
                                            <motion.div className="text-sm text-black">
                                                :
                                            </motion.div>
                                            <motion.div className="text-sm flex flex-row text-black">
                                                    CLOSED
                                            </motion.div>
                                        </motion.div>}
                                        </>
                                    )
                                })}
                            </motion.div>
                        </motion.div>
                    </motion.div>
                    }
                </>
            </motion.div>
        </motion.div>
    )
}

export default Professional