import React, { useState } from "react";
import { motion } from "framer-motion";
import logo from "../assets/minlogo.jpeg";
import { sideRoutes } from "../routes";
import HomeIcon from "../assets/Icons/Home.png";
import VideoIcon from "../assets/Icons/video.png";
import ShopIcon from "../assets/Icons/Shop.png";
import notifyIcon from "../assets/Icons/Notify.png";
import JobsIcon from "../assets/Icons/Job.png";
import extraIcon from "../assets/Icons/extras.png";
import campaignIcon from "../assets/Icons/campaign.png";
import powerIcon from "../assets/Icons/power.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RiShutDownLine } from "react-icons/ri";
import addPostIcon from "../assets/Icons/addpost.png";
import CreatePost from "../Modules/Post/CreatePost";
import {
  setToken,
  setLoggedIn,
  setScreenName,
  setOpenNotificationBox,
  setLoadAllPost,
} from "../store/auth/actions";
import Dialog from "./Dialog";

const Toolbar = () => {
  const [screenList, setScreenList] = useState([
    { screenName: "Home", link: "/home", select: true },
    { screenName: "Video", link: "/video", select: false },
    { screenName: "Shop", link: "/shop", select: false },
    { screenName: "Notification", link: "/notification", select: false },
    { screenName: "Jobs", link: "/jobs", select: false },
    { screenName: "extras", select: false },
  ]);
  const [nestedMenu, setNestedMenu] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [routerLink, setRouterLink] = useState();
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [openCreatePost, setOpenCreatePost] = useState(false);
  const openNotificationBox = useSelector(
    (state) => state.auth.openNotificationBox
  );
  const loadAllPostCount = useSelector((state) => state.auth.loadAllPost);
  const changeScreen = (value) => {
    setScreenList(
      screenList.map((c) => {
        if (c.screenName === value.screenName) {
          dispatch(setScreenName(value.screenName));
          if (c.link) {
            history.push(c.link);
          }
          c.select = true;
        } else {
          c.select = false;
        }
        return c;
      })
    );
  };

  const openNestedMenu = () => {
    setNestedMenu(!nestedMenu);
  };

  const logout = () => {
    dispatch(setToken(""));
    dispatch(setLoggedIn(false));
    localStorage.removeItem("token");
    history.push("/");
  };

  const routeToPage = (link) => {
    setRouterLink(link.link);
    dispatch(setScreenName(link.name));
    history.push(link.link);
  };

  const openNotification = () => {
    dispatch(setOpenNotificationBox(!openNotificationBox));
  };

  const loadPost = (value) => {
    dispatch(setLoadAllPost(loadAllPostCount + 1));
    setOpenCreatePost(value);
  };

  return (
    <motion.div className="relative h-14 md:min-h-screen flex flex-col bg-[#40444B] px-2">
      {/* Desktop */}
      <motion.div className="md:block hidden">
        <motion.div className="relative justify-center items-center text-center flex flex-1 flex-col mb-3">
          <img alt="" src={logo} className="h-12 w-12" />
        </motion.div>
        <motion.div className="relative font-bold">
          {sideRoutes.map((c, i) => {
            return (
              <motion.div
                key={i}
                className={`relative cursor-pointer justify-center items-center text-center text-white flex flex-col`}
                onClick={() => routeToPage(c)}
              >
                <img alt="" src={c.icon} height={35} width={35} />
                <motion.div className="mt-1 mb-3 text-xs font-bold">
                  {c.name}
                </motion.div>
              </motion.div>
            );
          })}
          <motion.div
            className="relative justify-center items-center text-center text-white flex flex-col cursor-pointer"
            onClick={() => openNotification()}
          >
            <img alt="" src={notifyIcon} height={35} width={35} />
            <motion.div className="mt-2 mb-2 text-xs font-bold">
              Notify
            </motion.div>
          </motion.div>
        </motion.div>
        <motion.div className="absolute cursor-pointer  text-white inset-x-0 bottom-0 h-16">
          <motion.div
            className="relative justify-center items-center text-center text-white flex flex-col"
            onClick={() => setLogoutPopup(true)}
          >
            <img alt="" src={powerIcon} height={20} width={20} />
            <motion.div className="mt-2 mb-2 text-xs font-bold">
              Logout
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
      {/* Mobile */}
      <motion.div className="md:hidden block ion-footer h-full">
        <motion.div className="relative flex flex-row px-2 py-1 justify-between">
          <motion.div
            className="relative flex flex-col px-2 cursor-pointer"
            onClick={() => changeScreen({ screenName: "Home", url: "/Home" })}
          >
            <motion.div className="sticky">
              <img alt="" src={HomeIcon} height={35} width={35} />
            </motion.div>
            <motion.div
              className={`absolute ${screenList.filter((c) => {
                return c.screenName === "Home" && c.select === true;
              }).length === 1
                  ? "underline decoration-4  decoration-white  underline-offset-4"
                  : ""
                } text-md font-bold text-white mt-7`}
            >
              Home
            </motion.div>
          </motion.div>
          <motion.div
            className="relative flex flex-col  px-2 cursor-pointer"
            onClick={() => changeScreen({ screenName: "Video", url: "/Home" })}
          >
            <motion.div className="sticky">
              <img alt="" src={VideoIcon} height={35} width={35} />
            </motion.div>
            <motion.div
              className={`absolute ${screenList.filter((c) => {
                return c.screenName === "Video" && c.select === true;
              }).length === 1
                  ? "underline decoration-4  decoration-white  underline-offset-4"
                  : ""
                } text-md font-bold text-white mt-7`}
            >
              Video
            </motion.div>
          </motion.div>
          <motion.div
            className="relative flex flex-col  px-2 cursor-pointer"
            onClick={() => setOpenCreatePost(!openCreatePost)}
          >
            <motion.div className="sticky px-2">
              <img alt="" src={addPostIcon} height={25} width={25} />
            </motion.div>
            <motion.div
              className={`absolute ${screenList.filter((c) => {
                return c.screenName === "Post" && c.select === true;
              }).length === 1
                  ? "underline decoration-4  decoration-white  underline-offset-4"
                  : ""
                } text-md font-bold text-white mt-7`}
            >
              Post
            </motion.div>
          </motion.div>
          <motion.div
            className="relative flex flex-col  px-2 cursor-pointer"
            onClick={() => changeScreen({ screenName: "Shop", url: "/Home" })}
          >
            <motion.div className="sticky">
              <img alt="" src={ShopIcon} height={35} width={35} />
            </motion.div>
            <motion.div
              className={`absolute ${screenList.filter((c) => {
                return c.screenName === "Shop" && c.select === true;
              }).length === 1
                  ? "underline decoration-4  decoration-white  underline-offset-4"
                  : ""
                } text-md font-bold text-white mt-7`}
            >
              Shop
            </motion.div>
          </motion.div>
          <motion.div
            className="relative flex flex-col  px-2 cursor-pointer"
            onClick={() =>
              changeScreen({ screenName: "Notification", url: "/notification" })
            }
          >
            <motion.div className="sticky">
              <img alt="" src={notifyIcon} height={35} width={35} />
            </motion.div>
            <motion.div
              className={`absolute ${screenList.filter((c) => {
                return c.screenName === "notify" && c.select === true;
              }).length === 1
                  ? "underline decoration-4  decoration-white  underline-offset-4"
                  : ""
                } text-md font-bold text-white mt-7`}
            >
              Notify
            </motion.div>
          </motion.div>
          <motion.div className="static px-2" onClick={() => openNestedMenu()}>
            {nestedMenu && (
              <motion.div className="absolute bottom-[125px] right-[130px] mt-1 mb-1">
                <motion.div className="absolute text-white w-32 py-2 bg-[#40444B] flex flex-col px-2">
                  <motion.div
                    className="flex flex-row justify-evenly cursor-pointer"
                    onClick={() =>
                      changeScreen({ screenName: "extras", url: "/jobs" })
                    }
                  >
                    <img alt="" src={JobsIcon} height={35} width={35} />
                    <motion.div className="text-sm mt-1 mb-1">Jobs</motion.div>
                  </motion.div>
                  <motion.div
                    className="flex flex-row justify-evenly cursor-pointer"
                    onClick={() =>
                      changeScreen({ screenName: "extras", url: "/campaign" })
                    }
                  >
                    <img alt="" src={campaignIcon} height={35} width={35} />
                    <motion.div className="text-sm mt-1 mb-1">
                      Campaign
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="flex flex-row justify-evenly cursor-pointer"
                    onClick={() => setLogoutPopup(true)}
                  >
                    <img
                      alt=""
                      src={powerIcon}
                      style={{ height: "20px", width: "20px" }}
                      className="mr-2 mt-1"
                    />
                    <motion.div className="text-sm mt-1 mb-1">
                      Logout
                    </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
            )}
            <motion.div
              className={`fixed ${screenList.filter((c) => {
                return c.screenName === "extras" && c.select === true;
              }).length === 1
                  ? "underline decoration-4  decoration-white  underline-offset-4"
                  : ""
                }`}
            >
              <img
                alt=""
                src={extraIcon}
                className="mt-1 mr-10"
                height={10}
                width={10}
              />
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
      <Dialog
        injectClass={"w-[852px]"}
        title={"Add Post"}
        showDialog={openCreatePost}
        onClose={() => setOpenCreatePost(false)}
      >
        <CreatePost onClose={(ev) => loadPost(ev)} />
      </Dialog>
      <Dialog
        title={"Logout"}
        centerIcon={<RiShutDownLine />}
        showDialog={logoutPopup}
        onClose={() => setLogoutPopup(false)}
      >
        <div className="py-2 w-full">
          <div className="px-4 mt-2">
            <motion.div className="text-xl text-black">
              Are you sure you want to logout ?
            </motion.div>
          </div>
          <div className="flex justify-between mt-8 mx-2">
            <button
              className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500"
              onClick={() => setLogoutPopup(false)}
            >
              No
            </button>
            <button
              className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-500 hover:bg-opacity-80 text-white"
              onClick={() => logout()}
            >
              Yes
            </button>
          </div>
        </div>
      </Dialog>
    </motion.div>
  );
};

export default Toolbar;
