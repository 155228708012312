import React, { useEffect, useState } from "react";
import ViewPost from "../../Post/ViewPost";
import ViewpostLoader from "../../../Loaders/ViewpostLoader";

const SelfPost = ({ postDetails }) => {
  //   console.log(postDetails);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (postDetails && postDetails !== undefined && postDetails.length > 0) {
      setLoading(false);
    }
  }, []);
  return (
    <>
      {loading ? (
        <ViewpostLoader />
      ) : (
        postDetails &&
        postDetails !== undefined &&
        postDetails.length > 0 &&
        postDetails.map((c) => {
          return <ViewPost details={c} />;
        })
      )}
    </>
  );
};

export default SelfPost;
