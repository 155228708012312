
import {CgProfile} from 'react-icons/cg'
import {MdOutlineAutoStories} from 'react-icons/md'
import { motion } from "framer-motion"
import { PopInAnim } from "../common/anims"
import { useDispatch, useSelector } from "react-redux"
import { setSwitchProfileMode } from "../store/auth/actions"


const ProfilePostSwitch = ({ rotate= "0", anim= true }) => {

    const isPostMode = useSelector(state => state.auth.profileMode === 'post')
    const dispatch = useDispatch()


    const toggleMode = () => {
        dispatch(setSwitchProfileMode(isPostMode ? 'profile' : 'post'))
    }

    return (
        <div className="inline-block">
            <motion.div variants={ anim ? PopInAnim : {} } initial="hidden" animate="visible" className={ `ring-1 ring-offset-1 ${isPostMode ? 'bg-[#2593D2] ring-white ring-offset-black' : 'bg-gray-300 ring-black'} w-12 h-5 rounded-full flex content-center relative transition-all duration-500 cursor-pointer` } onClick={ toggleMode }  title={'Profile Mode'}>
                <CgProfile style={{ width: "12px", height: "auto" }} className={ `absolute left-1.5 top-1 text-black z-10` } />
                <div className={ `absolute bg-white h-5/6 w-4 inline-block mt-0.5 mx-1 rounded-full transition-all duration-500 ${isPostMode ? 'left-2/4' : 'left-0'}` }></div>
                <MdOutlineAutoStories style={{ width: "12px", height: "auto" }} className={ `absolute left-7 ml-0.5 top-1 text-black z-10 transform` } />
            </motion.div>
        </div>
    )
}

export default ProfilePostSwitch