import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import graphIcon from "../../../assets/Icons/whiteGraph.png";
import videoIcon from "../../../assets/Icons/video.png";
import eventIcon from "../../../assets/Icons/whiteEvent.png";
import shopIcon from "../../../assets/Icons/Shop.png";
import liveIcon from "../../../assets/Icons/whiteLive.png";
import Photos from "./Photos";
import Videos from "./Videos";
import Events from "./Events";
import Shop from "./Shop";
import Live from "./Live";
import { ImFeed } from "react-icons/im";
import SelfPost from "./SelfPost";
import API from "../../../axios/API";

const photos = [
  {
    link: "https://images.unsplash.com/photo-1679542501404-5f994a2ecb4d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDE1fDZzTVZqVExTa2VRfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
  },
  {
    link: "https://images.unsplash.com/photo-1667752287443-4b7bd7de9705?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDQxfDZzTVZqVExTa2VRfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
  },
  {
    link: "https://images.unsplash.com/photo-1679588062232-e8eb7d31f3bf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDQyfDZzTVZqVExTa2VRfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
  },
  {
    link: "https://images.unsplash.com/photo-1679345506099-baca3b043efe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDQ3fDZzTVZqVExTa2VRfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
  },
  {
    link: "https://images.unsplash.com/photo-1678545091829-6b232518ef52?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDc3fDZzTVZqVExTa2VRfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
  },
  {
    link: "https://images.unsplash.com/photo-1678366954137-773fe11cb761?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDk2fDZzTVZqVExTa2VRfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
  },
  {
    link: "https://images.unsplash.com/photo-1676839670988-55a7e968f5f2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDEwNXw2c01WalRMU2tlUXx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=900&q=60",
  },
  {
    link: "https://images.unsplash.com/photo-1672745256937-4e60a4534413?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDEyMXw2c01WalRMU2tlUXx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=900&q=60",
  },
  {
    link: "https://images.unsplash.com/photo-1672745256937-4e60a4534413?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDEyMXw2c01WalRMU2tlUXx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=900&q=60",
  },
  {
    link: "https://images.unsplash.com/photo-1676743947908-eff693c29fe7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDE3MHw2c01WalRMU2tlUXx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=900&q=60",
  },
  {
    link: "https://images.unsplash.com/photo-1678612836754-eba3673f7080?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDE2fEpwZzZLaWRsLUhrfHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
  },
];

const Posts = ({ imageCount, videoCount, profileId }) => {
  const [tab, setTab] = useState();
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    setLoading(true);
    API.get(`/post/getSelfPosts/${profileId}`).then((res) => {
      if (
        (imageCount && imageCount !== undefined && imageCount.length > 0) ||
        (videoCount && videoCount !== undefined && videoCount.length > 0)
      ) {
      }
      if (res.status === 200) {
        setTab("post");
        setPosts(res.data.body);
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading ? (
        "Loading please wait ...."
      ) : (
        <motion.div className="relative w-full mt-2">
          <motion.div
            className={`sticky top-0 bg-[#40444B] w-full md:p-2 rounded-lg flex flex-row justify-around`}
          >
            <motion.div
              className={`relative flex flex-row ${
                tab === "post"
                  ? "underline decoration-4  decoration-white  underline-offset-4"
                  : ""
              } justify-start gap-2 cursor-pointer`}
              onClick={() => setTab("post")}
            >
              <img src={eventIcon} height={30} width={30} />
              <motion.div className={"text-[#2593D2] text-xl"}>
                {posts && posts !== undefined && posts.length}
              </motion.div>
            </motion.div>
            <motion.div
              className={`relative flex flex-row ${
                tab === "graph"
                  ? "underline decoration-4  decoration-white  underline-offset-4"
                  : ""
              } justify-start gap-2 cursor-pointer`}
            >
              <img
                src={graphIcon}
                height={30}
                width={30}
                onClick={() => setTab("graph")}
              />
              <motion.div
                className={"text-[#2593D2] text-xl"}
                onClick={() => setTab("graph")}
              >
                {imageCount && imageCount !== undefined && imageCount.length}
              </motion.div>
            </motion.div>
            <motion.div
              className={`relative flex flex-row ${
                tab === "video"
                  ? "underline decoration-4  decoration-white  underline-offset-4"
                  : ""
              } justify-start gap-2 cursor-pointer`}
              onClick={() => setTab("video")}
            >
              <img src={videoIcon} height={30} width={30} />
              <motion.div className={"text-[#2593D2] text-xl"}>
                {videoCount && videoCount !== undefined && videoCount.length}
              </motion.div>
            </motion.div>
            {/* <motion.div className={`relative flex flex-row ${tab === 'event' ? 'underline decoration-4  decoration-white  underline-offset-4' : ''} justify-start gap-2 cursor-pointer`} onClick={()=>setTab('event')}>
                          <img src={eventIcon} height={30} width={30} />
                          <motion.div className={'text-[#2593D2] text-xl'}>
                              2
                          </motion.div>
                      </motion.div>
                      <motion.div className={`relative flex flex-row ${tab === 'shop' ? 'underline decoration-4  decoration-white  underline-offset-4' : ''} justify-start gap-2 cursor-pointer`} onClick={()=>setTab('shop')}>
                          <img src={shopIcon} height={30} width={30} />
                          <motion.div className={'text-[#2593D2] text-xl'}>
                              1
                          </motion.div>
                      </motion.div>
                      <motion.div className={`relative flex flex-row ${tab === 'live' ? 'underline decoration-4  decoration-white  underline-offset-4' : ''} justify-start gap-2 cursor-pointer`} onClick={()=>setTab('live')}>
                          <img src={liveIcon} height={30} width={30} />
                          <motion.div className={'text-[#2593D2] text-xl'}>
                              1
                          </motion.div>
                      </motion.div> */}
          </motion.div>
          <motion.div className={"relative mt-2"}>
            {tab === "post" && (
              <motion.div className={"text-black text-md font-bold"}>
                <SelfPost postDetails={posts} />
              </motion.div>
            )}
            {tab === "graph" && (
              <motion.div className={"text-black text-md font-bold"}>
                <Photos
                  imageSet={
                    imageCount && imageCount !== undefined && imageCount
                  }
                />
              </motion.div>
            )}
            {tab === "video" && (
              <motion.div className={"text-black text-md font-bold"}>
                <Videos
                  videoSet={
                    videoCount && videoCount !== undefined && videoCount
                  }
                />
              </motion.div>
            )}
            {/* {tab === "event" && (
                <motion.div className={"text-black text-md font-bold"}>
                  <Events />
                </motion.div>
              )}
              {tab === "shop" && (
                <motion.div className={"text-black text-md font-bold"}>
                  <Shop purchaseHistory={photos} />
                </motion.div>
              )}
              {tab === "live" && (
                <motion.div className={"text-black text-md font-bold"}>
                  <Live />
                </motion.div>
              )} */}
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default Posts;
