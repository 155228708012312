import React, { useEffect, useState } from "react"
import { motion } from 'framer-motion'
import logo from '../../assets/signupLogo.png'
import * as Yup from 'yup'
import { useFormik } from "formik"
import FieldInput from "../../Components/FieldInput"
import Button from "../../Components/Button"
import { FcGoogle } from 'react-icons/fc'
import { useHistory } from "react-router-dom"
import { IoArrowBackCircleSharp } from 'react-icons/io5'
import FieldSelect from "../../Components/FieldSelect"
import API from '../../axios/API'
import { setToken, setLoggedIn } from "../../store/auth/actions"
import { useDispatch } from "react-redux"
import BgVideo from '../../assets/finalsignup.mp4'
import { setAlert } from '../../store/theme/actions'
import axios from "axios"
import codes from 'country-calling-code';
import { LoginSocialGoogle } from 'reactjs-social-login'

const page1form = {
    email: '',
    password: '',
    confirmPassword: '',
}

const page2form = {
    accountTypeId: '',
    preferenceTypeId: '',
    firstName: '',
    lastName: '',
    businessName: '',
}

const page3form = {
    countryCode: '',
    phone: '',
}

const page4form = {
    otp: '',
}

// const initialInfoValues = {
//     email: '',
//     password: '',
//     confirmPassword: '',
//     accountTypeId: '',
//     preferenceTypeId: '',
//     firstName: '',
//     lastName: '',
//     businessName: '',
//     countryCode:'',
//     phone: '',
//     otp: '',
// }


const Signup = () => {

    const history = useHistory()
    const [page1, setPage1] = useState(true)
    const [page2, setPage2] = useState(false)
    const [page3, setPage3] = useState(false)
    const [page4, setPage4] = useState(false)
    const [prefType, setPrefType] = useState(1)
    const [accounts, setAccounts] = useState([])
    const [preference, setPreference] = useState([])
    const [countries, setCountries] = useState([])
    const [countryCode, setCountryCode] = useState('+01')
    const [otpToken, setOTPToken] = useState()
    const dispatch = useDispatch()

    const page1FormSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email or Phone Number is required')
            .test('is-email-or-number', 'Invalid email or number', function (value) {
                // Check if the value is a valid email address
                if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value)) {
                    return Yup.string()
                        .email('Invalid email address')
                        .validate(value, { abortEarly: false })
                        .then(() => true)
                        .catch(() => false);
                }

                // Check if the value is a valid phone number with or without the country code
                if (/^(\+[0-9]{1,3}\s?)?\d{1,10}$/i.test(value.replace(/\s/g, ''))) {
                    return true;
                }

                return false;
            }),
        password: Yup.string().required("Password is required"),
        confirmPassword: Yup.string().required("Confirm Password is required"),
    })

    const page2FormSchema = Yup.object().shape({
        accountTypeId: Yup.string().required('Account Type is required'),
        preferenceTypeId: Yup.string().required('Preference is required'),
        firstName: prefType && prefType === 1 ? Yup.string().required('First Name is required') : Yup.string(),
        lastName: prefType && prefType === 1 ? Yup.string().required('Last Name is required') : Yup.string(),
        businessName: prefType && prefType !== 1 ? Yup.string().required('Business Name is required') : Yup.string(),
    })

    const page3FormSchema = Yup.object().shape({
        countryCode: Yup.string().required("Country is required"),
        phone: Yup.string().required("Mobile number is required").matches(/^(\+\d{1,3}[- ]?)?\d{0,10}$/, 'Invalid phone number')
    })

    const page4FormSchema = Yup.object().shape({
        otp: Yup.string().required('OTP is required')
    })


    useEffect(() => {
        axios.all([API.get('/menu/accountTypes'), API.get('/menu/preferenceTypes'), API.get('/menu/countries')]).then((axios.spread((accountResp, prefResp, countryResp) => {
            setAccounts(accountResp.data.body.map((c) => ({ text: c.accountType, value: c.accountTypeId })))
            setPreference(prefResp.data.body.map((c) => ({ text: c.preferenceType, value: c.preferenceTypeId })))
            setCountries(countryResp.data.body.map((c) => ({ text: c.name, value: c.code })))
        })))
    }, [])


    // const signupFormSchema = Yup.object().shape({
    //     email: Yup.string().required("Email or Phone Number is required"),
    //     password: Yup.string().required("Password is required"),
    //     confirmPassword: Yup.string().required("Confirm Password is required"),
    //     accountTypeId: page2 ? Yup.string().required('Account Type is required') : Yup.string(),
    //     preferenceTypeId: page2 ? Yup.string().required('Preference is required') : Yup.string(),
    //     firstName: prefType && prefType ===1 && page2 ? Yup.string().required('First Name is required') : Yup.string(),
    //     lastName: prefType && prefType ===1 && page2 ? Yup.string().required('Last Name is required') : Yup.string(),
    //     businessName: prefType && prefType !==1 && page2 ? Yup.string().required('Business Name is required') : Yup.string(),
    //     countryCode : page3 ? Yup.string().required("Country is required"):Yup.string(),
    //     phone: page3 ? Yup.string().required("phone is required") : Yup.string(),
    //     otp: page4 ? Yup.string().required('OTP is required') : Yup.string(),
    // })

    // const signupForm = useFormik({
    //     initialValues: initialInfoValues,
    //     // validateOnMount: true,
    //     enableReinitialize: true,
    //     validationSchema: signupFormSchema,
    //     onSubmit: (values) => {
    //         if(page1){
    //             if(values.password === values.confirmPassword){
    //                 pageChange(2)
    //             }else{
    //                 console.log("Passwords do not match")
    //             }
    //         }else if(page2){
    //             pageChange(3)
    //         }else if(page3){
    //             // pageChange(4)
    //             let value = {
    //                 phone : values.phone
    //             }
    //             API.post('/auth/sendVerification',value).then((res)=>{
    //                 if(res.data.status === 200){
    //                     setOTPToken(res.data.body.token)
    //                     pageChange(4)
    //                 }
    //             })
    //         }else{
    //             let otpVerify = {
    //                 token : otpToken,
    //                 otp:values.otp,
    //                 emailEnable:false,
    //                 phone:values.phone
    //             }
    //             API.post('/auth/verifyOTP',otpVerify).then((res)=>{
    //                 if(res.data.status === 200){
    //                     API.post('/auth/signUp',values).then((res)=>{
    //                         if(res.data.status === 200){
    //                             dispatch(setToken(res.data.data.body))
    //                             dispatch(setLoggedIn(true))
    //                             localStorage.setItem('token', res.data.data.body)
    //                             history.push('/')
    //                         }
    //                     })
    //                 }
    //             })
    //         }
    //     }
    // })

    const page1Form = useFormik({
        initialValues: page1form,
        validationSchema: page1FormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: (values) => {
            const obj = values
            obj.email = values.email.replace(/\s/g, '')
            if (values.password === values.confirmPassword) {
                pageChange(2)
            } else {
                dispatch(setAlert({ type: 'warning', title: 'Warning', subtitle: "Password Does Not Match", active: true }))
            }
        }
    })

    const page2Form = useFormik({
        initialValues: page2form,
        validationSchema: page2FormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: (values) => {
            pageChange(3)
        }
    })

    const page3Form = useFormik({
        initialValues: page3form,
        validationSchema: page3FormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: (values) => {
            const obj = values
            obj.phone = values.phone.replace(/\s/g, '')
            let value = {
                phone: values.phone.includes('+') ? values.phone : `${countryCode} ${values.phone}`
            }
            API.post('/auth/sendVerification', value).then((res) => {
                if (res.data.status === 200) {
                    setOTPToken(res.data.body.token)
                    dispatch(setAlert({ type: 'success', title: 'Success', subtitle: "OTP is sent to your registered phone number", active: true }))
                    pageChange(4)
                }
            })
        }
    })

    const page4Form = useFormik({
        initialValues: page4form,
        validationSchema: page4FormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: (values) => {
            let otpVerify = {
                token: otpToken,
                otp: values.otp,
                emailEnable: false,
                phone: page3Form.values.phone.includes('+') ? page3Form.values.phone : `${countryCode} ${page3Form.values.phone}`
            }
            let userDetails = { ...page1Form.values, ...page2Form.values, ...page3Form.values }
            userDetails.email = page1Form.values.email.includes('@') ? page1Form.values.email : null
            userDetails.emailEnable = page1Form.values.email.split('').includes('@') ? true : false
            API.post('/auth/verifyOTP', otpVerify).then((res) => {
                if (res.data.status === 200) {
                    API.post('/auth/signUp', userDetails).then((res) => {
                        if (res.data.status === 200) {
                            dispatch(setToken(res.data.data.body))
                            dispatch(setLoggedIn(true))
                            dispatch(setAlert({ type: 'success', title: 'Success', subtitle: "Account Created Successfully", active: true }))
                            localStorage.setItem('token', res.data.data.body)
                            history.push('/')
                        } else if (res.data.status === 403) {
                            dispatch(setAlert({ type: 'warning', title: 'Warning', subtitle: "User Already Exists", active: true }))
                        }
                    })
                }
            })
        }
    })

    const pageChange = (value) => {
        if (value === 1) {
            setPage1(true)
            setPage2(false)
            setPage3(false)
            setPage4(false)
        } else if (value === 2) {
            setPage1(false)
            setPage2(true)
            setPage3(false)
            setPage4(false)
        } else if (value === 3) {
            setPage1(false)
            setPage2(false)
            setPage3(true)
            setPage4(false)
        } else {
            setPage1(false)
            setPage2(false)
            setPage3(false)
            setPage4(true)
        }
    }

    const accountTypeChange = (value) => {
        if (value === 1) {
            setPrefType(1)
        } else {
            setPrefType(2)
        }
    }

    const countryType = (value) => {
        setCountryCode(`+${codes.filter((x) => x.isoCode2 === value)[0].countryCodes[0]}`)
    }

    const resendOTP = (type) => {
        let value = {
            phone: page3Form.values.phone.includes('+') ? page3Form.values.phone : `${countryCode} ${page3Form.values.phone}`
        }
        if (type === 'sms') {
            API.post('/auth/sendVerification', value).then((res) => {
                if (res.data.status === 200) {
                    setOTPToken(res.data.body.token)
                    dispatch(setAlert({ type: 'success', title: 'Success', subtitle: "OTP is sent to your registered phone number", active: true }))
                    pageChange(4)
                }
            })
        } else if (type === 'phone') {
            API.post('/auth/otpByPhone', value).then((res) => {
                if (res.data.status === 200) {
                    setOTPToken(res.data.body.token)
                    dispatch(setAlert({ type: 'success', title: 'Success', subtitle: "You will receive a call shortly", active: true }))
                    pageChange(4)
                }
            })
        }
    }

    // const responseSuccessGoogle = (response) => {
    //     page1Form.values.email = response.email;
    //     page2Form.values.firstName = response.given_name
    // }

    return (
        <motion.div className="min-h-screen items-center flex flex-col text-center justify-center">
            <video src={BgVideo} autoPlay={true} muted={true} loop={true} style={{ objectFit: 'cover', position: 'absolute', height: '100vh', width: '100%' }} className="overflow-hidden" />
            <motion.div className="md:w-1/3 w-80 drop-shadow-lg rounded-xl backdrop-blur-sm bg-white/30">
                <motion.div className="relative md:p-4 p-2">
                    {
                        page1 && <motion.div className="md:p-2 p-3 rounded-xl bg-white">
                            <motion.div className="items-center flex flex-row justify-center text-center">
                                <img src={logo} className={'h-24 w-24'} />
                            </motion.div>
                            <motion.div className="mt-2 mb-2">
                                <FieldInput label={'Email or Phone number'} fieldProps={page1Form.getFieldProps('email')} fieldMeta={page1Form.getFieldMeta('email')} fieldHelper={page1Form.getFieldHelpers('email')} edit={true} dataLoaded={true} />
                                <FieldInput label={'Password'} type={'password'} fieldProps={page1Form.getFieldProps('password')} fieldMeta={page1Form.getFieldMeta('password')} fieldHelper={page1Form.getFieldHelpers('password')} edit={true} dataLoaded={true} />
                                <FieldInput label={'Confirm Password'} type={'password'} fieldProps={page1Form.getFieldProps('confirmPassword')} fieldMeta={page1Form.getFieldMeta('confirmPassword')} fieldHelper={page1Form.getFieldHelpers('confirmPassword')} edit={true} dataLoaded={true} />
                            </motion.div>
                            <motion.div className="mt-2 mb-2 flex flex-row text-left justify-start">
                                <input type="checkbox" className="mr-2 ml-2 mt-1 mb-1" />&nbsp;<span className="text-md">By Clicking Agree & Join, you agree to the Aqua Agriface <span className="text-blue-500 cursor-pointer" onClick={() => history.push("/user-agreement")}>User Agreement,</span><span className="text-blue-500 cursor-pointer" onClick={() => history.push('/privacy-policy')}>Privacy Policy</span> and <span className="text-blue-500 cursor-pointer" onClick={() => history.push('/cookie-policy')}>Cookie Policy</span></span>
                            </motion.div>
                            <motion.div className="mt-2 mb-2">
                                <Button injectClass={'w-full bg-[#2593D2]'} onClick={() => page1Form.submitForm()}>Agree & Join</Button>
                            </motion.div>
                            <motion.div className="relative justify-center flex flex-col items-center text-center">
                                {/* <motion.div className="justify-center sticky items-center text-center">
                                    <LoginSocialGoogle
                                        client_id={process.env.REACT_APP_GG_APP_ID || ''}
                                        scope="openid profile email"
                                        discoveryDocs="claims_supported"
                                        access_type="offline"
                                        onResolve={({ provider, data }) => {
                                            responseSuccessGoogle(data)
                                        }}
                                        onReject={err => {
                                            console.log(err);
                                        }}
                                    >
                                        <FcGoogle />
                                    </LoginSocialGoogle>
                                </motion.div> */}
                                <motion.div className="flex mt-5 flex-1 flex-row">
                                    <p>Already on Aqua agriface ? </p>&nbsp;<p className="text-blue-500 cursor-pointer" onClick={() => history.push('/auth/login')}>Sign in</p>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    }
                    {
                        page2 && <motion.div className="md:p-4 p-2rounded-xl relative bg-white">
                            <motion.div className="absolute md:p-2 p-3">
                                <IoArrowBackCircleSharp className="h-6 w-6 cursor-pointer" onClick={() => pageChange(1)} />
                            </motion.div>
                            <motion.div className="items-center flex flex-row justify-center text-center">
                                <img src={logo} className={'h-24 w-24'} />
                            </motion.div>
                            <motion.div className="mt-2 mb-2">
                                <FieldSelect title={'Account Type'} fieldProps={page2Form.getFieldProps('accountTypeId')} fieldMeta={page2Form.getFieldMeta('accountTypeId')} fieldHelper={page2Form.getFieldHelpers('accountTypeId')} edit={true} dataLoaded={true} options={accounts} onChange={(ev) => accountTypeChange(ev)} />
                                {prefType === 1 && <>
                                    <FieldInput label={'First Name'} fieldProps={page2Form.getFieldProps('firstName')} fieldMeta={page2Form.getFieldMeta('firstName')} fieldHelper={page2Form.getFieldHelpers('firstName')} edit={true} dataLoaded={true} />
                                    <FieldInput label={'Last Name'} fieldProps={page2Form.getFieldProps('lastName')} fieldMeta={page2Form.getFieldMeta('lastName')} fieldHelper={page2Form.getFieldHelpers('lastName')} edit={true} dataLoaded={true} /></>}
                                {prefType === 2 && <>
                                    <FieldInput label={'Business Name'} fieldProps={page2Form.getFieldProps('businessName')} fieldMeta={page2Form.getFieldMeta('businessName')} fieldHelper={page2Form.getFieldHelpers('businessName')} edit={true} dataLoaded={true} />
                                </>}
                                <FieldSelect title={'Preference'} fieldProps={page2Form.getFieldProps('preferenceTypeId')} fieldMeta={page2Form.getFieldMeta('preferenceTypeId')} fieldHelper={page2Form.getFieldHelpers('preferenceTypeId')} edit={true} dataLoaded={true} options={preference} />
                            </motion.div>
                            <motion.div className="mt-2 mb-5">
                                <Button injectClass={'w-full bg-[#2593D2]'} onClick={() => page2Form.submitForm()}>Continue</Button>
                            </motion.div>
                        </motion.div>
                    }{
                        page3 && <motion.div className="md:p-4 p-2 rounded-xl relative bg-white">
                            <motion.div className="md:p-2 p-3 flex flex-row justify-start">
                                <IoArrowBackCircleSharp className="sticky h-6 w-6 cursor-pointer" onClick={() => pageChange(2)} />
                                <motion.div className="text-black text-xl ml-6 sticky">
                                    Security Verification
                                </motion.div>
                            </motion.div>
                            <hr className="sticky" />
                            <motion.div className="items-center flex flex-row justify-center text-center">
                                <img src={logo} className={'h-24 w-24'} />
                            </motion.div>
                            <motion.div className="mt-2 mb-2 text-center">
                                <motion.div className="text-black text-2xl">
                                    Just a quick security check
                                </motion.div>
                                <motion.div className="text-black text-md mt-2 mb-2">
                                    As an extra security step, we'll need to give you a verification code to register. <span className="text-blue-500">Learn more</span>
                                </motion.div>
                            </motion.div>
                            <motion.div className="mt-2 mb-2">
                                <FieldSelect label={'Country'} fieldProps={page3Form.getFieldProps('countryCode')} fieldMeta={page3Form.getFieldMeta('countryCode')} fieldHelper={page3Form.getFieldHelpers('countryCode')} edit={true} dataLoaded={true} options={countries} onChange={(ev) => countryType(ev)} />
                                <FieldInput label={'Phone number'} splitBox={true} splitText={`${countryCode}`} placeholder={`0987654321`} fieldProps={page3Form.getFieldProps('phone')} fieldMeta={page3Form.getFieldMeta('phone')} fieldHelper={page3Form.getFieldHelpers('phone')} edit={true} dataLoaded={true} />
                            </motion.div>
                            <motion.div className="mt-2 mb-5">
                                <Button injectClass={'w-full bg-[#2593D2]'} onClick={() => page3Form.submitForm()}>Continue</Button>
                            </motion.div>
                        </motion.div>
                    }
                    {
                        page4 && <motion.div className="md:p-4 p-2 rounded-xl relative bg-white">
                            <motion.div className="md:p-2 p-3 flex flex-row justify-start">
                                <IoArrowBackCircleSharp className="sticky h-6 w-6 cursor-pointer" onClick={() => pageChange(3)} />
                                <motion.div className="text-black text-xl ml-6 sticky">
                                    Security Verification
                                </motion.div>
                            </motion.div>
                            <hr className="sticky" />
                            <motion.div className="items-center flex flex-row justify-center text-center">
                                <img src={logo} className={'h-24 w-24'} />
                            </motion.div>
                            <motion.div className="mt-2 mb-2 text-center">
                                <motion.div className="text-black text-2xl">
                                    Just a quick security check
                                </motion.div>
                                <motion.div className="text-black text-md mt-2 mb-2">
                                    As an extra security step, we'll need to give you a verification code to register. <span className="text-blue-500">Learn more</span>
                                </motion.div>
                            </motion.div>
                            <motion.div className="mt-2 mb-2">
                                <FieldInput placeholder={`Enter OTP`} fieldProps={page4Form.getFieldProps('otp')} fieldMeta={page4Form.getFieldMeta('otp')} fieldHelper={page4Form.getFieldHelpers('otp')} edit={true} dataLoaded={true} />
                            </motion.div>
                            <motion.div className="mt-2 mb-2 text-center">
                                <motion.div className="text-black text-md">
                                    Didn't receive the code
                                </motion.div>
                                <motion.div className="text-[#2593D2] px-4 flex flex-row justify-between text-md mt-2 mb-2">
                                    <span className="cursor-pointer" onClick={() => resendOTP('sms')}>Resend code by SMS</span>
                                    <span className="cursor-pointer" onClick={() => resendOTP('phone')}>Resend code by phone call</span>
                                </motion.div>
                            </motion.div>
                            <motion.div className="mt-2 mb-5">
                                <Button injectClass={'w-full bg-[#2593D2]'} onClick={() => page4Form.submitForm()}>Continue</Button>
                            </motion.div>
                        </motion.div>
                    }
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default Signup