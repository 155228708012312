export const SET_LOGGEDIN = 'SET_LOGGEDIN'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_USER = 'SET_USER'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const SET_TEMP_USER = 'SET_TEMP_USER'
export const SET_SWITCH_PROFILE_MODE  = 'SET_SWITCH_PROFILE_MODE'
export const SET_SCREEN_NAME = 'SET_SCREEN_NAME'
export const SET_PROFILE_EDIT_MODE = 'SET_PROFILE_EDIT_MODE'
export const SET_CALL_USER_API = 'SET_CALL_USER_API'
export const SET_FOLLOW_DETAIL = 'SET_FOLLOW_DETAIL'
export const SET_LOAD_ALL_POST = 'SET_LOAD_ALL_POST'
export const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST'
export const SET_OPEN_NOTIFICATION_BOX = 'SET_OPEN_NOTIFICATION_BOX'