import React from "react"
import {motion} from 'framer-motion'
import { FaPen } from "react-icons/fa"
import roundIcon from '../../../assets/Icons/round.png'
import { useHistory } from "react-router-dom"

const skills = [
    {skillName:"Team Management"},
    {skillName:"Marketing Strategy"},
    {skillName:"Research and Development"},
    {skillName:"Programming"},
    {skillName:"Designing"}
]

const Skills = ({skill,edit}) =>{

    const history =  useHistory()
    return (
        <motion.div className={`relative w-full ${skill === null || skill === 'null' && 'h-40'} text-black flex flex-col md:drop-shadow-xl md:rounded-3xl bg-white mt-2 md:px-2 md:py-4`}>
            <motion.div className={`flex flex-row justify-between md:m-4 m-3`}>
                <motion.div className={`text-xl font-bold`}>
                    Skills
                </motion.div>
                {edit && <motion.div className={`relative cursor-pointer rounded-full backdrop-blur-sm bg-[#2593D2]/30`} style={{ height: '30px', width: '30px' }} onClick={()=>history.push("/editProfile")}>
                    <FaPen className="text-white text-sm m-[8px]" />
                </motion.div>}
            </motion.div>
            <>
                {
                    skill ?  skill.skills.map((c)=>{
                        return <>
                            {
                                c.isActive ? <motion.div className="flex flex-col gap-5 px-5 md:px-10">
                                <motion.div className="flex flex-row justify-start gap-2 mt-2">
                                    <img alt="" className="mt-1" src={roundIcon} style={{height: '15px',width: '12px'}}/>
                                    <motion.div className="text-black text-md">{c.skillName}</motion.div>
                                </motion.div>
                            </motion.div> : ''
                            }
                        </>
                    }) :edit ? <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">Add Skills</motion.div> : <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">No Skills</motion.div>
                }
            </>
        </motion.div>
    )
}

export default Skills