import Home from "./Modules/Home";
import homeIcon from "./assets/Icons/Home.png";
import videoIcon from "./assets/Icons/video.png";
import shopIcon from "./assets/Icons/Shop.png";
import jobIcon from "./assets/Icons/Job.png";
import textIcon from "./assets/Icons/text.png";
import campaignIcon from "./assets/Icons/campaign.png";
import Profile from "./Modules/Profile/Profile";
import EditProfile from "./Modules/Profile/EditProfile";
import Jobs from "./Modules/Jobs";
import Campaign from "./Modules/Campaign";
import Shop from "./Modules/Shop";
import Video from "./Modules/Video";
import Message from "./Modules/Message";
import SinglePost from "./Modules/Post/SinglePost";
import Notification from "./Modules/Notification";

export const sideRoutes = [
  {
    name: "Home",
    link: "/home",
    icon: homeIcon,
    component: Home,
  },
  {
    name: "Jobs",
    link: "/jobs",
    icon: jobIcon,
    component: Jobs,
  },
  {
    name: "Campaign",
    link: "/campaign",
    icon: campaignIcon,
    component: Campaign,
  },
  {
    name: "Shop",
    link: "/shop",
    icon: shopIcon,
    component: Shop,
  },
  {
    name: "Video",
    link: "/video",
    icon: videoIcon,
    component: Video,
  },
  {
    name: "Text",
    link: "/text",
    icon: textIcon,
    component: Message,
  },
];

export const screenRoutes = [
  {
    name: "Home",
    link: "/",
    component: Home,
  },
  {
    name: "Profile",
    link: "/profile",
    component: Profile,
  },
  {
    name: "Edit Profile",
    link: "/editProfile",
    component: EditProfile,
  },
  {
    name: "Profile",
    link: "/profile/:profileId",
    component: Profile,
  },
  {
    name: "Notification",
    link: "/notification",
    component: Notification,
  },
  {
    name: "Post",
    link: "/post/:postId",
    component: SinglePost,
  },
];

export const combinedRoutes = [...sideRoutes, ...screenRoutes];
