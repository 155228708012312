import React, { useState, useEffect, useMemo, useRef } from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import profilePic from "../../assets/dummyProfile.webp";
import { FaPen } from "react-icons/fa";
import coverPng from "../../assets/singupbackground.png";
import FieldInput from "../../Components/FieldInput";
import FieldSelect from "../../Components/FieldSelect";
import API from "../../axios/API";
import agri from "../../assets/Icons/agri.png";
import aqua from "../../assets/Icons/aqua.png";
import { BiUpload, BiTrash } from "react-icons/bi";
import MapContainer from "../../Components/MapContainer";
import axios from "axios";
import { fileUpload } from "../../Components/FileUpload";
import { setAlert } from "../../store/theme/actions";
import { useDispatch } from "react-redux";
import Loaders from "../../Loaders/Loaders";

const initialFormValues = {
  profileImage: "",
  coverImage: "",
  profileName: "",
  userName: "",
  designation: "",
  businessName: "",
  accountTypeId: "",
  phone: "",
  isPhoneEnable: false,
  email: "",
  isEmailEnable: false,
  about: "",
  websiteUrl: "",
  professionals: {
    addressLine1: "",
    pinCode: "",
    country: "",
    latitude: "0.00",
    longitude: "0.00",
    timings: [
      {
        day: "Mon",
        openingTime: "",
        openZone: "",
        closingTime: "",
        closeZone: "",
        isHoliday: false,
      },
      {
        day: "Tue",
        openingTime: "",
        openZone: "",
        closingTime: "",
        closeZone: "",
        isHoliday: false,
      },
      {
        day: "Wed",
        openingTime: "",
        openZone: "",
        closingTime: "",
        closeZone: "",
        isHoliday: false,
      },
      {
        day: "Thu",
        openingTime: "",
        openZone: "",
        closingTime: "",
        closeZone: "",
        isHoliday: false,
      },
      {
        day: "Fri",
        openingTime: "",
        openZone: "",
        closingTime: "",
        closeZone: "",
        isHoliday: false,
      },
      {
        day: "Sat",
        openingTime: "",
        openZone: "",
        closingTime: "",
        closeZone: "",
        isHoliday: false,
      },
      {
        day: "Sun",
        openingTime: "",
        openZone: "",
        closingTime: "",
        closeZone: "",
        isHoliday: false,
      },
    ],
  },
  languages: [
    {
      isActive: true,
      language: "",
      languageLevel: "",
    },
  ],
  skills: [
    {
      isActive: true,
      skillName: "",
    },
  ],
  licenses: [
    {
      isActive: true,
      institutionName: "",
      title: "",
      licenseLogo: "",
      fromMonth: "",
      fromYear: "",
      toMonth: "",
      toYear: "",
      isExpired: false,
    },
  ],
  projects: [
    {
      isActive: true,
      projectTitle: "",
      projectDescription: "",
    },
  ],
  educations: [
    {
      isActive: true,
      schoolName: "",
      schoolLogo: "",
      schoolLocation: "",
      degree: "",
      fromMonth: "",
      fromYear: "",
      toMonth: "",
      toYear: "",
      isPresent: false,
    },
  ],
  experiences: [
    {
      isActive: true,
      companyName: "",
      companyLocation: "",
      companyLogo: "",
      jobs: [
        {
          isActive: true,
          jobTitle: "",
          jobType: "",
          fromMonth: "",
          fromYear: "",
          toMonth: "",
          toYear: "",
          isPresent: false,
        },
      ],
    },
  ],
};

const timeZone = [
  { text: "AM", value: "AM" },
  { text: "PM", value: "PM" },
];
const months = [
  { text: "JAN", value: "Jan" },
  { text: "FEB", value: "Feb" },
  { text: "MAR", value: "Mar" },
  { text: "APR", value: "Apr" },
  { text: "MAY", value: "May" },
  { text: "JUN", value: "Jun" },
  { text: "JUL", value: "Jul" },
  { text: "AUG", value: "Aug" },
  { text: "SEP", value: "Sep" },
  { text: "OCT", value: "Oct" },
  { text: "NOV", value: "Nov" },
  { text: "DEC", value: "Dec" },
];

const EditProfile = () => {
  const history = useHistory();

  const [profileDetails, setProfileDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [langLevel, setLangLevel] = useState([]);
  const [countries, setCountries] = useState([]);
  const [jobType, setJobType] = useState([]);
  const fileInputRef = useRef(null);
  const [imageType, setImageType] = useState();
  const [imageIndex, setImageIndex] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    let yearCal = [];
    for (var i = 1950; i < 2050; i++) {
      yearCal.push({ text: i.toString(), value: i.toString() });
    }
    axios
      .all([
        API.get("/user/getUserInfo"),
        API.get("/menu/languages"),
        API.get("/menu/langLevel"),
        API.get("/menu/countries"),
        API.get("/menu/jobTypes"),
      ])
      .then(
        axios.spread((userResp, langResp, levelResp, countryResp, jobResp) => {
          let profile = userResp.data.data.userDetail;
          profile.educations = JSON.parse(profile.educations);
          profile.experiences = JSON.parse(profile.experiences);
          profile.projects = JSON.parse(profile.projects);
          profile.licenses = JSON.parse(profile.licenses);
          profile.languages = JSON.parse(profile.languages);
          profile.professionals = JSON.parse(profile.professionals);
          profile.skills = JSON.parse(profile.skills);
          setProfileDetails(profile);
          setCountries(
            countryResp.data.body.map((c) => ({ text: c.name, value: c.code }))
          );
          setLanguages(
            langResp.data.body.map((c) => ({
              text: c.language,
              value: c.languageId,
            }))
          );
          setLangLevel(
            levelResp.data.body.map((c) => ({
              text: c.langLevel,
              value: c.langLevelTypeId,
            }))
          );
          setJobType(
            jobResp.data.body.map((c) => ({
              text: c.jobType,
              value: c.jobTypeId,
            }))
          );
          if (yearCal.length === 2050 - 1950) {
            setYears(yearCal);
            setLoading(false);
          }
        })
      );
  }, []);

  const formData = useMemo(() => {
    if (profileDetails && !loading) {
      initialFormValues.isPhoneEnable = profileDetails.isPhoneEnable;
      initialFormValues.isEmailEnable = profileDetails.isEmailEnable;
      initialFormValues.profileImage = profileDetails.profileImage;
      initialFormValues.coverImage = profileDetails.coverImage;
      initialFormValues.designation = profileDetails.designation;
      initialFormValues.profileName =
        profileDetails.firstName !== null &&
        profileDetails.firstName !== undefined &&
        profileDetails.firstName !== "" &&
        profileDetails.lastName !== null &&
        profileDetails.lastName !== undefined &&
        profileDetails.lastName !== ""
          ? profileDetails.firstName + " " + profileDetails.lastName
          : profileDetails.businessName;
      initialFormValues.accountTypeId = profileDetails.accountTypeId;
      initialFormValues.userName = "@" + profileDetails.userName;
      initialFormValues.about = profileDetails.about;
      initialFormValues.websiteUrl = profileDetails.webSite;
      initialFormValues.email = profileDetails.email;
      initialFormValues.phone = profileDetails.phone;
      initialFormValues.professionals =
        profileDetails &&
        profileDetails.professionals &&
        profileDetails.professionals !== null
          ? profileDetails.professionals
          : initialFormValues.professionals;
      initialFormValues.experiences =
        profileDetails &&
        profileDetails.experiences &&
        profileDetails.experiences !== null
          ? profileDetails.experiences.experience
          : initialFormValues.experiences;
      initialFormValues.skills =
        profileDetails &&
        profileDetails.skills &&
        profileDetails.skills !== null
          ? profileDetails.skills.skills
          : initialFormValues.skills;
      initialFormValues.educations =
        profileDetails &&
        profileDetails.educations &&
        profileDetails.educations !== null
          ? profileDetails.educations.educations
          : initialFormValues.educations;
      initialFormValues.languages =
        profileDetails &&
        profileDetails.languages &&
        profileDetails.languages !== null
          ? profileDetails.languages.language
          : initialFormValues.languages;
      initialFormValues.projects =
        profileDetails &&
        profileDetails.projects &&
        profileDetails.projects !== null
          ? profileDetails.projects.projects
          : initialFormValues.projects;
      initialFormValues.licenses =
        profileDetails &&
        profileDetails.licenses &&
        profileDetails.licenses !== null
          ? profileDetails.licenses.license
          : initialFormValues.licenses;
      return { ...initialFormValues };
    }
  });

  const timingSchema = Yup.object().shape({
    day: Yup.string().oneOf(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]),
    openingTime: Yup.number()
      .nullable()
      .test("not-zero", "Invalid Time", function (value) {
        const { closingTime, closeZone } = this.parent;
        if (value === "0") {
          return false;
        } else if (
          closingTime &&
          closeZone &&
          value + this.parent.openZone === closingTime + closeZone
        ) {
          return false;
        } else {
          return true;
        }
      }),
    openZone: Yup.string().nullable(),
    closingTime: Yup.number()
      .nullable()
      .test("not-zero", "Invalid Time", function (value) {
        const { openingTime, openZone } = this.parent;
        if (value === "0") {
          return false;
        } else if (
          openingTime &&
          openZone &&
          openingTime + openZone === value + this.parent.closeZone
        ) {
          return false;
        } else {
          return true;
        }
      }),
    closeZone: Yup.string().nullable(),
    isHoliday: Yup.boolean(),
  });

  const profileFormSchema = Yup.object().shape({
    profileImage: Yup.string().nullable(),
    coverImage: Yup.string().nullable(),
    profileName: Yup.string().nullable(),
    userName: Yup.string().nullable(),
    designation: Yup.string().nullable(),
    accountTypeId: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    isPhoneEnable: Yup.boolean(),
    email: Yup.string().nullable(),
    isEmailEnable: Yup.boolean(),
    about: Yup.string().nullable(),
    websiteUrl: Yup.string().nullable(),
    professionals: Yup.object().shape({
      addressLine1: Yup.string().nullable(),
      pinCode: Yup.string().nullable(),
      country: Yup.string().nullable(),
      latitude: Yup.string().nullable(),
      longitude: Yup.string().nullable(),
      timings: Yup.array().of(timingSchema).min(7),
    }),
    languages: Yup.array().of(
      Yup.object().shape({
        language: Yup.string().nullable(),
        languageLevel: Yup.string().nullable(),
      })
    ),
    projects: Yup.array().of(
      Yup.object().shape({
        projectTitle: Yup.string().nullable(),
        projectDescription: Yup.string().nullable(),
      })
    ),
    licenses: Yup.array().of(
      Yup.object().shape({
        institutionName: Yup.string().nullable(),
        title: Yup.string().nullable(),
        licenseLogo: Yup.string().nullable(),
        fromMonth: Yup.string().nullable(),
        fromYear: Yup.string().nullable(),
        toMonth: Yup.string().nullable(),
        toYear: Yup.string().nullable(),
        isExpired: Yup.boolean(),
      })
    ),
    educations: Yup.array().of(
      Yup.object().shape({
        schoolName: Yup.string().nullable(),
        schoolLocation: Yup.string().nullable(),
        schoolLogo: Yup.string().nullable(),
        degree: Yup.string().nullable(),
        fromMonth: Yup.string().nullable(),
        fromYear: Yup.string().nullable(),
        toMonth: Yup.string().nullable(),
        toYear: Yup.string().nullable(),
        isPresent: Yup.boolean(),
      })
    ),
    experiences: Yup.array().of(
      Yup.object().shape({
        companyName: Yup.string().nullable(),
        companyLocation: Yup.string().nullable(),
        companyLogo: Yup.string().nullable(),
        jobs: Yup.array().of(
          Yup.object().shape({
            jobTitle: Yup.string().nullable(),
            jobType: Yup.string().nullable(),
            fromMonth: Yup.string().nullable(),
            fromYear: Yup.string().nullable(),
            toMonth: Yup.string().nullable(),
            toYear: Yup.string().nullable(),
            isPresent: Yup.boolean(),
          })
        ),
      })
    ),
    skills: Yup.array().of(
      Yup.object().shape({
        skillName: Yup.string().nullable(),
      })
    ),
  });

  const profileForm = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: profileFormSchema,
    onSubmit: (values) => {
      if (profileDetails.educations && profileDetails.educations !== null) {
        profileDetails.educations.educations = values.educations;
      } else {
        profileDetails.educations = { educations: values.educations };
      }
      if (profileDetails.experiences && profileDetails.experiences !== null) {
        profileDetails.experiences.experience = values.experiences;
      } else {
        profileDetails.experiences = { experience: values.experiences };
      }
      if (profileDetails.skills && profileDetails.skills !== null) {
        profileDetails.skills.skills = values.skills;
      } else {
        profileDetails.skills = { skills: values.skills };
      }
      if (profileDetails.educations && profileDetails.educations !== null) {
        profileDetails.educations.educations = values.educations;
      } else {
        profileDetails.educations = { educations: values.educations };
      }
      if (profileDetails.languages && profileDetails.languages !== null) {
        profileDetails.languages.language = values.languages;
      } else {
        profileDetails.languages = { language: values.languages };
      }
      if (profileDetails.projects && profileDetails.projects !== null) {
        profileDetails.projects.projects = values.projects;
      } else {
        profileDetails.projects = { projects: values.projects };
      }
      if (profileDetails.licenses && profileDetails.licenses !== null) {
        profileDetails.licenses.license = values.licenses;
      } else {
        profileDetails.licenses = { license: values.licenses };
      }
      profileDetails.profileImage = values.profileImage;
      profileDetails.coverImage = values.coverImage;
      if (
        profileDetails.firstName !== "" &&
        profileDetails.lastName !== null &&
        profileDetails.firstName !== undefined
      ) {
        profileDetails.firstName = values.profileName.split(" ")[0];
      }
      if (
        profileDetails.lastName !== "" &&
        profileDetails.lastName !== null &&
        profileDetails.lastName !== undefined
      ) {
        profileDetails.lastName = values.profileName.split(" ")[1];
      }
      if (
        profileDetails.businessName !== "" &&
        profileDetails.businessName !== null &&
        profileDetails.businessName !== undefined
      ) {
        profileDetails.businessName = values.profileName;
      }
      profileDetails.userName = values.userName.split("@")[1];
      profileDetails.designation = values.designation;
      profileDetails.accountTypeId = values.accountTypeId;
      profileDetails.phone = values.phone;
      profileDetails.isPhoneEnable = values.isPhoneEnable;
      profileDetails.email = values.email;
      profileDetails.isEmailEnable = values.isEmailEnable;
      profileDetails.about = values.about;
      profileDetails.websiteUrl = values.websiteUrl;
      profileDetails.professionals = values.professionals;
      API.post("/user/userUpdate", profileDetails).then((res) => {
        if (res.status === 200) {
          dispatch(
            setAlert({
              type: "success",
              title: "Success",
              subtitle: "Profile Updated Successfully",
              active: true,
            })
          );
          history.push("/profile");
        }
      });
    },
  });

  const addLanguages = () => {
    if (profileForm.values.languages !== null) {
      profileForm.setValues({
        ...profileForm.values,
        languages: [
          ...profileForm.values.languages,
          { language: "", languageLevel: "", isActive: true },
        ],
      });
    } else {
      profileForm.setValues({
        ...profileForm.values,
        languages: [{ language: "", languageLevel: "", isActive: true }],
      });
    }
  };

  const addSkills = () => {
    if (profileForm.values.skills !== null) {
      profileForm.setValues({
        ...profileForm.values,
        skills: [
          ...profileForm.values.skills,
          { skillName: "", isActive: true },
        ],
      });
    } else {
      profileForm.setValues({
        ...profileForm.values,
        skills: [{ skillName: "", isActive: true }],
      });
    }
  };

  const addLicense = () => {
    if (profileForm.values.licenses !== null) {
      profileForm.setValues({
        ...profileForm.values,
        licenses: [
          ...profileForm.values.licenses,
          {
            institutionName: "",
            title: "",
            licenseLogo: "",
            fromMonth: "",
            fromYear: "",
            toMonth: "",
            toYear: "",
            isExpired: false,
            isActive: true,
          },
        ],
      });
    } else {
      profileForm.setValues({
        ...profileForm.values,
        licenses: [
          {
            institutionName: "",
            title: "",
            licenseLogo: "",
            fromMonth: "",
            fromYear: "",
            toMonth: "",
            toYear: "",
            isExpired: false,
            isActive: true,
          },
        ],
      });
    }
  };

  const addProjects = () => {
    if (profileForm.values.projects !== null) {
      profileForm.setValues({
        ...profileForm.values,
        projects: [
          ...profileForm.values.projects,
          { projectTitle: "", projectDescription: "", isActive: true },
        ],
      });
    } else {
      profileForm.setValues({
        ...profileForm.values,
        projects: [
          { projectTitle: "", projectDescription: "", isActive: true },
        ],
      });
    }
  };

  const addEducation = () => {
    if (profileForm.values.educations !== null) {
      profileForm.setValues({
        ...profileForm.values,
        educations: [
          ...profileForm.values.educations,
          {
            schoolName: "",
            schoolLocation: "",
            degree: "",
            fromMonth: "",
            fromYear: "",
            toMonth: "",
            toYear: "",
            isPresent: false,
            isActive: true,
          },
        ],
      });
    } else {
      profileForm.setValues({
        ...profileForm.values,
        educations: [
          {
            schoolName: "",
            schoolLocation: "",
            degree: "",
            fromMonth: "",
            fromYear: "",
            toMonth: "",
            toYear: "",
            isPresent: false,
            isActive: true,
          },
        ],
      });
    }
  };

  const addExperience = () => {
    if (profileForm.values.experiences !== null) {
      profileForm.setValues({
        ...profileForm.values,
        experiences: [
          ...profileForm.values.experiences,
          {
            companyName: "",
            companyLocation: "",
            companyLogo: "",
            jobs: [
              {
                jobTitile: "",
                jobType: "",
                fromMonth: "",
                fromYear: "",
                toMonth: "",
                toYear: "",
                isPresent: false,
                isActive: true,
              },
            ],
            isActive: true,
          },
        ],
      });
    } else {
      profileForm.setValues({
        ...profileForm.values,
        experiences: [
          {
            companyName: "",
            companyLocation: "",
            companyLogo: "",
            jobs: [
              {
                jobTitile: "",
                jobType: "",
                fromMonth: "",
                fromYear: "",
                toMonth: "",
                toYear: "",
                isPresent: false,
                isActive: true,
              },
            ],
            isActive: true,
          },
        ],
      });
    }
  };

  const addPositions = (i) => {
    let obj = {
      jobTitile: "",
      jobType: "",
      fromMonth: "",
      fromYear: "",
      toMonth: "",
      toYear: "",
      isPresent: false,
      isActive: true,
    };
    profileForm.values.experiences[i].jobs.push(obj);
    profileForm.setValues({
      ...profileForm.values,
      experiences: profileForm.values.experiences,
    });
  };

  const handleFileSelect = async (event) => {
    if (imageType === "profile") {
      let result = await fileUpload(event.target.files[0]);
      profileForm.setValues({
        ...profileForm.values,
        profileImage: result.Location,
      });
    } else if (imageType === "cover") {
      let result = await fileUpload(event.target.files[0]);
      profileForm.setValues({
        ...profileForm.values,
        coverImage: result.Location,
      });
    } else if (imageType === "experience") {
      let result = await fileUpload(event.target.files[0]);
      profileForm.values.experiences[imageIndex].companyLogo = result.Location;
      profileForm.setValues({
        ...profileForm.values,
        experiences: profileForm.values.experiences,
      });
    } else if (imageType === "education") {
      let result = await fileUpload(event.target.files[0]);
      profileForm.values.educations[imageIndex].schoolLogo = result.Location;
      profileForm.setValues({
        ...profileForm.values,
        educations: profileForm.values.educations,
      });
    } else if (imageType === "license") {
      let result = await fileUpload(event.target.files[0]);
      profileForm.values.licenses[imageIndex].licenseLogo = result.Location;
      profileForm.setValues({
        ...profileForm.values,
        licenses: profileForm.values.licenses,
      });
    }
  };

  const handleUploadClick = (type, i) => {
    fileInputRef.current.click();
    setImageType(type);
    setImageIndex(i);
  };

  const deleteLists = (type, i) => {
    if (type === "skills") {
      if (profileForm.values.skills[i].skillName !== "") {
        profileForm.values.skills[i].isActive = false;
        profileForm.setValues({
          ...profileForm.values,
          skills: profileForm.values.skills,
        });
      } else {
        profileForm.values.skills.map((c, k) => {
          if (k === i) {
            profileForm.values.skills.splice(k, 1);
          }
        });
        profileForm.setValues({
          ...profileForm.values,
          skills: profileForm.values.skills,
        });
      }
    } else if (type === "lang") {
      if (profileForm.values.languages[i].language !== "") {
        profileForm.values.languages[i].isActive = false;
        profileForm.setValues({
          ...profileForm.values,
          languages: profileForm.values.languages,
        });
      } else {
        profileForm.values.languages.map((c, k) => {
          if (k === i) {
            profileForm.values.languages.splice(k, 1);
          }
        });
        profileForm.setValues({
          ...profileForm.values,
          languages: profileForm.values.languages,
        });
      }
    } else if (type === "projects") {
      if (profileForm.values.projects[i].projectTitle !== "") {
        profileForm.values.projects[i].isActive = false;
        profileForm.setValues({
          ...profileForm.values,
          projects: profileForm.values.projects,
        });
      } else {
        profileForm.values.projects.map((c, k) => {
          if (k === i) {
            profileForm.values.projects.splice(k, 1);
          }
        });
        profileForm.setValues({
          ...profileForm.values,
          projects: profileForm.values.projects,
        });
      }
    } else if (type === "license") {
      if (profileForm.values.licenses[i].institutionName !== "") {
        profileForm.values.licenses[i].isActive = false;
        profileForm.setValues({
          ...profileForm.values,
          licenses: profileForm.values.licenses,
        });
      } else {
        profileForm.values.licenses.map((c, k) => {
          if (k === i) {
            profileForm.values.licenses.splice(k, 1);
          }
        });
        profileForm.setValues({
          ...profileForm.values,
          licenses: profileForm.values.licenses,
        });
      }
    } else if (type === "educations") {
      if (profileForm.values.educations[i].schoolName !== "") {
        profileForm.values.educations[i].isActive = false;
        profileForm.setValues({
          ...profileForm.values,
          educations: profileForm.values.educations,
        });
      } else {
        profileForm.values.educations.map((c, k) => {
          if (k === i) {
            profileForm.values.educations.splice(k, 1);
          }
        });
        profileForm.setValues({
          ...profileForm.values,
          educations: profileForm.values.educations,
        });
      }
    } else if (type === "experiences") {
      if (profileForm.values.experiences[i].companyName !== "") {
        profileForm.values.experiences[i].isActive = false;
        profileForm.setValues({
          ...profileForm.values,
          experiences: profileForm.values.experiences,
        });
      } else {
        profileForm.values.experiences.map((c, k) => {
          if (k === i) {
            profileForm.values.experiences.splice(k, 1);
          }
        });
        profileForm.setValues({
          ...profileForm.values,
          experiences: profileForm.values.experiences,
        });
      }
    }
  };

  const deleteNestedList = (type, k, l) => {
    if (type === "jobsPositionList") {
      if (profileForm.values.experiences[k].jobs[l].jobTitle !== "") {
        profileForm.values.experiences[k].jobs[l].isActive = false;
        profileForm.setValues({
          ...profileForm.values,
          experiences: profileForm.values.experiences,
        });
      } else {
        profileForm.values.experiences[k].jobs.map((c, index) => {
          if (l === index) {
            profileForm.values.experiences[k].jobs.splice(index, 1);
          }
        });
      }
    }
  };

  const selectButton = (type, event, i, k) => {
    if (type === "timings") {
      if (
        profileForm.values.professionals.timings[i] &&
        profileForm.values.professionals.timings[i].isHoliday !== undefined
      ) {
        profileForm.values.professionals.timings[i].isHoliday = event;
        profileForm.setValues({
          ...profileForm.values,
          professionals: {
            ...profileForm.values.professionals,
            timings: profileForm.values.professionals.timings,
          },
        });
      } else {
        profileForm.values.professionals.timings[i].isHoliday = event;
        // profileForm.setValues({ ...profileForm.values,professional: { ...profileForm.values.professionals,timings: profileForm.values.professionals.timings}})
      }
    } else if (type === "jobs") {
      profileForm.values.experiences[i].jobs[k].isPresent = event;
      profileForm.setValues({
        ...profileForm.values,
        experiences: profileForm.values.experiences,
      });
    } else if (type === "education") {
      profileForm.values.educations[i].isPresent = event;
      profileForm.setValues({
        ...profileForm.values,
        educations: profileForm.values.educations,
      });
    } else if (type === "phone") {
      let isPhoneEnable = event;
      profileForm.setValues({ ...profileForm.values, isPhoneEnable });
    } else if (type === "email") {
      let isEmailEnable = event;
      profileForm.setValues({ ...profileForm.values, isEmailEnable });
    } else if (type === "licenses") {
      profileForm.values.licenses[i].isExpired = event;
      profileForm.setValues({
        ...profileForm.values,
        licenses: profileForm.values.licenses,
      });
    }
  };

  const getCurrentLocation = (eve) => {
    if (eve.checked && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          profileForm.setValues({
            ...profileForm.values,
            professionals: {
              ...profileForm.values.professionals,
              latitude: position.coords.latitude.toString(),
              longitude: position.coords.longitude.toString(),
            },
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };
  return (
    <>
      {loading ? (
        <Loaders />
      ) : (
        <motion.div
          className={`relative text-black min-h-screen bg-[white] overflow-auto mt-2 w-full mb-10`}
        >
          {/* Desktop */}
          <motion.div className={`relative hidden md:block`}>
            <input
              type="file"
              ref={fileInputRef}
              accept=".png, .jpeg, .jpg, .webp"
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
            <motion.div
              className={`fixed min-h-screen z-10 items-center left-20 justify-center flex flex-col overflow-hidden`}
            >
              <motion.div
                className={`relative bg-white drop-shadow p-2 flex flex-col gap-10`}
              >
                <motion.div
                  className={`relative bg-sky-200 py-4 px-4 text-white text-md font-bold rounded-lg cursor-pointer`}
                  onClick={() => profileForm.submitForm()}
                >
                  Save
                </motion.div>
                <motion.div
                  className={`relative bg-[#FACACB] py-4 px-3 text-white text-md font-bold rounded-lg cursor-pointer`}
                  onClick={() => history.push("/profile")}
                >
                  Cancel
                </motion.div>
              </motion.div>
            </motion.div>
            <motion.div className={`realtive grid grid-cols-1 gap-5 px-12`}>
              {/* Basic  */}
              <motion.div className="relative">
                <motion.div
                  className={`relative text-black text-md font-bold mt-2 mb-2`}
                >
                  Basic Info
                </motion.div>
                <motion.div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-2 px-12">
                  <motion.div className={`relative`}>
                    <motion.div className="profile">
                      <img
                        src={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.profileImage &&
                          profileForm.values.profileImage !== null
                            ? profileForm.values.profileImage
                            : profilePic
                        }
                        alt=""
                        height={150}
                        width={150}
                      />
                      <motion.div
                        className={`absolute top-0 cursor-pointer left-28 rounded-full backdrop-blur-sm bg-[#2593D2]/30`}
                        style={{ height: "40px", width: "40px" }}
                        onClick={() => handleUploadClick("profile", 0)}
                      >
                        <FaPen className="text-white m-3" />
                      </motion.div>
                    </motion.div>
                    <motion.div className="relative mt-10">
                      <motion.div
                        className={`absolute flex flex-row ring-1 ring-white drop-shadow-lg`}
                      >
                        <motion.div
                          class="absolute  overflow-hidden inline-block"
                          style={{ width: "190px" }}
                        >
                          <motion.div
                            class=" bg-white rotate-45 transform origin-top-right"
                            style={{ height: "330px" }}
                          ></motion.div>
                        </motion.div>
                        <img
                          src={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.coverImage &&
                            profileForm.values.coverImage !== null
                              ? profileForm.values.coverImage
                              : coverPng
                          }
                          style={{ height: "200px", width: "300px" }}
                          alt=""
                        />
                        <motion.div
                          class="absolute bottom-0 right-0 overflow-hidden inline-block"
                          style={{ width: "190px" }}
                        >
                          <motion.div
                            class="bg-white rotate-45 transform origin-bottom-left"
                            style={{ height: "330px" }}
                          ></motion.div>
                        </motion.div>
                      </motion.div>
                      <motion.div
                        className={`absolute top-36 cursor-pointer left-60  rounded-full backdrop-blur-sm bg-[#2593D2]/30`}
                        style={{ height: "40px", width: "40px" }}
                        onClick={() => handleUploadClick("cover", 0)}
                      >
                        <FaPen className="text-white m-3" />
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className="relative">
                    <FieldInput
                      labelLength="57"
                      label={"Name "}
                      labelSide="side"
                      bgColor={"bg-[#2593D2]/20"}
                      injectClass={"w-[100%]"}
                      fieldProps={profileForm.getFieldProps("profileName")}
                      fieldMeta={profileForm.getFieldMeta("profileName")}
                      fieldHelper={profileForm.getFieldHelpers("profileName")}
                      edit={true}
                      dataLoaded={true}
                    />
                    <FieldInput
                      label={"Account "}
                      disabled={true}
                      labelSide="side"
                      injectClass={"w-[100%]"}
                      bgColor={"bg-[#2593D2]/20"}
                      fieldProps={profileForm.getFieldProps("userName")}
                      fieldMeta={profileForm.getFieldMeta("userName")}
                      fieldHelper={profileForm.getFieldHelpers("userName")}
                      edit={true}
                      dataLoaded={true}
                    />
                    <FieldInput
                      labelLength="57"
                      label={`I'm a/an`}
                      labelSide="side"
                      injectClass={"w-[90%]"}
                      bgColor={"bg-[#ED1F24]/20"}
                      fieldProps={profileForm.getFieldProps("designation")}
                      fieldMeta={profileForm.getFieldMeta("designation")}
                      fieldHelper={profileForm.getFieldHelpers("designation")}
                      edit={true}
                      dataLoaded={true}
                    />
                    <motion.div className="flex flex-row justify-start gap-10">
                      <lable>I'm in</lable>
                      <motion.div
                        className={`${
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.accountTypeId === "1" &&
                          "bg-[#2593D2]/20"
                        } px-1 py-1 rounded-xl drop-shadow-lg`}
                      >
                        <motion.div className="bg-white drop-shadow-xl flex flex-row justify-center px-2 py-5 rounded-xl">
                          <img src={agri} alt="" />
                        </motion.div>
                        <motion.div
                          className={`${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.accountTypeId === "1"
                              ? "text-white"
                              : "text-[grey]"
                          } text-xl font-bold text-center p-5`}
                        >
                          Agriculture
                        </motion.div>
                      </motion.div>
                      <motion.div
                        className={`${
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.accountTypeId === "2" &&
                          "bg-[#2593D2]/20"
                        } px-1 py-1 rounded-xl drop-shadow-lg`}
                      >
                        <motion.div className="bg-white drop-shadow-xl flex flex-row justify-center px-2 py-5 rounded-xl">
                          <img src={aqua} alt="" />
                        </motion.div>
                        <motion.div
                          className={`${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.accountTypeId === "2"
                              ? "text-white"
                              : "text-[grey]"
                          } text-xl font-bold text-center p-5`}
                        >
                          Aquaculture
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="flex flex-row justify-start gap-2">
                      <motion.div className="w-[400px]">
                        <FieldInput
                          labelLength="56"
                          label={"Phone "}
                          labelSide="side"
                          bgColor={"bg-[#2593D2]/20"}
                          injectClass={"w-[100%]"}
                          fieldProps={profileForm.getFieldProps("phone")}
                          fieldMeta={profileForm.getFieldMeta("phone")}
                          fieldHelper={profileForm.getFieldHelpers("phone")}
                          edit={true}
                          dataLoaded={true}
                        />
                      </motion.div>
                      <motion.div className="flex flex-row justify-start gap-2">
                        <motion.div
                          className={`relative cursor-pointer p-2 mt-5 right-2 ${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.isPhoneEnable
                              ? "ring-1 ring-[#2593D2] bg-[#2593D2]"
                              : "ring-1 ring-[#707070] bg-[#707070]"
                          } rounded-full w-[1px] h-[1px] flex flex-col`}
                          onClick={() =>
                            selectButton(
                              "phone",
                              !profileForm.values.isPhoneEnable,
                              "",
                              ""
                            )
                          }
                        >
                          <>
                            {profileForm &&
                            profileForm.values &&
                            profileForm.values.isPhoneEnable ? (
                              <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                            ) : (
                              <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                            )}
                          </>
                        </motion.div>
                        <motion.div
                          className={`text-black text-sm flex flex-col justify-center mb-4`}
                        >
                          Show Call Button
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="flex flex-row justify-start gap-2">
                      <motion.div className="w-[400px]">
                        <FieldInput
                          labelLength="56"
                          label={"Email "}
                          labelSide="side"
                          bgColor={"bg-[#2593D2]/20"}
                          injectClass={"w-[100%]"}
                          fieldProps={profileForm.getFieldProps("email")}
                          fieldMeta={profileForm.getFieldMeta("email")}
                          fieldHelper={profileForm.getFieldHelpers("email")}
                          edit={true}
                          dataLoaded={true}
                        />
                      </motion.div>
                      <motion.div className="flex flex-row justify-start gap-2">
                        <motion.div
                          className={`relative cursor-pointer p-2 mt-5 right-2 ${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.isEmailEnable
                              ? "ring-1 ring-[#2593D2] bg-[#2593D2]"
                              : "ring-1 ring-[#707070] bg-[#707070]"
                          } rounded-full w-[1px] h-[1px] flex flex-col`}
                          onClick={() =>
                            selectButton(
                              "email",
                              !profileForm.values.isEmailEnable,
                              "",
                              ""
                            )
                          }
                        >
                          <>
                            {profileForm &&
                            profileForm.values &&
                            profileForm.values.isEmailEnable ? (
                              <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                            ) : (
                              <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                            )}
                          </>
                        </motion.div>
                        <motion.div
                          className={`text-black text-sm flex flex-col justify-center mb-4`}
                        >
                          Show Email Button
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* About */}
              <motion.div className="relative text-black text-md font-bold mt-2 mb-2">
                About
              </motion.div>
              <motion.div className="relative">
                <FieldInput
                  type={"textarea"}
                  row={"12"}
                  bgColor={"bg-[#2593D2]/20"}
                  injectClass={"w-[100%]"}
                  fieldProps={profileForm.getFieldProps("about")}
                  fieldMeta={profileForm.getFieldMeta("about")}
                  fieldHelper={profileForm.getFieldHelpers("about")}
                  edit={true}
                  dataLoaded={true}
                />
              </motion.div>
              <hr />
              {/* Professional */}
              <motion.div className="relative text-black text-md font-bold mt-2 mb-2">
                Professional Summary
              </motion.div>
              <motion.div className="relative">
                <motion.div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-20">
                  <motion.div className="grid grid-cols-1">
                    <FieldInput
                      labelLength="46"
                      radioOption={true}
                      label={"Web"}
                      labelSide="side"
                      bgColor={"bg-[#2593D2]/20"}
                      injectClass={"w-[100%]"}
                      fieldProps={profileForm.getFieldProps("websiteUrl")}
                      fieldMeta={profileForm.getFieldMeta("websiteUrl")}
                      fieldHelper={profileForm.getFieldHelpers("websiteUrl")}
                      edit={true}
                      dataLoaded={true}
                    />
                    <FieldInput
                      labelLength="46"
                      radioOption={true}
                      label={"Email"}
                      labelSide="side"
                      bgColor={"bg-[#2593D2]/20"}
                      injectClass={"w-[100%]"}
                      fieldProps={profileForm.getFieldProps("email")}
                      fieldMeta={profileForm.getFieldMeta("email")}
                      fieldHelper={profileForm.getFieldHelpers("email")}
                      edit={true}
                      dataLoaded={true}
                    />
                    <FieldInput
                      label={"Phone"}
                      radioOption={true}
                      labelSide="side"
                      bgColor={"bg-[#2593D2]/20"}
                      injectClass={"w-[100%]"}
                      fieldProps={profileForm.getFieldProps("phone")}
                      fieldMeta={profileForm.getFieldMeta("phone")}
                      fieldHelper={profileForm.getFieldHelpers("phone")}
                      edit={true}
                      dataLoaded={true}
                    />
                  </motion.div>
                  <motion.div className="grid grid-cols-1">
                    <motion.div className="relative -top-8">
                      <motion.div className="flex top-10 left-12 text-black text-xl">
                        Address:
                        <motion.div className="relative p-2 mt-2 mb-2 ml-3 ring-1 ring-black rounded-full w-[2px] h-[2px] flex flex-col">
                          <motion.div className="absolute ring ring-1 top-1 right-1  ring-black rounded-full bg-black p-1 w-[1px] h-[1px]"></motion.div>
                        </motion.div>
                      </motion.div>
                      <FieldInput
                        labelLength="56"
                        radioOption={true}
                        label={"Line 1"}
                        labelSide="side"
                        bgColor={"bg-[#2593D2]/20"}
                        injectClass={"w-[90%]"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.addressLine1"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.addressLine1"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.addressLine1"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldInput
                        labelLength="56"
                        radioOption={true}
                        label={"Pincode"}
                        labelSide="side"
                        bgColor={"bg-[#2593D2]/20"}
                        injectClass={"w-[100%]"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.pinCode"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.pinCode"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.pinCode"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        label={"Country"}
                        labelSide="side"
                        radioOption={true}
                        bgColor={"bg-[#ED1F24]/20"}
                        injectClass={"w-[79%]"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.country"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.country"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.country"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={countries}
                      />
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div className="grid grid-cols-2 sm:grid-cols-1 mt-3 md:grid-cols-2 gap-20">
                  <motion.div className="grid grid-cols-1">
                    <motion.div className="flex text-black text-xl">
                      Timings:
                      <motion.div className="relative p-2 mt-2 mb-2 ml-3 ring-1 ring-black rounded-full w-[2px] h-[2px] flex flex-col">
                        <motion.div className="absolute ring ring-1 top-1 right-1 ring-[#707070] bg-[#707070] rounded-full bg-black p-1 w-[1px] h-[1px]"></motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="grid grid-cols-4 gap-2 text-center">
                      <motion.div></motion.div>
                      <motion.div>Opening</motion.div>
                      <motion.div>Closing</motion.div>
                      <motion.div>Holiday</motion.div>
                    </motion.div>
                    <motion.div className="grid grid-cols-4 gap-2">
                      <motion.div className="mt-4 text-end mr-2 ml-2">
                        Mon
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[0] &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[0].openingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[0].openingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[0].openingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[0] &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[0].openZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[0].openZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[0].openZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          injectClass={"h-8"}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[0] &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday
                          }
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[0].closingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[0].closingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[0].closingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[0] &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[0].closeZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[0].closeZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[0].closeZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="flex justify-center items-center">
                        <motion.div
                          className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[0] &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday
                              ? "ring-1 ring-[#2593D2] bg-[#2593D2]"
                              : "ring-1 ring-[#707070] bg-[#707070]"
                          } rounded-full w-[2px] h-[2px] flex flex-col`}
                          onClick={() =>
                            selectButton(
                              "timings",
                              !profileForm.values.professionals.timings[0]
                                .isHoliday,
                              0,
                              ""
                            )
                          }
                        >
                          <>
                            {profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[0] &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[0]
                              .isHoliday ? (
                              <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                            ) : (
                              <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                            )}
                          </>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="grid grid-cols-4 gap-2">
                      <motion.div className="mt-4 text-end mr-2 ml-2">
                        Tue
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[1] &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[1].openingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[1].openingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[1].openingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[1] &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[1].openZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[1].openZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[1].openZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[1] &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[1].closingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[1].closingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[1].closingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[1] &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[1].closeZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[1].closeZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[1].closeZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="flex justify-center items-center">
                        <motion.div
                          className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[1] &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday
                              ? "ring-1 ring-[#2593D2] bg-[#2593D2]"
                              : "ring-1 ring-[#707070] bg-[#707070]"
                          } rounded-full w-[2px] h-[2px] flex flex-col`}
                          onClick={() =>
                            selectButton(
                              "timings",
                              !profileForm.values.professionals.timings[1]
                                .isHoliday,
                              1,
                              ""
                            )
                          }
                        >
                          <>
                            {profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[1] &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday !== undefined &&
                            profileForm.values.professionals.timings[1]
                              .isHoliday ? (
                              <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                            ) : (
                              <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                            )}
                          </>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="grid grid-cols-4 gap-2">
                      <motion.div className="mt-4 text-end mr-2 ml-2">
                        Wed
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          injectClass={"h-8"}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[2] &&
                            profileForm.values.professionals.timings[2]
                              .isHoliday
                          }
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[2].openingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[2].openingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[2].openingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[2] &&
                            profileForm.values.professionals.timings[2]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[2].openZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[2].openZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[2].openZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          injectClass={"h-8"}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[2] &&
                            profileForm.values.professionals.timings[2]
                              .isHoliday
                          }
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[2].closingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[2].closingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[2].closingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[2] &&
                            profileForm.values.professionals.timings[2]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[2].closeZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[2].closeZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[2].closeZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="flex justify-center items-center">
                        <motion.div
                          className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[2] &&
                            profileForm.values.professionals.timings[2]
                              .isHoliday
                              ? "ring-1 ring-[#2593D2] bg-[#2593D2]"
                              : "ring-1 ring-[#707070] bg-[#707070]"
                          } rounded-full w-[2px] h-[2px] flex flex-col`}
                          onClick={() =>
                            selectButton(
                              "timings",
                              !profileForm.values.professionals.timings[2]
                                .isHoliday,
                              2,
                              ""
                            )
                          }
                        >
                          <>
                            {profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[2] &&
                            profileForm.values.professionals.timings[2]
                              .isHoliday ? (
                              <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                            ) : (
                              <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                            )}
                          </>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="grid grid-cols-4 gap-2">
                      <motion.div className="mt-4 text-end mr-2 ml-2">
                        Thu
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          injectClass={"h-8"}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[3] &&
                            profileForm.values.professionals.timings[3]
                              .isHoliday
                          }
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[3].openingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[3].openingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[3].openingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[3] &&
                            profileForm.values.professionals.timings[3]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[3].openZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[3].openZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[3].openZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          injectClass={"h-8"}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[3] &&
                            profileForm.values.professionals.timings[3]
                              .isHoliday
                          }
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[3].closingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[3].closingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[3].closingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[3] &&
                            profileForm.values.professionals.timings[3]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[3].closeZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[3].closeZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[3].closeZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="flex justify-center items-center">
                        <motion.div
                          className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[3] &&
                            profileForm.values.professionals.timings[3]
                              .isHoliday
                              ? "ring-1 ring-[#2593D2] bg-[#2593D2]"
                              : "ring-1 ring-[#707070] bg-[#707070]"
                          } rounded-full w-[2px] h-[2px] flex flex-col`}
                          onClick={() =>
                            selectButton(
                              "timings",
                              !profileForm.values.professionals.timings[3]
                                .isHoliday,
                              3,
                              ""
                            )
                          }
                        >
                          <>
                            {profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[3] &&
                            profileForm.values.professionals.timings[3]
                              .isHoliday ? (
                              <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                            ) : (
                              <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                            )}
                          </>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="grid grid-cols-4 gap-2">
                      <motion.div className="mt-4 text-end mr-2 ml-2">
                        Fri
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          injectClass={"h-8"}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[4] &&
                            profileForm.values.professionals.timings[4]
                              .isHoliday
                          }
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[4].openingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[4].openingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[4].openingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[4] &&
                            profileForm.values.professionals.timings[4]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[4].openZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[4].openZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[4].openZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          injectClass={"h-8"}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[4] &&
                            profileForm.values.professionals.timings[4]
                              .isHoliday
                          }
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[4].closingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[4].closingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[4].closingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[4] &&
                            profileForm.values.professionals.timings[4]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[4].closeZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[4].closeZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[4].closeZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="flex justify-center items-center">
                        <motion.div
                          className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[4] &&
                            profileForm.values.professionals.timings[4]
                              .isHoliday
                              ? "ring-1 ring-[#2593D2] bg-[#2593D2]"
                              : "ring-1 ring-[#707070] bg-[#707070]"
                          } rounded-full w-[2px] h-[2px] flex flex-col`}
                          onClick={() =>
                            selectButton(
                              "timings",
                              !profileForm.values.professionals.timings[4]
                                .isHoliday,
                              4,
                              ""
                            )
                          }
                        >
                          <>
                            {profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[4] &&
                            profileForm.values.professionals.timings[4]
                              .isHoliday ? (
                              <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                            ) : (
                              <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                            )}
                          </>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="grid grid-cols-4 gap-2">
                      <motion.div className="mt-4 text-end mr-2 ml-2">
                        Sat
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          injectClass={"h-8"}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[5] &&
                            profileForm.values.professionals.timings[5]
                              .isHoliday
                          }
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[5].openingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[5].openingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[5].openingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[5] &&
                            profileForm.values.professionals.timings[5]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[5].openZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[5].openZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[5].openZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          injectClass={"h-8"}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[5] &&
                            profileForm.values.professionals.timings[5]
                              .isHoliday
                          }
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[5].closingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[5].closingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[5].closingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[5] &&
                            profileForm.values.professionals.timings[5]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[5].closeZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[5].closeZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[5].closeZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="flex justify-center items-center">
                        <motion.div
                          className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[5] &&
                            profileForm.values.professionals.timings[5]
                              .isHoliday
                              ? "ring-1 ring-[#2593D2] bg-[#2593D2]"
                              : "ring-1 ring-[#707070] bg-[#707070]"
                          } rounded-full w-[2px] h-[2px] flex flex-col`}
                          onClick={() =>
                            selectButton(
                              "timings",
                              !profileForm.values.professionals.timings[5]
                                .isHoliday,
                              5,
                              ""
                            )
                          }
                        >
                          <>
                            {profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[5] &&
                            profileForm.values.professionals.timings[5]
                              .isHoliday ? (
                              <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                            ) : (
                              <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                            )}
                          </>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="grid grid-cols-4 gap-2">
                      <motion.div className="mt-4 text-end mr-2 ml-2">
                        Sun
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          injectClass={"h-8"}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[6] &&
                            profileForm.values.professionals.timings[6]
                              .isHoliday
                          }
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[6].openingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[6].openingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[6].openingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[6] &&
                            profileForm.values.professionals.timings[6]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[6].openZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[6].openZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[6].openZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="grid grid-cols-2 gap-2">
                        <FieldInput
                          miniBox={true}
                          injectClass={"h-8"}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[6] &&
                            profileForm.values.professionals.timings[6]
                              .isHoliday
                          }
                          bgColor={"bg-[#2593D2]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[6].closingTime"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[6].closingTime"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[6].closingTime"
                          )}
                          edit={true}
                          dataLoaded={true}
                        />
                        <FieldSelect
                          arrowEnd={true}
                          valuePadding={false}
                          disabled={
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[6] &&
                            profileForm.values.professionals.timings[6]
                              .isHoliday
                          }
                          injectClass={"h-8"}
                          bgColor={"bg-[#ED1F24]/20"}
                          fieldProps={profileForm.getFieldProps(
                            "professionals.timings[6].closeZone"
                          )}
                          fieldMeta={profileForm.getFieldMeta(
                            "professionals.timings[6].closeZone"
                          )}
                          fieldHelper={profileForm.getFieldHelpers(
                            "professionals.timings[6].closeZone"
                          )}
                          edit={true}
                          dataLoaded={true}
                          options={timeZone}
                        />
                      </motion.div>
                      <motion.div className="flex justify-center items-center">
                        <motion.div
                          className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[6] &&
                            profileForm.values.professionals.timings[6]
                              .isHoliday
                              ? "ring-1 ring-[#2593D2] bg-[#2593D2]"
                              : "ring-1 ring-[#707070] bg-[#707070]"
                          } rounded-full w-[2px] h-[2px] flex flex-col`}
                          onClick={() =>
                            selectButton(
                              "timings",
                              !profileForm.values.professionals.timings[6]
                                .isHoliday,
                              6,
                              ""
                            )
                          }
                        >
                          <>
                            {profileForm &&
                            profileForm.values &&
                            profileForm.values.professionals !== null &&
                            profileForm.values.professionals.timings &&
                            profileForm.values.professionals.timings[6] &&
                            profileForm.values.professionals.timings[6]
                              .isHoliday ? (
                              <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                            ) : (
                              <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                            )}
                          </>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className="grid grid-cols-1">
                    <motion.div className="flex text-black text-xl">
                      Location:
                      <motion.div className="relative p-2 mt-2 mb-2 ml-3 ring-1 ring-black rounded-full w-[2px] h-[2px] flex flex-col">
                        <motion.div className="absolute ring ring-1 top-1 right-1  ring-black rounded-full bg-black p-1 w-[1px] h-[1px]"></motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div
                      className="relative grid grid-cols-2 gap-10"
                      style={{ marginTop: "-33%" }}
                    >
                      <motion.div className="w-100 relative">
                        {/* <img className="rounded-lg" src={dummyMap} alt="" /> */}
                        <div>
                          <MapContainer
                            latitude={parseFloat(
                              profileForm &&
                                profileForm.values &&
                                profileForm.values.professionals !== null &&
                                profileForm.values.professionals.latitude
                            )}
                            longitude={parseFloat(
                              profileForm &&
                                profileForm.values &&
                                profileForm.values.professionals !== null &&
                                profileForm.values.professionals.longitude
                            )}
                          />
                        </div>
                      </motion.div>
                      <motion.div className="relative flex flex-col">
                        <motion.div className="flex flex-row justify-start gap-2">
                          <input
                            className="m-1"
                            type="checkbox"
                            onChange={(ev) => getCurrentLocation(ev.target)}
                          />
                          <span>User My Current Location</span>
                        </motion.div>
                        <motion.div className="flex flex-row justify-start gap-2">
                          <input className="m-1" type="checkbox" />
                          <span>Locate my address</span>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* Experience */}
              <motion.div className="relative">
                <motion.div
                  className={`relative text-black text-md font-bold mt-2 mb-2`}
                >
                  Experience
                </motion.div>
                <motion.div className="relative grid grid-cols-1">
                  {profileForm &&
                    profileForm.values &&
                    profileForm.values.experiences !== null &&
                    profileForm.values.experiences.map((c, i) => {
                      return (
                        <>
                          {c.isActive && (
                            <>
                              <motion.div className="relative">
                                <motion.div className="relative flex flex-row justify-start gap-10">
                                  <motion.div
                                    className="text-md text-black mt-5"
                                    style={{ width: "80px" }}
                                  >
                                    Company :{" "}
                                  </motion.div>
                                  <FieldInput
                                    bgColor={"bg-[#2593D2]/20"}
                                    injectClass={"w-[152%]"}
                                    fieldProps={profileForm.getFieldProps(
                                      `experiences.${i}.companyName`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `experiences.${i}.companyName`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `experiences.${i}.companyName`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                  />
                                  <motion.div
                                    className={`ml-[100px] mt-2 relative bg-white drop-shadow-lg ring-1 ring-white ${
                                      c.companyLogo && c.companyLogo !== ""
                                        ? ""
                                        : "p-5"
                                    } rounded-lg`}
                                    style={{ width: "50px", height: "50px" }}
                                    onClick={() =>
                                      handleUploadClick("experience", i)
                                    }
                                  >
                                    {c.companyLogo && c.companyLogo !== "" ? (
                                      <img
                                        src={c.companyLogo}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    ) : (
                                      <BiUpload className="flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                    )}
                                  </motion.div>
                                </motion.div>
                                <motion.div className="relative flex flex-1 flex-row justify-start gap-10">
                                  <motion.div
                                    className="text-md text-black mt-5"
                                    style={{ width: "54px" }}
                                  >
                                    Place :{" "}
                                  </motion.div>
                                  <motion.div className="w-[245px]">
                                    <FieldSelect
                                      fullWidth={true}
                                      bgColor={"bg-[#ED1F24]/20"}
                                      injectClass={"w-[140%] ml-6"}
                                      fieldProps={profileForm.getFieldProps(
                                        `experiences.${i}.companyLocation`
                                      )}
                                      fieldMeta={profileForm.getFieldMeta(
                                        `experiences.${i}.companyLocation`
                                      )}
                                      fieldHelper={profileForm.getFieldHelpers(
                                        `experiences.${i}.companyLocation`
                                      )}
                                      edit={true}
                                      dataLoaded={true}
                                      options={countries}
                                    />
                                  </motion.div>
                                  <motion.div
                                    className={`ml-[100px] mt-2 relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                    style={{ width: "50px", height: "50px" }}
                                    onClick={() =>
                                      deleteLists("experiences", i)
                                    }
                                  >
                                    <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                  </motion.div>
                                </motion.div>
                              </motion.div>
                              <motion.div className="relative flex flex-col mb-10">
                                {c.jobs.map((e, j) => {
                                  return (
                                    <>
                                      {e.isActive && (
                                        <motion.div className="relative">
                                          <motion.div className="relative flex flex-1 flex-row justify-start gap-10">
                                            <motion.div
                                              className="text-md text-black mt-5"
                                              style={{ width: "74px" }}
                                            >
                                              Job Title :{" "}
                                            </motion.div>
                                            <FieldInput
                                              bgColor={"bg-[#2593D2]/20"}
                                              injectClass={"w-[150%] ml-1"}
                                              fieldProps={profileForm.getFieldProps(
                                                `experiences.${i}.jobs.${j}.jobTitle`
                                              )}
                                              fieldMeta={profileForm.getFieldMeta(
                                                `experiences.${i}.jobs.${j}.jobTitle`
                                              )}
                                              fieldHelper={profileForm.getFieldHelpers(
                                                `experiences.${i}.jobs.${j}.jobTitle`
                                              )}
                                              edit={true}
                                              dataLoaded={true}
                                            />
                                            <motion.div
                                              className={`ml-[100px] mt-2 relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                              }}
                                              onClick={() =>
                                                deleteNestedList(
                                                  "jobsPositionList",
                                                  i,
                                                  j
                                                )
                                              }
                                            >
                                              <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                            </motion.div>
                                          </motion.div>
                                          <motion.div className="relative flex flex-1 flex-row justify-start gap-10">
                                            <motion.div
                                              className="text-md text-black mt-5"
                                              style={{ width: "75px" }}
                                            >
                                              Job Type :{" "}
                                            </motion.div>
                                            <motion.div className="w-[230px]">
                                              <FieldSelect
                                                fullWidth={true}
                                                bgColor={"bg-[#2593D2]/20"}
                                                injectClass={"w-[150%] ml-1"}
                                                fieldProps={profileForm.getFieldProps(
                                                  `experiences.${i}.jobs.${j}.jobType`
                                                )}
                                                fieldMeta={profileForm.getFieldMeta(
                                                  `experiences.${i}.jobs.${j}.jobType`
                                                )}
                                                fieldHelper={profileForm.getFieldHelpers(
                                                  `experiences.${i}.jobs.${j}.jobType`
                                                )}
                                                edit={true}
                                                dataLoaded={true}
                                                options={jobType}
                                              />
                                            </motion.div>
                                          </motion.div>
                                          <motion.div className="relative flex flex-1 flex-row justify-start gap-10 w-64">
                                            <motion.div className="mr-[65px]"></motion.div>
                                            <motion.div
                                              className="flex flex-row justify-start"
                                              style={{ maxWidth: "62%" }}
                                            >
                                              <FieldSelect
                                                arrowEnd={true}
                                                valuePadding={false}
                                                bgColor={"bg-[#ED1F24]/20"}
                                                injectClass={
                                                  "w-[70%] h-8 mr-2 ml-2"
                                                }
                                                fieldProps={profileForm.getFieldProps(
                                                  `experiences.${i}.jobs.${j}.fromMonth`
                                                )}
                                                fieldMeta={profileForm.getFieldMeta(
                                                  `experiences.${i}.jobs.${j}.fromMonth`
                                                )}
                                                fieldHelper={profileForm.getFieldHelpers(
                                                  `experiences.${i}.jobs.${j}.fromMonth`
                                                )}
                                                edit={true}
                                                dataLoaded={true}
                                                options={months}
                                              />
                                              <FieldSelect
                                                arrowEnd={true}
                                                valuePadding={false}
                                                bgColor={"bg-[#ED1F24]/20"}
                                                injectClass={
                                                  "w-[70%] h-8 mr-2 ml-2"
                                                }
                                                fieldProps={profileForm.getFieldProps(
                                                  `experiences.${i}.jobs.${j}.fromYear`
                                                )}
                                                fieldMeta={profileForm.getFieldMeta(
                                                  `experiences.${i}.jobs.${j}.fromYear`
                                                )}
                                                fieldHelper={profileForm.getFieldHelpers(
                                                  `experiences.${i}.jobs.${j}.fromYear`
                                                )}
                                                edit={true}
                                                dataLoaded={true}
                                                options={years}
                                              />
                                            </motion.div>
                                            <motion.div className="flex flex-row justify-start">
                                              <FieldSelect
                                                arrowEnd={true}
                                                disabled={e.isPresent}
                                                valuePadding={false}
                                                bgColor={"bg-[#ED1F24]/20"}
                                                injectClass={
                                                  "w-[70%] h-8 mr-2 ml-2"
                                                }
                                                fieldProps={profileForm.getFieldProps(
                                                  `experiences.${i}.jobs.${j}.toMonth`
                                                )}
                                                fieldMeta={profileForm.getFieldMeta(
                                                  `experiences.${i}.jobs.${j}.toMonth`
                                                )}
                                                fieldHelper={profileForm.getFieldHelpers(
                                                  `experiences.${i}.jobs.${j}.toMonth`
                                                )}
                                                edit={true}
                                                dataLoaded={true}
                                                options={months}
                                              />
                                              <FieldSelect
                                                arrowEnd={true}
                                                disabled={e.isPresent}
                                                valuePadding={false}
                                                bgColor={"bg-[#ED1F24]/20"}
                                                injectClass={
                                                  "w-[70%] h-8 mr-2 ml-2"
                                                }
                                                fieldProps={profileForm.getFieldProps(
                                                  `experiences.${i}.jobs.${j}.toYear`
                                                )}
                                                fieldMeta={profileForm.getFieldMeta(
                                                  `experiences.${i}.jobs.${j}.toYear`
                                                )}
                                                fieldHelper={profileForm.getFieldHelpers(
                                                  `experiences.${i}.jobs.${j}.toYear`
                                                )}
                                                edit={true}
                                                dataLoaded={true}
                                                options={years}
                                              />
                                            </motion.div>
                                            <motion.div
                                              className={`relative cursor-pointer p-2 mt-5 right-2 ${
                                                e.isPresent
                                                  ? "ring-1 ring-[#2593D2]"
                                                  : "ring-1 ring-[#707070]"
                                              } rounded-full w-[2px] h-[2px] flex flex-col`}
                                              onClick={() =>
                                                selectButton(
                                                  "jobs",
                                                  !e.isPresent,
                                                  i,
                                                  j
                                                )
                                              }
                                            >
                                              <>
                                                {e.isPresent ? (
                                                  <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                                                ) : (
                                                  <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                                                )}
                                              </>
                                            </motion.div>
                                          </motion.div>
                                        </motion.div>
                                      )}
                                    </>
                                  );
                                })}
                                <motion.div
                                  className="relative cursor-pointer bg-white w-max top-1 right-14 left-20 bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                                  onClick={() => addPositions(i)}
                                >
                                  <motion.div className="text-md">
                                    + &nbsp; add another position
                                  </motion.div>
                                </motion.div>
                              </motion.div>
                            </>
                          )}
                        </>
                      );
                    })}
                </motion.div>
                <motion.div
                  className="relative cursor-pointer bg-white w-max top-1 right-14 left-20  bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addExperience()}
                >
                  <motion.div className="text-md">
                    + add another company you've worked/working for
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* Education */}
              <motion.div className="relative">
                <motion.div
                  className={`relative text-black text-md font-bold mt-2 mb-2`}
                >
                  Education
                </motion.div>
                <motion.div className="relative grid grid-cols-1">
                  {profileForm &&
                    profileForm.values &&
                    profileForm.values.educations !== null &&
                    profileForm.values.educations.map((c, i) => {
                      return (
                        <>
                          {c.isActive && (
                            <motion.div className="relative">
                              <motion.div className="relative flex flex-row justify-start gap-10">
                                <motion.div
                                  className="text-md text-black mt-5"
                                  style={{ width: "74px" }}
                                >
                                  School :{" "}
                                </motion.div>
                                <FieldInput
                                  bgColor={"bg-[#2593D2]/20"}
                                  injectClass={"w-[152%]"}
                                  fieldProps={profileForm.getFieldProps(
                                    `educations.${i}.schoolName`
                                  )}
                                  fieldMeta={profileForm.getFieldMeta(
                                    `educations.${i}.schoolName`
                                  )}
                                  fieldHelper={profileForm.getFieldHelpers(
                                    `educations.${i}.schoolName`
                                  )}
                                  edit={true}
                                  dataLoaded={true}
                                />
                                <motion.div
                                  className={`ml-[100px] mt-2 relative bg-white drop-shadow-lg ring-1 ring-white ${
                                    c.schoolLogo && c.schoolLogo !== ""
                                      ? ""
                                      : "p-5"
                                  } rounded-lg`}
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() =>
                                    handleUploadClick("education", i)
                                  }
                                >
                                  {c.schoolLogo && c.schoolLogo !== "" ? (
                                    <img
                                      src={c.schoolLogo}
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  ) : (
                                    <BiUpload className="flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                  )}
                                </motion.div>
                              </motion.div>
                              <motion.div className="relative flex flex-1 flex-row justify-start gap-10">
                                <motion.div className="text-md text-black mt-5">
                                  Place :{" "}
                                </motion.div>
                                <motion.div className="w-[245px]">
                                  <FieldSelect
                                    fullWidth={true}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"w-[140%] ml-6"}
                                    fieldProps={profileForm.getFieldProps(
                                      `educations.${i}.schoolLocation`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `educations.${i}.schoolLocation`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `educations.${i}.schoolLocation`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={countries}
                                  />
                                </motion.div>
                                <motion.div
                                  className={`ml-[100px] mt-2 relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() => deleteLists("educations", i)}
                                >
                                  <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                </motion.div>
                              </motion.div>
                              <motion.div className="relative flex flex-1 flex-row justify-start">
                                <motion.div
                                  className="text-md text-black mt-5"
                                  style={{ width: "90px" }}
                                >
                                  Degree :{" "}
                                </motion.div>
                                <FieldInput
                                  bgColor={"bg-[#2593D2]/20"}
                                  injectClass={"w-[140%] ml-6"}
                                  fieldProps={profileForm.getFieldProps(
                                    `educations.${i}.degree`
                                  )}
                                  fieldMeta={profileForm.getFieldMeta(
                                    `educations.${i}.degree`
                                  )}
                                  fieldHelper={profileForm.getFieldHelpers(
                                    `educations.${i}.degree`
                                  )}
                                  edit={true}
                                  dataLoaded={true}
                                />
                              </motion.div>
                              <motion.div className="relative flex flex-1 flex-row justify-start gap-10 w-64">
                                <motion.div className="mr-[60px]"></motion.div>
                                <motion.div
                                  className="flex flex-row justify-start"
                                  style={{ maxWidth: "62%" }}
                                >
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"w-[70%] h-8 mr-2 ml-2"}
                                    fieldProps={profileForm.getFieldProps(
                                      `educations.${i}.fromMonth`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `educations.${i}.fromMonth`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `educations.${i}.fromMonth`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={months}
                                  />
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"w-[70%] h-8 mr-2 ml-2"}
                                    fieldProps={profileForm.getFieldProps(
                                      `educations.${i}.fromYear`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `educations.${i}.fromYear`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `educations.${i}.fromYear`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={years}
                                  />
                                </motion.div>
                                <motion.div className="flex flex-row justify-start">
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    disabled={c.isPresent}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"w-[70%] h-8 mr-2 ml-2"}
                                    fieldProps={profileForm.getFieldProps(
                                      `educations.${i}.toMonth`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `educations.${i}.toMonth`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `educations.${i}.toMonth`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={months}
                                  />
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    disabled={c.isPresent}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"w-[70%] h-8 mr-2 ml-2"}
                                    fieldProps={profileForm.getFieldProps(
                                      `educations.${i}.toYear`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `educations.${i}.toYear`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `educations.${i}.toYear`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={years}
                                  />
                                </motion.div>
                                <motion.div
                                  className={`relative p-2 mt-5 cursor-pointer right-2 ${
                                    c.isPresent
                                      ? "ring-1 ring-[#2593D2]"
                                      : "ring-1 ring-[#707070]"
                                  } rounded-full w-[2px] h-[2px] flex flex-col`}
                                  onClick={() =>
                                    selectButton(
                                      "education",
                                      !c.isPresent,
                                      i,
                                      ""
                                    )
                                  }
                                >
                                  <>
                                    {c.isPresent ? (
                                      <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                                    ) : (
                                      <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                                    )}
                                  </>
                                </motion.div>
                              </motion.div>
                            </motion.div>
                          )}
                        </>
                      );
                    })}
                </motion.div>
                <motion.div
                  className="relative cursor-pointer bg-white w-max top-1 right-14 left-20 bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addEducation()}
                >
                  <motion.div className="text-md">
                    + add another degree
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* License */}
              <motion.div className="relative">
                <motion.div
                  className={`relative text-black text-md font-bold mt-2 mb-2`}
                >
                  License and Certificate
                </motion.div>
                <motion.div className="relative grid grid-cols-1">
                  {profileForm &&
                    profileForm.values &&
                    profileForm.values.licenses !== null &&
                    profileForm.values.licenses.map((c, i) => {
                      return (
                        <>
                          {c.isActive && (
                            <motion.div className="relative">
                              <motion.div className="relative flex flex-row justify-start gap-10">
                                <motion.div
                                  className="text-md text-black mt-5"
                                  style={{ width: "78px" }}
                                >
                                  Institution :{" "}
                                </motion.div>
                                <FieldInput
                                  bgColor={"bg-[#2593D2]/20"}
                                  injectClass={"w-[152%]"}
                                  fieldProps={profileForm.getFieldProps(
                                    `licenses.${i}.institutionName`
                                  )}
                                  fieldMeta={profileForm.getFieldMeta(
                                    `licenses.${i}.institutionName`
                                  )}
                                  fieldHelper={profileForm.getFieldHelpers(
                                    `licenses.${i}.institutionName`
                                  )}
                                  edit={true}
                                  dataLoaded={true}
                                />
                                <motion.div
                                  className={`ml-[100px] mt-2 relative bg-white drop-shadow-lg ring-1 ring-white ${
                                    c.licenseLogo && c.licenseLogo !== ""
                                      ? ""
                                      : "p-5"
                                  } rounded-lg`}
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() =>
                                    handleUploadClick("license", i)
                                  }
                                >
                                  {c.licenseLogo && c.licenseLogo !== "" ? (
                                    <img
                                      src={c.licenseLogo}
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  ) : (
                                    <BiUpload className="flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                  )}
                                </motion.div>
                              </motion.div>
                              <motion.div className="relative flex flex-1 flex-row justify-start gap-10">
                                <motion.div
                                  className="text-md text-black mt-5"
                                  style={{ width: "53px" }}
                                >
                                  Title :{" "}
                                </motion.div>
                                <FieldInput
                                  bgColor={"bg-[#2593D2]/20"}
                                  injectClass={"w-[140%] ml-6"}
                                  fieldProps={profileForm.getFieldProps(
                                    `licenses.${i}.title`
                                  )}
                                  fieldMeta={profileForm.getFieldMeta(
                                    `licenses.${i}.title`
                                  )}
                                  fieldHelper={profileForm.getFieldHelpers(
                                    `licenses.${i}.title`
                                  )}
                                  edit={true}
                                  dataLoaded={true}
                                />
                                <motion.div
                                  className={`ml-[100px] mt-2 relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() => deleteLists("license", i)}
                                >
                                  <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                </motion.div>
                              </motion.div>
                              <motion.div className="relative flex flex-1 flex-row justify-start gap-10 w-64">
                                <motion.div className="mr-[65px]"></motion.div>
                                <motion.div
                                  className="flex flex-row justify-start"
                                  style={{ maxWidth: "62%" }}
                                >
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"w-[70%] h-8 mr-2 ml-2"}
                                    fieldProps={profileForm.getFieldProps(
                                      `licenses.${i}.fromMonth`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `licenses.${i}.fromMonth`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `licenses.${i}.fromMonth`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={months}
                                  />
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"w-[70%] h-8 mr-2 ml-2"}
                                    fieldProps={profileForm.getFieldProps(
                                      `licenses.${i}.fromYear`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `licenses.${i}.fromYear`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `licenses.${i}.fromYear`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={years}
                                  />
                                </motion.div>
                                <motion.div className="flex flex-row justify-start">
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    disabled={c.isExpired}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"w-[70%] h-8 mr-2 ml-2"}
                                    fieldProps={profileForm.getFieldProps(
                                      `licenses.${i}.toMonth`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `licenses.${i}.toMonth`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `licenses.${i}.toMonth`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={months}
                                  />
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    disabled={c.isExpired}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"w-[70%] h-8 mr-2 ml-2"}
                                    fieldProps={profileForm.getFieldProps(
                                      `licenses.${i}.toYear`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `licenses.${i}.toYear`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `licenses.${i}.toYear`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={years}
                                  />
                                </motion.div>
                                <motion.div
                                  className={`relative cursor-pointer p-2 mt-5 right-2 ${
                                    c.isExpired
                                      ? "ring-1 ring-[#2593D2]"
                                      : "ring-1 ring-[#707070]"
                                  }  rounded-full w-[2px] h-[2px] flex flex-col`}
                                  onClick={() =>
                                    selectButton(
                                      "licenses",
                                      !c.isExpired,
                                      i,
                                      ""
                                    )
                                  }
                                >
                                  <>
                                    {c.isExpired ? (
                                      <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                                    ) : (
                                      <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                                    )}
                                  </>
                                </motion.div>
                              </motion.div>
                            </motion.div>
                          )}
                        </>
                      );
                    })}
                </motion.div>
                <motion.div
                  className="relative cursor-pointer bg-white w-max top-1 right-14 left-20 bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addLicense()}
                >
                  <motion.div className="text-md">
                    + add another License / Certificate
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* Projects */}
              <motion.div className="relative">
                <motion.div
                  className={`relative text-black text-md font-bold mt-2 mb-2`}
                >
                  Projects
                </motion.div>
                {profileForm &&
                  profileForm.values &&
                  profileForm.values.projects !== null &&
                  profileForm.values.projects.map((c, i) => {
                    return (
                      <>
                        {c.isActive && (
                          <motion.div className="relative grid grid-cols-1">
                            <motion.div className="relative">
                              <motion.div className="flex flex-row flex-1 justify-between">
                                <motion.div className="w-full">
                                  <FieldInput
                                    label={"Title :"}
                                    labelLength="85"
                                    labelSide="side"
                                    bgColor={"bg-[#2593D2]/20"}
                                    injectClass={"w-[28%] mr-3 ml-3"}
                                    fieldProps={profileForm.getFieldProps(
                                      `projects.${i}.projectTitle`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `projects.${i}.projectTitle`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `projects.${i}.projectTitle`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                  />
                                </motion.div>
                                <motion.div
                                  className={`ml-[100px] mt-2 relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() => deleteLists("projects", i)}
                                >
                                  <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                </motion.div>
                              </motion.div>
                              <FieldInput
                                label={"Brief :"}
                                labelLength="83"
                                type="textarea"
                                labelSide="side"
                                row={"5"}
                                bgColor={"bg-[#2593D2]/20"}
                                injectClass={"w-[100%] mr-3 ml-3"}
                                fieldProps={profileForm.getFieldProps(
                                  `projects.${i}.projectDescription`
                                )}
                                fieldMeta={profileForm.getFieldMeta(
                                  `projects.${i}.projectDescription`
                                )}
                                fieldHelper={profileForm.getFieldHelpers(
                                  `projects.${i}.projectDescription`
                                )}
                                edit={true}
                                dataLoaded={true}
                              />
                            </motion.div>
                          </motion.div>
                        )}
                      </>
                    );
                  })}
                <motion.div
                  className="relative cursor-pointer bg-white w-max top-1 right-14 left-20 bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addProjects()}
                >
                  <motion.div className="text-md">
                    + add another Project
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* Language */}
              <motion.div className="relative">
                <motion.div
                  className={`relative text-black text-md font-bold mt-2 mb-2`}
                >
                  Languages Known :
                </motion.div>
                {profileForm &&
                  profileForm.values &&
                  profileForm.values.languages !== null &&
                  profileForm.values.languages.map((c, i) => {
                    return (
                      <>
                        {c.isActive && (
                          <motion.div className="relative grid grid-cols-2 gap-5">
                            <motion.div className="relative">
                              <FieldSelect
                                label={"Language "}
                                labelSide="side"
                                labelLength="86"
                                bgColor={"bg-[#ED1F24]/20"}
                                injectClass={"w-full mr-3 ml-3"}
                                fieldProps={profileForm.getFieldProps(
                                  `languages.${i}.language`
                                )}
                                fieldMeta={profileForm.getFieldMeta(
                                  `languages.${i}.language`
                                )}
                                fieldHelper={profileForm.getFieldHelpers(
                                  `languages.${i}.language`
                                )}
                                edit={true}
                                dataLoaded={true}
                                options={languages}
                              />
                              <FieldSelect
                                label={"Level "}
                                labelSide="side"
                                labelLength="85"
                                bgColor={"bg-[#ED1F24]/20"}
                                injectClass={"w-full mr-3 ml-3"}
                                fieldProps={profileForm.getFieldProps(
                                  `languages.${i}.languageLevel`
                                )}
                                fieldMeta={profileForm.getFieldMeta(
                                  `languages.${i}.languageLevel`
                                )}
                                fieldHelper={profileForm.getFieldHelpers(
                                  `languages.${i}.languageLevel`
                                )}
                                edit={true}
                                dataLoaded={true}
                                options={langLevel}
                              />
                            </motion.div>
                            <motion.div
                              className={`mt-2 relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                              style={{ width: "50px", height: "50px" }}
                              onClick={() => deleteLists("lang", i)}
                            >
                              <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                            </motion.div>
                          </motion.div>
                        )}
                      </>
                    );
                  })}
                <motion.div
                  className="relative cursor-pointer bg-white w-max top-1 right-14 left-20 bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addLanguages()}
                >
                  <motion.div className="text-md">
                    + add another Language
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* Skills */}
              <motion.div className="relative mb-10">
                <motion.div
                  className={`relative text-black text-md font-bold mt-2 mb-2`}
                >
                  Skills :
                </motion.div>
                <motion.div className="relative grid grid-cols-1">
                  {profileForm &&
                    profileForm.values &&
                    profileForm.values.skills !== null &&
                    profileForm.values.skills.map((c, i) => {
                      return (
                        <>
                          {c.isActive && (
                            <motion.div className="relative flex flex-1 flex-row justify-start gap-10">
                              <motion.div
                                className="text-md text-black mt-5"
                                style={{ width: "53px" }}
                              ></motion.div>
                              <FieldInput
                                bgColor={"bg-[#ED1F24]/20"}
                                injectClass={"w-[140%] ml-6"}
                                fieldProps={profileForm.getFieldProps(
                                  `skills.${i}.skillName`
                                )}
                                fieldMeta={profileForm.getFieldMeta(
                                  `skills.${i}.skillName`
                                )}
                                fieldHelper={profileForm.getFieldHelpers(
                                  `skills.${i}.skillName`
                                )}
                                edit={true}
                                dataLoaded={true}
                              />
                              <motion.div
                                className={`ml-[100px] mt-2 relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                style={{ width: "50px", height: "50px" }}
                                onClick={() => deleteLists("skills", i)}
                              >
                                <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                              </motion.div>
                            </motion.div>
                          )}
                        </>
                      );
                    })}
                </motion.div>
                <motion.div
                  className="relative cursor-pointer bg-white w-max top-1 right-14 left-20 bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addSkills()}
                >
                  <motion.div className="text-md">
                    + add another Skill
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>

          {/* Mobile */}
          <motion.div className={`relative min-h-screen block md:hidden px-2`}>
            <motion.div
              className={`fixed z-10 right-1 bg-white drop-shadow p-2 flex flex-row gap-5`}
            >
              <motion.div
                className={`relative bg-sky-200 py-2 px-1 text-white text-md font-bold rounded-lg`}
                onClick={() => profileForm.submitForm()}
              >
                Save
              </motion.div>
              <motion.div
                className={`relative bg-[#FACACB] py-2 px-1 text-white text-md font-bold rounded-lg`}
                onClick={() => history.push("/profile")}
              >
                Cancel
              </motion.div>
            </motion.div>
            <motion.div className={"realtive grid grid-cols-1 gap-5 px-5"}>
              {/* Basic Info */}
              <motion.div className={"realtive mb-10"}>
                <motion.div
                  className={`relative text-black text-md font-bold mt-2 mb-2`}
                >
                  Basic Info
                </motion.div>
                <motion.div
                  className={"relative grid grid-cols-1 gap-10 mt-10"}
                >
                  <motion.div className="relative flex flex-row items-center justify-center text-center">
                    <img
                      src={
                        profileForm &&
                        profileForm.values &&
                        profileForm.values.profileImage &&
                        profileForm.values.profileImage !== null
                          ? profileForm.values.profileImage
                          : profilePic
                      }
                      alt=""
                      height={150}
                      width={150}
                    />
                    <motion.div
                      className={`absolute top-0 cursor-pointer right-24 rounded-full backdrop-blur-sm bg-white/30`}
                      style={{ height: "40px", width: "40px" }}
                      onClick={() => handleUploadClick("profile", 0)}
                    >
                      <FaPen className="text-white m-3" />
                    </motion.div>
                  </motion.div>
                  <motion.div className="relative flex flex-row justify-center">
                    <motion.div
                      className={`absolute flex flex-row ring-1 ring-white drop-shadow-lg`}
                    >
                      <motion.div
                        class="absolute  overflow-hidden inline-block"
                        style={{ width: "190px" }}
                      >
                        <motion.div
                          class=" bg-white rotate-45 transform origin-top-right"
                          style={{ height: "330px" }}
                        ></motion.div>
                      </motion.div>
                      <img
                        src={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.coverImage &&
                          profileForm.values.coverImage !== null
                            ? profileForm.values.coverImage
                            : coverPng
                        }
                        style={{ height: "200px", width: "300px" }}
                        alt=""
                      />
                      <motion.div
                        class="absolute bottom-0 right-0 overflow-hidden inline-block"
                        style={{ width: "190px" }}
                      >
                        <motion.div
                          class="bg-white rotate-45 transform origin-bottom-left"
                          style={{ height: "330px" }}
                        ></motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div
                      className={`absolute top-36 cursor-pointer left-60  rounded-full backdrop-blur-sm bg-[#2593D2]/30`}
                      style={{ height: "40px", width: "40px" }}
                      onClick={() => handleUploadClick("cover", 0)}
                    >
                      <FaPen className="text-white m-3" />
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="relative"
                    style={{ marginTop: "200px" }}
                  >
                    <FieldInput
                      labelLength="57"
                      label={"Name "}
                      labelSide="side"
                      bgColor={"bg-[#2593D2]/20"}
                      injectClass={"w-[100%]"}
                      fieldProps={profileForm.getFieldProps("profileName")}
                      fieldMeta={profileForm.getFieldMeta("profileName")}
                      fieldHelper={profileForm.getFieldHelpers("profileName")}
                      edit={true}
                      dataLoaded={true}
                    />
                    <FieldInput
                      label={"Account "}
                      labelSide="side"
                      injectClass={"w-[100%]"}
                      bgColor={"bg-[#2593D2]/20"}
                      fieldProps={profileForm.getFieldProps("userName")}
                      fieldMeta={profileForm.getFieldMeta("userName")}
                      fieldHelper={profileForm.getFieldHelpers("userName")}
                      edit={true}
                      dataLoaded={true}
                    />
                    <FieldInput
                      labelLength="57"
                      label={`I'm a/an`}
                      labelSide="side"
                      injectClass={"w-[90%]"}
                      bgColor={"bg-[#ED1F24]/20"}
                      fieldProps={profileForm.getFieldProps("designation")}
                      fieldMeta={profileForm.getFieldMeta("designation")}
                      fieldHelper={profileForm.getFieldHelpers("designation")}
                      edit={true}
                      dataLoaded={true}
                    />
                    <motion.div className="flex flex-row justify-start gap-10">
                      <lable>I'm in</lable>
                      <motion.div
                        className={`${
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.accountTypeId === "1" &&
                          "bg-[#2593D2]/20"
                        } px-1 py-1 rounded-xl drop-shadow-lg`}
                      >
                        <motion.div className="bg-white drop-shadow-lg  px-2 py-5 rounded-lg">
                          <img src={agri} alt="" />
                        </motion.div>
                        <motion.div
                          className={`${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.accountTypeId === "1"
                              ? "text-white"
                              : "text-[grey]"
                          } text-xl font-bold text-center p-1`}
                        >
                          Agriculture
                        </motion.div>
                      </motion.div>
                      <motion.div
                        className={`${
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.accountTypeId === "2" &&
                          "bg-[#2593D2]/20"
                        } px-1 py-1 rounded-xl drop-shadow-lg`}
                      >
                        <motion.div className="bg-white drop-shadow-lg px-2 py-3 rounded-lg">
                          <img src={aqua} alt="" />
                        </motion.div>
                        <motion.div
                          className={`${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.accountTypeId === "2"
                              ? "text-white"
                              : "text-[grey]"
                          } text-xl font-bold text-center p-1`}
                        >
                          Aquaculture
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="flex flex-row justify-start">
                      <motion.div className="w-full">
                        <FieldInput
                          labelLength="56"
                          label={"Phone "}
                          labelSide="side"
                          bgColor={"bg-[#2593D2]/20"}
                          injectClass={"w-[100%]"}
                          fieldProps={profileForm.getFieldProps("phone")}
                          fieldMeta={profileForm.getFieldMeta("phone")}
                          fieldHelper={profileForm.getFieldHelpers("phone")}
                          edit={true}
                          dataLoaded={true}
                        />
                      </motion.div>
                      <motion.div className="flex flex-row justify-start gap-2">
                        <motion.div
                          className={`relative cursor-pointer p-2 mt-5 ml-1 right-2 ${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.isPhoneEnable
                              ? "ring-1 ring-[#2593D2]"
                              : "ring-1 ring-[#707070]"
                          } rounded-full w-[1px] h-[1px] flex flex-col`}
                          onClick={() =>
                            selectButton(
                              "phone",
                              !profileForm.values.isPhoneEnable,
                              "",
                              ""
                            )
                          }
                        >
                          <>
                            {profileForm &&
                            profileForm.values &&
                            profileForm.values.isPhoneEnable ? (
                              <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                            ) : (
                              <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                            )}
                          </>
                        </motion.div>
                        <motion.div
                          className={`text-black text-sm flex flex-col text-center justify-center mb-1`}
                        >
                          Show Call
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className="flex flex-row justify-start">
                      <motion.div className="w-full">
                        <FieldInput
                          labelLength="56"
                          label={"Email "}
                          labelSide="side"
                          bgColor={"bg-[#2593D2]/20"}
                          injectClass={"w-[100%]"}
                          fieldProps={profileForm.getFieldProps("email")}
                          fieldMeta={profileForm.getFieldMeta("email")}
                          fieldHelper={profileForm.getFieldHelpers("email")}
                          edit={true}
                          dataLoaded={true}
                        />
                      </motion.div>
                      <motion.div className="flex flex-row justify-start gap-2">
                        <motion.div
                          className={`relative cursor-pointer p-2 mt-5 ml-1 right-2 ${
                            profileForm &&
                            profileForm.values &&
                            profileForm.values.isEmailEnable
                              ? "ring-1 ring-[#2593D2]"
                              : "ring-1 ring-[#707070]"
                          } rounded-full w-[1px] h-[1px] flex flex-col`}
                          onClick={() =>
                            selectButton(
                              "email",
                              !profileForm.values.isEmailEnable,
                              "",
                              ""
                            )
                          }
                        >
                          <>
                            {profileForm &&
                            profileForm.values &&
                            profileForm.values.isEmailEnable ? (
                              <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                            ) : (
                              <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                            )}
                          </>
                        </motion.div>
                        <motion.div
                          className={`text-black text-sm flex flex-col text-center justify-center mb-1`}
                        >
                          Show Email
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* About */}
              <motion.div className="relative mb-10">
                <motion.div className="relative text-black text-md font-bold mt-2 mb-2">
                  About
                </motion.div>
                <motion.div className="relative">
                  <FieldInput
                    type={"textarea"}
                    row={"12"}
                    bgColor={"bg-[#2593D2]/20"}
                    injectClass={"w-[100%]"}
                    fieldProps={profileForm.getFieldProps("about")}
                    fieldMeta={profileForm.getFieldMeta("about")}
                    fieldHelper={profileForm.getFieldHelpers("about")}
                    edit={true}
                    dataLoaded={true}
                  />
                </motion.div>
              </motion.div>
              <hr />
              {/* Professional Summary */}
              <motion.div clasName="h-full relative mb-10">
                <motion.div className="relative text-black text-md font-bold mt-2 mb-2">
                  Professional Summary
                </motion.div>
                <motion.div className="grid grid-cols-1">
                  <FieldInput
                    labelLength="46"
                    radioOption={true}
                    label={"Web"}
                    labelSide="side"
                    bgColor={"bg-[#2593D2]/20"}
                    injectClass={"w-[100%]"}
                    fieldProps={profileForm.getFieldProps("websiteUrl")}
                    fieldMeta={profileForm.getFieldMeta("websiteUrl")}
                    fieldHelper={profileForm.getFieldHelpers("websiteUrl")}
                    edit={true}
                    dataLoaded={true}
                  />
                  <FieldInput
                    labelLength="46"
                    radioOption={true}
                    label={"Email"}
                    labelSide="side"
                    bgColor={"bg-[#2593D2]/20"}
                    injectClass={"w-[100%]"}
                    fieldProps={profileForm.getFieldProps("email")}
                    fieldMeta={profileForm.getFieldMeta("email")}
                    fieldHelper={profileForm.getFieldHelpers("email")}
                    edit={true}
                    dataLoaded={true}
                  />
                  <FieldInput
                    label={"Phone"}
                    radioOption={true}
                    labelSide="side"
                    bgColor={"bg-[#2593D2]/20"}
                    injectClass={"w-[100%]"}
                    fieldProps={profileForm.getFieldProps("phone")}
                    fieldMeta={profileForm.getFieldMeta("phone")}
                    fieldHelper={profileForm.getFieldHelpers("phone")}
                    edit={true}
                    dataLoaded={true}
                  />
                </motion.div>
                <motion.div className="grid grid-cols-1">
                  <motion.div className="relative top-8">
                    <motion.div className="flex top-10 left-12 text-black text-xl">
                      Address:
                      <motion.div className="relative p-2 mt-2 mb-2 ml-3 ring-1 ring-black rounded-full w-[2px] h-[2px] flex flex-col">
                        <motion.div className="absolute ring ring-1 top-1 right-1  ring-black rounded-full bg-black p-1 w-[1px] h-[1px]"></motion.div>
                      </motion.div>
                    </motion.div>
                    <FieldInput
                      labelLength="56"
                      radioOption={true}
                      label={"Line 1"}
                      labelSide="side"
                      bgColor={"bg-[#2593D2]/20"}
                      injectClass={"w-[90%]"}
                      fieldProps={profileForm.getFieldProps(
                        "professionals.addressLine1"
                      )}
                      fieldMeta={profileForm.getFieldMeta(
                        "professionals.addressLine1"
                      )}
                      fieldHelper={profileForm.getFieldHelpers(
                        "professionals.addressLine1"
                      )}
                      edit={true}
                      dataLoaded={true}
                    />
                    <FieldInput
                      labelLength="56"
                      radioOption={true}
                      label={"Pincode"}
                      labelSide="side"
                      bgColor={"bg-[#2593D2]/20"}
                      injectClass={"w-[100%]"}
                      fieldProps={profileForm.getFieldProps(
                        "professionals.pinCode"
                      )}
                      fieldMeta={profileForm.getFieldMeta(
                        "professionals.pinCode"
                      )}
                      fieldHelper={profileForm.getFieldHelpers(
                        "professionals.pinCode"
                      )}
                      edit={true}
                      dataLoaded={true}
                    />
                    <FieldSelect
                      label={"Country"}
                      labelSide="side"
                      radioOption={true}
                      bgColor={"bg-[#ED1F24]/20"}
                      injectClass={"w-[79%]"}
                      fieldProps={profileForm.getFieldProps(
                        "professionals.country"
                      )}
                      fieldMeta={profileForm.getFieldMeta(
                        "professionals.country"
                      )}
                      fieldHelper={profileForm.getFieldHelpers(
                        "professionals.country"
                      )}
                      edit={true}
                      dataLoaded={true}
                      options={countries}
                    />
                  </motion.div>
                </motion.div>
                <motion.div className="grid grid-cols-1 mt-10 mb-10">
                  <motion.div className="flex text-black text-xl">
                    Timings:
                    <motion.div className="relative p-2 mt-2 mb-2 ml-3 ring-1 ring-black rounded-full w-[2px] h-[2px] flex flex-col">
                      <motion.div className="absolute ring ring-1 top-1 right-1  ring-black rounded-full bg-black p-1 w-[1px] h-[1px]"></motion.div>
                    </motion.div>
                  </motion.div>
                  {/* <motion.div className="flex flex-row justify-around gap-2 text-start">
                                    <motion.div></motion.div>
                                    <motion.div>Opening</motion.div>
                                    <motion.div>Closing</motion.div>
                                    <motion.div>Holiday</motion.div>
                                </motion.div> */}
                  <motion.div className="flex flex-row justify-around gap-2">
                    <motion.div className="mt-4 text-start">Mon</motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[0] &&
                          profileForm.values.professionals.timings[0]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[0].isHoliday
                        }
                        injectClass={"h-8 w-full"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[0].openingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[0].openingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[0].openingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[0] &&
                          profileForm.values.professionals.timings[0]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[0].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[0].openZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[0].openZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[0].openZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[0] &&
                          profileForm.values.professionals.timings[0]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[0].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[0].closingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[0].closingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[0].closingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[0] &&
                          profileForm.values.professionals.timings[0]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[0].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[0].closeZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[0].closeZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[0].closeZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="flex justify-center items-center">
                      <motion.div
                        className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[0] &&
                          profileForm.values.professionals.timings[0]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[0].isHoliday
                            ? "ring-1 ring-[#2593D2]"
                            : "ring-1 ring-[#707070]"
                        } rounded-full w-[2px] h-[2px] flex flex-col`}
                        onClick={() =>
                          selectButton(
                            "timings",
                            !profileForm.values.professionals.timings[0]
                              .isHoliday,
                            0,
                            ""
                          )
                        }
                      >
                        <>
                          {profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[0] &&
                          profileForm.values.professionals.timings[0]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[0]
                            .isHoliday ? (
                            <motion.div className="absolute top-1 right-1 ring ring-5 ring-white  rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                          ) : (
                            <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                          )}
                        </>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className="flex flex-row justify-around gap-2">
                    <motion.div className="mt-4 text-start">Tue</motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[1] &&
                          profileForm.values.professionals.timings[1]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[1].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[1].openingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[1].openingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[1].openingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[1] &&
                          profileForm.values.professionals.timings[1]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[1].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[1].openZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[1].openZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[1].openZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[1] &&
                          profileForm.values.professionals.timings[1]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[1].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[1].closingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[1].closingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[1].closingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[1] &&
                          profileForm.values.professionals.timings[1]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[1].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[1].closeZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[1].closeZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[1].closeZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="flex justify-center items-center">
                      <motion.div
                        className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[1] &&
                          profileForm.values.professionals.timings[1]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[1].isHoliday
                            ? "ring-1 ring-[#2593D2]"
                            : "ring-1 ring-[#707070]"
                        } rounded-full w-[2px] h-[2px] flex flex-col`}
                        onClick={() =>
                          selectButton(
                            "timings",
                            !profileForm.values.professionals.timings[1]
                              .isHoliday,
                            1,
                            ""
                          )
                        }
                      >
                        <>
                          {profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[1] &&
                          profileForm.values.professionals.timings[1]
                            .isHoliday !== undefined &&
                          profileForm.values.professionals.timings[1]
                            .isHoliday ? (
                            <motion.div className="absolute top-1 right-1 ring ring-5 ring-white  rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                          ) : (
                            <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                          )}
                        </>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className="flex flex-row justify-around gap-2">
                    <motion.div className="mt-4 text-start">Wed</motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[2] &&
                          profileForm.values.professionals.timings[2].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[2].openingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[2].openingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[2].openingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[2] &&
                          profileForm.values.professionals.timings[2].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[2].openZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[2].openZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[2].openZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[2] &&
                          profileForm.values.professionals.timings[2].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[2].closingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[2].closingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[2].closingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[2] &&
                          profileForm.values.professionals.timings[2].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[2].closeZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[2].closeZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[2].closeZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="flex justify-center items-center">
                      <motion.div
                        className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[2] &&
                          profileForm.values.professionals.timings[2].isHoliday
                            ? "ring-1 ring-[#2593D2]"
                            : "ring-1 ring-[#707070]"
                        } rounded-full w-[2px] h-[2px] flex flex-col`}
                        onClick={() =>
                          selectButton(
                            "timings",
                            !profileForm.values.professionals.timings[2]
                              .isHoliday,
                            2,
                            ""
                          )
                        }
                      >
                        <>
                          {profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[2] &&
                          profileForm.values.professionals.timings[2]
                            .isHoliday ? (
                            <motion.div className="absolute top-1 right-1 ring ring-5 ring-white  rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                          ) : (
                            <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                          )}
                        </>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className="flex flex-row justify-around gap-2">
                    <motion.div className="mt-4 text-start">Thu</motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[3] &&
                          profileForm.values.professionals.timings[3].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[3].openingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[3].openingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[3].openingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[3] &&
                          profileForm.values.professionals.timings[3].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[3].openZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[3].openZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[3].openZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[3] &&
                          profileForm.values.professionals.timings[3].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[3].closingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[3].closingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[3].closingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[3] &&
                          profileForm.values.professionals.timings[3].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[3].closeZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[3].closeZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[3].closeZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="flex justify-center items-center">
                      <motion.div
                        className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[3] &&
                          profileForm.values.professionals.timings[3].isHoliday
                            ? "ring-1 ring-[#2593D2]"
                            : "ring-1 ring-[#707070]"
                        } rounded-full w-[2px] h-[2px] flex flex-col`}
                        onClick={() =>
                          selectButton(
                            "timings",
                            !profileForm.values.professionals.timings[3]
                              .isHoliday,
                            3,
                            ""
                          )
                        }
                      >
                        <>
                          {profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[3] &&
                          profileForm.values.professionals.timings[3]
                            .isHoliday ? (
                            <motion.div className="absolute top-1 right-1 ring ring-5 ring-white  rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                          ) : (
                            <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                          )}
                        </>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className="flex flex-row justify-around gap-2">
                    <motion.div className="mt-4 text-start">Fri</motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[4] &&
                          profileForm.values.professionals.timings[4].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[4].openingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[4].openingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[4].openingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[4] &&
                          profileForm.values.professionals.timings[4].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[4].openZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[4].openZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[4].openZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[4] &&
                          profileForm.values.professionals.timings[4].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[4].closingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[4].closingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[4].closingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[4] &&
                          profileForm.values.professionals.timings[4].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[4].closeZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[4].closeZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[4].closeZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="flex justify-center items-center">
                      <motion.div
                        className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[4] &&
                          profileForm.values.professionals.timings[4].isHoliday
                            ? "ring-1 ring-[#2593D2]"
                            : "ring-1 ring-[#707070]"
                        } rounded-full w-[2px] h-[2px] flex flex-col`}
                        onClick={() =>
                          selectButton(
                            "timings",
                            !profileForm.values.professionals.timings[4]
                              .isHoliday,
                            4,
                            ""
                          )
                        }
                      >
                        <>
                          {profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[4] &&
                          profileForm.values.professionals.timings[4]
                            .isHoliday ? (
                            <motion.div className="absolute top-1 right-1 ring ring-5 ring-white  rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                          ) : (
                            <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                          )}
                        </>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className="flex flex-row justify-around gap-2">
                    <motion.div className="mt-4 text-start">Sat</motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[5] &&
                          profileForm.values.professionals.timings[5].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[5].openingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[5].openingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[5].openingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[5] &&
                          profileForm.values.professionals.timings[5].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[5].openZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[5].openZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[5].openZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[5] &&
                          profileForm.values.professionals.timings[5].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[5].closingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[5].closingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[5].closingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[5] &&
                          profileForm.values.professionals.timings[5].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[5].closeZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[5].closeZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[5].closeZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="flex justify-center items-center">
                      <motion.div
                        className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[5] &&
                          profileForm.values.professionals.timings[5].isHoliday
                            ? "ring-1 ring-[#2593D2]"
                            : "ring-1 ring-[#707070]"
                        } rounded-full w-[2px] h-[2px] flex flex-col`}
                        onClick={() =>
                          selectButton(
                            "timings",
                            !profileForm.values.professionals.timings[5]
                              .isHoliday,
                            5,
                            ""
                          )
                        }
                      >
                        <>
                          {profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[5] &&
                          profileForm.values.professionals.timings[5]
                            .isHoliday ? (
                            <motion.div className="absolute top-1 right-1 ring ring-5 ring-white  rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                          ) : (
                            <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                          )}
                        </>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className="flex flex-row justify-around gap-2">
                    <motion.div className="mt-4 text-start">Sun</motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[6] &&
                          profileForm.values.professionals.timings[6].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[6].openingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[6].openingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[6].openingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[6] &&
                          profileForm.values.professionals.timings[6].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[6].openZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[6].openZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[6].openZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="grid grid-cols-2 gap-2">
                      <FieldInput
                        miniBox={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[6] &&
                          profileForm.values.professionals.timings[6].isHoliday
                        }
                        injectClass={"h-8"}
                        bgColor={"bg-[#2593D2]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[6].closingTime"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[6].closingTime"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[6].closingTime"
                        )}
                        edit={true}
                        dataLoaded={true}
                      />
                      <FieldSelect
                        arrowEnd={true}
                        disabled={
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[6] &&
                          profileForm.values.professionals.timings[6].isHoliday
                        }
                        valuePadding={false}
                        injectClass={"h-8"}
                        bgColor={"bg-[#ED1F24]/20"}
                        fieldProps={profileForm.getFieldProps(
                          "professionals.timings[6].closeZone"
                        )}
                        fieldMeta={profileForm.getFieldMeta(
                          "professionals.timings[6].closeZone"
                        )}
                        fieldHelper={profileForm.getFieldHelpers(
                          "professionals.timings[6].closeZone"
                        )}
                        edit={true}
                        dataLoaded={true}
                        options={timeZone}
                      />
                    </motion.div>
                    <motion.div className="flex justify-center items-center">
                      <motion.div
                        className={`relative cursor-pointer p-2 mt-2 mb-2 ${
                          profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[6] &&
                          profileForm.values.professionals.timings[6].isHoliday
                            ? "ring-1 ring-[#2593D2]"
                            : "ring-1 ring-[#707070]"
                        } rounded-full w-[2px] h-[2px] flex flex-col`}
                        onClick={() =>
                          selectButton(
                            "timings",
                            !profileForm.values.professionals.timings[6]
                              .isHoliday,
                            6,
                            ""
                          )
                        }
                      >
                        <>
                          {profileForm &&
                          profileForm.values &&
                          profileForm.values.professionals !== null &&
                          profileForm.values.professionals.timings &&
                          profileForm.values.professionals.timings[6] &&
                          profileForm.values.professionals.timings[6]
                            .isHoliday ? (
                            <motion.div className="absolute top-1 right-1 ring ring-5 ring-white  rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                          ) : (
                            <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                          )}
                        </>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div className="grid grid-cols-1 mb-10">
                  <motion.div className="flex text-black text-xl">
                    Location:
                    <motion.div className="relative p-2 mt-2 mb-2 ml-3 ring-1 ring-black rounded-full w-[2px] h-[2px] flex flex-col">
                      <motion.div className="absolute ring ring-1 top-1 right-1  ring-black rounded-full bg-black p-1 w-[1px] h-[1px]"></motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className="row">
                    <motion.div className="relative flex flex-col">
                      <motion.div className="flex flex-row justify-start gap-2">
                        <input
                          className="m-1"
                          type="checkbox"
                          onChange={(ev) => getCurrentLocation(ev.target)}
                        />
                        <span>User My Current Location</span>
                      </motion.div>
                      <motion.div className="flex flex-row justify-start gap-2">
                        <input className="m-1" type="checkbox" />
                        <span>Locate my address</span>
                      </motion.div>
                    </motion.div>
                    <motion.div className="w-100 relative">
                      {/* <img className="rounded-lg" src={dummyMap} alt="" /> */}
                      <div>
                        <MapContainer
                          latitude={parseFloat(
                            profileForm &&
                              profileForm.values &&
                              profileForm.values.professionals !== null &&
                              profileForm.values.professionals.timings &&
                              profileForm.values.professionals.latitude
                          )}
                          longitude={parseFloat(
                            profileForm &&
                              profileForm.values &&
                              profileForm.values.professionals !== null &&
                              profileForm.values.professionals.timings &&
                              profileForm.values.professionals.longitude
                          )}
                        />
                      </div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* Experience */}
              <motion.div
                clasName="h-full relative mb-10"
                style={{ marginTop: "60%" }}
              >
                <motion.div className="relative text-black text-md font-bold mt-2 mb-2">
                  Experience
                </motion.div>
                <motion.div className="relative grid grid-cols-1">
                  {profileForm &&
                    profileForm.values &&
                    profileForm.values.experiences !== null &&
                    profileForm.values.experiences.map((c, i) => {
                      return (
                        <>
                          {c.isActive && (
                            <>
                              <motion.div className="relative">
                                <motion.div className="relative flex flex-row justify-start">
                                  <FieldInput
                                    labelLength="78"
                                    label={"Company :"}
                                    labelSide="side"
                                    bgColor={"bg-[#2593D2]/20"}
                                    injectClass={"w-[90%]"}
                                    fieldProps={profileForm.getFieldProps(
                                      `experiences.${i}.companyName`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `experiences.${i}.companyName`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `experiences.${i}.companyName`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                  />
                                  <motion.div
                                    className={`mt-2 relative bg-white drop-shadow-lg ring-1 ring-white ${
                                      c.companyLogo && c.companyLogo !== ""
                                        ? ""
                                        : "p-5"
                                    } rounded-lg`}
                                    style={{ width: "50px", height: "50px" }}
                                    onClick={() =>
                                      handleUploadClick("experience", i)
                                    }
                                  >
                                    {c.companyLogo && c.companyLogo !== "" ? (
                                      <img
                                        src={c.companyLogo}
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    ) : (
                                      <BiUpload className="flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                    )}
                                  </motion.div>
                                </motion.div>
                                <motion.div className="relative flex flex-1 flex-row justify-start">
                                  <motion.div className="w-full">
                                    <FieldSelect
                                      labelLength="74"
                                      label={"Place :"}
                                      labelSide="side"
                                      bgColor={"bg-[#ED1F24]/20"}
                                      injectClass={"w-[90%]"}
                                      fieldProps={profileForm.getFieldProps(
                                        `experiences.${i}.companyLocation`
                                      )}
                                      fieldMeta={profileForm.getFieldMeta(
                                        `experiences.${i}.companyLocation`
                                      )}
                                      fieldHelper={profileForm.getFieldHelpers(
                                        `experiences.${i}.companyLocation`
                                      )}
                                      edit={true}
                                      dataLoaded={true}
                                      options={countries}
                                    />
                                  </motion.div>
                                  <motion.div
                                    className={`cursor-pointer mt-2 relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                    style={{ width: "50px", height: "50px" }}
                                    onClick={() =>
                                      deleteLists("experiences", i)
                                    }
                                  >
                                    <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                  </motion.div>
                                </motion.div>
                              </motion.div>
                              <motion.div className="relative flex flex-col mb-10">
                                {c.jobs.map((e, j) => {
                                  return (
                                    <>
                                      {e.isActive && (
                                        <motion.div className="relative">
                                          <motion.div className="relative flex flex-1 flex-row justify-start">
                                            <motion.div className="w-full">
                                              <FieldInput
                                                labelLength="74"
                                                label={"Job Title :"}
                                                labelSide="side"
                                                bgColor={"bg-[#2593D2]/20"}
                                                injectClass={"w-[100%]"}
                                                fieldProps={profileForm.getFieldProps(
                                                  `experiences.${i}.jobs.${j}.jobTitle`
                                                )}
                                                fieldMeta={profileForm.getFieldMeta(
                                                  `experiences.${i}.jobs.${j}.jobTitle`
                                                )}
                                                fieldHelper={profileForm.getFieldHelpers(
                                                  `experiences.${i}.jobs.${j}.jobTitle`
                                                )}
                                                edit={true}
                                                dataLoaded={true}
                                              />
                                            </motion.div>
                                            <motion.div
                                              className={`cursor-pointer mt-2 relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                              }}
                                              onClick={() =>
                                                deleteNestedList(
                                                  "jobsPositionList",
                                                  i,
                                                  j
                                                )
                                              }
                                            >
                                              <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                            </motion.div>
                                          </motion.div>
                                          <motion.div className="relative flex flex-1 flex-row justify-start">
                                            <FieldSelect
                                              labelLength="74"
                                              label={"Job Type :"}
                                              labelSide="side"
                                              bgColor={"bg-[#ED1F24]/20"}
                                              injectClass={"w-[90%]"}
                                              fieldProps={profileForm.getFieldProps(
                                                `experiences.${i}.jobs.${j}.jobType`
                                              )}
                                              fieldMeta={profileForm.getFieldMeta(
                                                `experiences.${i}.jobs.${j}.jobType`
                                              )}
                                              fieldHelper={profileForm.getFieldHelpers(
                                                `experiences.${i}.jobs.${j}.jobType`
                                              )}
                                              edit={true}
                                              dataLoaded={true}
                                              options={jobType}
                                            />
                                          </motion.div>
                                          <motion.div className="relative flex flex-1 flex-row justify-start">
                                            <motion.div
                                              className="grid grid-cols-2 gap-2"
                                              style={{ marginRight: "12%" }}
                                            >
                                              <FieldSelect
                                                arrowEnd={true}
                                                valuePadding={false}
                                                bgColor={"bg-[#ED1F24]/20"}
                                                injectClass={"h-8"}
                                                fieldProps={profileForm.getFieldProps(
                                                  `experiences.${i}.jobs.${j}.fromMonth`
                                                )}
                                                fieldMeta={profileForm.getFieldMeta(
                                                  `experiences.${i}.jobs.${j}.fromMonth`
                                                )}
                                                fieldHelper={profileForm.getFieldHelpers(
                                                  `experiences.${i}.jobs.${j}.fromMonth`
                                                )}
                                                edit={true}
                                                dataLoaded={true}
                                                options={months}
                                              />
                                              <FieldSelect
                                                arrowEnd={true}
                                                valuePadding={false}
                                                bgColor={"bg-[#ED1F24]/20"}
                                                injectClass={"h-8"}
                                                fieldProps={profileForm.getFieldProps(
                                                  `experiences.${i}.jobs.${j}.fromYear`
                                                )}
                                                fieldMeta={profileForm.getFieldMeta(
                                                  `experiences.${i}.jobs.${j}.fromYear`
                                                )}
                                                fieldHelper={profileForm.getFieldHelpers(
                                                  `experiences.${i}.jobs.${j}.fromYear`
                                                )}
                                                edit={true}
                                                dataLoaded={true}
                                                options={years}
                                              />
                                            </motion.div>
                                            <motion.div className="grid grid-cols-2 gap-2">
                                              <FieldSelect
                                                arrowEnd={true}
                                                valuePadding={false}
                                                disabled={e.isPresent}
                                                bgColor={"bg-[#ED1F24]/20"}
                                                injectClass={"h-8"}
                                                fieldProps={profileForm.getFieldProps(
                                                  `experiences.${i}.jobs.${j}.toMonth`
                                                )}
                                                fieldMeta={profileForm.getFieldMeta(
                                                  `experiences.${i}.jobs.${j}.toMonth`
                                                )}
                                                fieldHelper={profileForm.getFieldHelpers(
                                                  `experiences.${i}.jobs.${j}.toMonth`
                                                )}
                                                edit={true}
                                                dataLoaded={true}
                                                options={months}
                                              />
                                              <FieldSelect
                                                arrowEnd={true}
                                                valuePadding={false}
                                                disabled={e.isPresent}
                                                bgColor={"bg-[#ED1F24]/20"}
                                                injectClass={"h-8"}
                                                fieldProps={profileForm.getFieldProps(
                                                  `experiences.${i}.jobs.${j}.toYear`
                                                )}
                                                fieldMeta={profileForm.getFieldMeta(
                                                  `experiences.${i}.jobs.${j}.toYear`
                                                )}
                                                fieldHelper={profileForm.getFieldHelpers(
                                                  `experiences.${i}.jobs.${j}.toYear`
                                                )}
                                                edit={true}
                                                dataLoaded={true}
                                                options={years}
                                              />
                                            </motion.div>

                                            <motion.div
                                              className={`relative p-2 mt-5 ml-3 top-2 ${
                                                e.isPresent
                                                  ? "ring-1 ring-[#2593D2]"
                                                  : "ring-1 ring-[#707070]"
                                              } rounded-full w-[2px] h-[2px] flex flex-col`}
                                              onClick={() =>
                                                selectButton(
                                                  "jobs",
                                                  !e.isPresent,
                                                  i,
                                                  j
                                                )
                                              }
                                            >
                                              <>
                                                {e.isPresent ? (
                                                  <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                                                ) : (
                                                  <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                                                )}
                                              </>
                                            </motion.div>
                                          </motion.div>
                                        </motion.div>
                                      )}
                                    </>
                                  );
                                })}
                                <motion.div
                                  className="relative cursor-pointer bg-white w-max top-1 bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                                  onClick={() => addPositions(i)}
                                >
                                  <motion.div className="text-md">
                                    + &nbsp; add another position
                                  </motion.div>
                                </motion.div>
                              </motion.div>
                            </>
                          )}
                        </>
                      );
                    })}
                </motion.div>
                <motion.div
                  style={{ width: "75%" }}
                  className="relative cursor-pointer bg-white w-max top-1  bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addExperience()}
                >
                  <motion.div className="text-md">
                    + add another company you've worked/working for
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* educations */}
              <motion.div clasName="h-full relative mb-10">
                <motion.div className="relative text-black text-md font-bold mt-2 mb-2">
                  Education
                </motion.div>
                <motion.div className="relative grid grid-cols-1">
                  {profileForm &&
                    profileForm.values &&
                    profileForm.values.educations !== null &&
                    profileForm.values.educations.map((c, i) => {
                      return (
                        <>
                          {c.isActive && (
                            <motion.div className="relative">
                              <motion.div className="relative flex flex-row justify-start">
                                <FieldInput
                                  labelSide="side"
                                  labelLength="74"
                                  label={"School :"}
                                  bgColor={"bg-[#2593D2]/20"}
                                  injectClass={"w-[152%]"}
                                  fieldProps={profileForm.getFieldProps(
                                    `educations.${i}.schoolName`
                                  )}
                                  fieldMeta={profileForm.getFieldMeta(
                                    `educations.${i}.schoolName`
                                  )}
                                  fieldHelper={profileForm.getFieldHelpers(
                                    `educations.${i}.schoolName`
                                  )}
                                  edit={true}
                                  dataLoaded={true}
                                />
                                <motion.div
                                  className={`mt-2 relative bg-white drop-shadow-lg ring-1 ring-white ${
                                    c.schoolLogo && c.schoolLogo !== ""
                                      ? ""
                                      : "p-5"
                                  } rounded-lg`}
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() =>
                                    handleUploadClick("education", i)
                                  }
                                >
                                  {c.schoolLogo && c.schoolLogo !== "" ? (
                                    <img
                                      src={c.schoolLogo}
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  ) : (
                                    <BiUpload className="flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                  )}
                                </motion.div>
                              </motion.div>
                              <motion.div className="relative flex flex-1 flex-row justify-start">
                                <motion.div className="w-full">
                                  <FieldSelect
                                    labelSide="side"
                                    labelLength="50"
                                    label={"Place :"}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"w-[140%] ml-6"}
                                    fieldProps={profileForm.getFieldProps(
                                      `educations.${i}.schoolLocation`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `educations.${i}.schoolLocation`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `educations.${i}.schoolLocation`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={countries}
                                  />
                                </motion.div>
                                <motion.div
                                  className={`cursor-pointer mt-2 relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() => deleteLists("educations", i)}
                                >
                                  <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                </motion.div>
                              </motion.div>
                              <motion.div className="relative flex flex-1 flex-row justify-start">
                                <motion.div
                                  className="text-md text-black mt-5"
                                  style={{ width: "82px" }}
                                >
                                  Degree :{" "}
                                </motion.div>
                                <FieldInput
                                  bgColor={"bg-[#2593D2]/20"}
                                  injectClass={"w-[85%] ml-6"}
                                  fieldProps={profileForm.getFieldProps(
                                    `educations.${i}.degree`
                                  )}
                                  fieldMeta={profileForm.getFieldMeta(
                                    `educations.${i}.degree`
                                  )}
                                  fieldHelper={profileForm.getFieldHelpers(
                                    `educations.${i}.degree`
                                  )}
                                  edit={true}
                                  dataLoaded={true}
                                />
                              </motion.div>
                              <motion.div className="relative flex flex-1 flex-row justify-start">
                                <motion.div
                                  className="grid grid-cols-2 gap-2"
                                  style={{ marginRight: "12%" }}
                                >
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"h-8"}
                                    fieldProps={profileForm.getFieldProps(
                                      `educations.${i}.fromMonth`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `educations.${i}.fromMonth`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `educations.${i}.fromMonth`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={months}
                                  />
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"h-8"}
                                    fieldProps={profileForm.getFieldProps(
                                      `educations.${i}.fromYear`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `educations.${i}.fromYear`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `educations.${i}.fromYear`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={years}
                                  />
                                </motion.div>
                                <motion.div className="grid grid-cols-2 gap-2">
                                  <FieldSelect
                                    arrowEnd={true}
                                    disabled={c.isPresent}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"h-8"}
                                    fieldProps={profileForm.getFieldProps(
                                      `educations.${i}.toMonth`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `educations.${i}.toMonth`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `educations.${i}.toMonth`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={months}
                                  />
                                  <FieldSelect
                                    arrowEnd={true}
                                    disabled={c.isPresent}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"h-8"}
                                    fieldProps={profileForm.getFieldProps(
                                      `educations.${i}.toYear`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `educations.${i}.toYear`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `educations.${i}.toYear`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={years}
                                  />
                                </motion.div>
                                <motion.div
                                  className={`cursor-pointer relative p-2 mt-5 ml-3  top-2 ${
                                    c.isPresent
                                      ? "ring-1 ring-[#2593D2]"
                                      : "ring-1 ring-[#707070]"
                                  } rounded-full w-[2px] h-[2px] flex flex-col`}
                                  onClick={() =>
                                    selectButton(
                                      "education",
                                      !c.isPresent,
                                      i,
                                      ""
                                    )
                                  }
                                >
                                  <>
                                    {c.isPresent ? (
                                      <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                                    ) : (
                                      <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                                    )}
                                  </>
                                </motion.div>
                              </motion.div>
                            </motion.div>
                          )}
                        </>
                      );
                    })}
                </motion.div>
                <motion.div
                  style={{ width: "75%" }}
                  className="relative cursor-pointer bg-white w-max top-1  bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addEducation()}
                >
                  <motion.div className="text-md">
                    + add another degree
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* License */}
              <motion.div clasName="h-full relative mb-10">
                <motion.div className="relative text-black text-md font-bold mt-2 mb-2">
                  License and Certificate :
                </motion.div>
                <motion.div className="relative grid grid-cols-1">
                  {profileForm &&
                    profileForm.values &&
                    profileForm.values.licenses !== null &&
                    profileForm.values.licenses.map((c, i) => {
                      return (
                        <>
                          {c.isActive && (
                            <motion.div className="relative">
                              <motion.div className="relative flex flex-row justify-start">
                                <FieldInput
                                  labelLength="78"
                                  label={"Institution :"}
                                  labelSide="side"
                                  bgColor={"bg-[#2593D2]/20"}
                                  injectClass={"w-[64%]"}
                                  fieldProps={profileForm.getFieldProps(
                                    `licenses.${i}.institutionName`
                                  )}
                                  fieldMeta={profileForm.getFieldMeta(
                                    `licenses.${i}.institutionName`
                                  )}
                                  fieldHelper={profileForm.getFieldHelpers(
                                    `licenses.${i}.institutionName`
                                  )}
                                  edit={true}
                                  dataLoaded={true}
                                />
                                <motion.div
                                  className={`mt-2 relative bg-white drop-shadow-lg ring-1 ring-white ${
                                    c.licenseLogo && c.licenseLogo !== ""
                                      ? ""
                                      : "p-5"
                                  } rounded-lg`}
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() =>
                                    handleUploadClick("license", i)
                                  }
                                >
                                  {c.licenseLogo && c.licenseLogo !== "" ? (
                                    <img
                                      src={c.licenseLogo}
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  ) : (
                                    <BiUpload className="flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                  )}
                                </motion.div>
                              </motion.div>
                              <motion.div className="relative flex flex-1 flex-row justify-start">
                                <motion.div className="w-full">
                                  <FieldInput
                                    labelLength="78"
                                    label={"Title :"}
                                    labelSide="side"
                                    bgColor={"bg-[#2593D2]/20"}
                                    injectClass={"w-[64%]"}
                                    fieldProps={profileForm.getFieldProps(
                                      `licenses.${i}.title`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `licenses.${i}.title`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `licenses.${i}.title`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                  />
                                </motion.div>
                                <motion.div
                                  className={`cursor-pointer mt-2 relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() => deleteLists("license", i)}
                                >
                                  <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                </motion.div>
                              </motion.div>
                              <motion.div className="relative flex flex-1 flex-row justify-start">
                                <motion.div
                                  className="grid grid-cols-2 gap-2"
                                  style={{ marginRight: "12%" }}
                                >
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"h-8"}
                                    fieldProps={profileForm.getFieldProps(
                                      `licenses.${i}.fromMonth`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `licenses.${i}.fromMonth`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `licenses.${i}.fromMonth`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={months}
                                  />
                                  <FieldSelect
                                    arrowEnd={true}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"h-8"}
                                    fieldProps={profileForm.getFieldProps(
                                      `licenses.${i}.fromYear`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `licenses.${i}.fromYear`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `licenses.${i}.fromYear`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={years}
                                  />
                                </motion.div>
                                <motion.div className="grid grid-cols-2 gap-2">
                                  <FieldSelect
                                    arrowEnd={true}
                                    disabled={c.isExpired}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"h-8"}
                                    fieldProps={profileForm.getFieldProps(
                                      `licenses.${i}.toMonth`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `licenses.${i}.toMonth`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `licenses.${i}.toMonth`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={months}
                                  />
                                  <FieldSelect
                                    arrowEnd={true}
                                    disabled={c.isExpired}
                                    valuePadding={false}
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"h-8"}
                                    fieldProps={profileForm.getFieldProps(
                                      `licenses.${i}.toYear`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `licenses.${i}.toYear`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `licenses.${i}.toYear`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={years}
                                  />
                                </motion.div>
                                <motion.div
                                  className={`relative cursor-pointer p-2 mt-5 ml-3  top-2 ${
                                    c.isExpired
                                      ? "ring-1 ring-[#2593D2]"
                                      : "ring-1 ring-[#707070]"
                                  } rounded-full w-[2px] h-[2px] flex flex-col`}
                                  onClick={() =>
                                    selectButton(
                                      "licenses",
                                      !c.isExpired,
                                      i,
                                      ""
                                    )
                                  }
                                >
                                  <>
                                    {c.isExpired ? (
                                      <motion.div className="absolute top-1 right-1 ring ring-5 ring-white rounded-full bg-[#2593D2] p-1 w-[1px] h-[1px]"></motion.div>
                                    ) : (
                                      <motion.div className="absolute ring ring-5 top-1 right-1  ring-white rounded-full bg-[#707070] p-1 w-[1px] h-[1px]"></motion.div>
                                    )}
                                  </>
                                </motion.div>
                              </motion.div>
                            </motion.div>
                          )}
                        </>
                      );
                    })}
                </motion.div>
                <motion.div
                  style={{ width: "75%" }}
                  className="relative cursor-pointer bg-white w-max top-1 bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addLicense()}
                >
                  <motion.div className="text-md">
                    + add another License / Certificate
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* Project */}
              <motion.div clasName="h-full relative mb-10">
                <motion.div className="relative text-black text-md font-bold mt-2 mb-2">
                  Projects :
                </motion.div>
                {profileForm &&
                  profileForm.values &&
                  profileForm.values.projects !== null &&
                  profileForm.values.projects.map((c, i) => {
                    return (
                      <>
                        {c.isActive && (
                          <motion.div className="relative grid grid-cols-1">
                            <motion.div className="relative">
                              <motion.div className="flex flex-row justify-around gap-1">
                                <motion.div className="w-full">
                                  <FieldInput
                                    label={"Title :"}
                                    labelLength="62"
                                    labelSide="side"
                                    bgColor={"bg-[#2593D2]/20"}
                                    injectClass={"w-[100%] mr-3 ml-3"}
                                    fieldProps={profileForm.getFieldProps(
                                      `projects.${i}.projectTitle`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `projects.${i}.projectTitle`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `projects.${i}.projectTitle`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                  />
                                </motion.div>
                                <motion.div
                                  className={`mt-2 relative cursor-pointer bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() => deleteLists("projects", i)}
                                >
                                  <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                </motion.div>
                              </motion.div>
                              <FieldInput
                                label={"Brief :"}
                                labelLength="63"
                                type="textarea"
                                labelSide="side"
                                row={"5"}
                                bgColor={"bg-[#2593D2]/20"}
                                injectClass={"w-[100%] mr-3 ml-3"}
                                fieldProps={profileForm.getFieldProps(
                                  `projects.${i}.projectDescription`
                                )}
                                fieldMeta={profileForm.getFieldMeta(
                                  `projects.${i}.projectDescription`
                                )}
                                fieldHelper={profileForm.getFieldHelpers(
                                  `projects.${i}.projectDescription`
                                )}
                                edit={true}
                                dataLoaded={true}
                              />
                            </motion.div>
                          </motion.div>
                        )}
                      </>
                    );
                  })}
                <motion.div
                  style={{ width: "75%" }}
                  className="relative cursor-pointer bg-white w-max top-1 bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addProjects()}
                >
                  <motion.div className="text-md">
                    + add another Project
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* Language */}
              <motion.div clasName="h-full relative mb-10">
                <motion.div className="relative text-black text-md font-bold mt-2 mb-2">
                  Languages Known :
                </motion.div>
                {profileForm &&
                  profileForm.values &&
                  profileForm.values.languages !== null &&
                  profileForm.values.languages.map((c, i) => {
                    return (
                      <>
                        {c.isActive && (
                          <motion.div className="relative">
                            <motion.div className="relative grid grid-cols-1">
                              <FieldSelect
                                label={"Language :"}
                                labelSide="side"
                                labelLength="86"
                                bgColor={"bg-[#ED1F24]/20"}
                                injectClass={"w-[100%] mr-3 ml-3"}
                                fieldProps={profileForm.getFieldProps(
                                  `languages.${i}.language`
                                )}
                                fieldMeta={profileForm.getFieldMeta(
                                  `languages.${i}.language`
                                )}
                                fieldHelper={profileForm.getFieldHelpers(
                                  `languages.${i}.language`
                                )}
                                edit={true}
                                dataLoaded={true}
                                options={languages}
                              />
                              <motion.div className="flex flex-row justify-start gap-1">
                                <motion.div className="w-full">
                                  <FieldSelect
                                    label={"Level :"}
                                    labelSide="side"
                                    labelLength="88"
                                    bgColor={"bg-[#ED1F24]/20"}
                                    injectClass={"w-[100%] mr-1 ml-1"}
                                    fieldProps={profileForm.getFieldProps(
                                      `languages.${i}.languageLevel`
                                    )}
                                    fieldMeta={profileForm.getFieldMeta(
                                      `languages.${i}.languageLevel`
                                    )}
                                    fieldHelper={profileForm.getFieldHelpers(
                                      `languages.${i}.languageLevel`
                                    )}
                                    edit={true}
                                    dataLoaded={true}
                                    options={langLevel}
                                  />
                                </motion.div>
                                <motion.div
                                  className={`mt-2 cursor-pointer relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() => deleteLists("lang", i)}
                                >
                                  <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                                </motion.div>
                              </motion.div>
                            </motion.div>
                          </motion.div>
                        )}
                      </>
                    );
                  })}
                <motion.div
                  style={{ width: "75%" }}
                  className="relative cursor-pointer bg-white w-max top-1  bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addLanguages()}
                >
                  <motion.div className="text-md">
                    + add another Language
                  </motion.div>
                </motion.div>
              </motion.div>
              <hr />
              {/* Skill */}
              <motion.div
                clasName="h-full relative mb-20"
                style={{ marginBottom: "13%" }}
              >
                <motion.div className="relative text-black text-md font-bold mt-2 mb-2">
                  Skills :
                </motion.div>
                <motion.div className="relative grid grid-cols-1">
                  {profileForm &&
                    profileForm.values &&
                    profileForm.values.skills !== null &&
                    profileForm.values.skills.map((c, i) => {
                      return (
                        <>
                          {c.isActive && (
                            <motion.div className="relative flex flex-1 flex-row justify-start">
                              <motion.div
                                className="text-md text-black mt-5"
                                style={{ width: "2px" }}
                              ></motion.div>
                              <FieldInput
                                bgColor={"bg-[#ED1F24]/20"}
                                injectClass={"w-[87%] ml-6"}
                                fieldProps={profileForm.getFieldProps(
                                  `skills.${i}.skillName`
                                )}
                                fieldMeta={profileForm.getFieldMeta(
                                  `skills.${i}.skillName`
                                )}
                                fieldHelper={profileForm.getFieldHelpers(
                                  `skills.${i}.skillName`
                                )}
                                edit={true}
                                dataLoaded={true}
                              />
                              <motion.div
                                className={`mt-2 cursor-pointer relative bg-white drop-shadow-lg ring-1 ring-white p-5 rounded-lg`}
                                style={{ width: "50px", height: "50px" }}
                                onClick={() => deleteLists("skills", i)}
                              >
                                <BiTrash className="text-[red]  flex flex-row m-4 text-xl text-center align-center justify-center items-center" />
                              </motion.div>
                            </motion.div>
                          )}
                        </>
                      );
                    })}
                </motion.div>
                <motion.div
                  style={{ width: "75%" }}
                  className="relative cursor-pointer bg-white w-max top-1 bottom-1 mt-5 mx-10 py-2 px-10 justify-center items-center align-center flex flex-row drop-shadow-lg rounded-lg ring text-center"
                  onClick={() => addSkills()}
                >
                  <motion.div className="text-md">
                    + add another Skill
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default EditProfile;
