import React, { useState } from "react"
import { motion } from 'framer-motion'
import { FaPen } from "react-icons/fa"
import { useHistory } from "react-router-dom"


const About = ({about,edit}) => {
    const [showMore, setShowMore] = useState(false)
    const history =  useHistory()

    return (
        <motion.div className={`relative w-full text-black flex flex-col md:drop-shadow-xl md:rounded-3xl bg-white mt-2 md:px-2 md:py-4 h-full`}>
            <motion.div className={`flex flex-row justify-between md:m-4 m-3`}>
                <motion.div className={`text-xl font-bold`}>
                    About
                </motion.div>
                {edit && <motion.div className={`relative cursor-pointer rounded-full backdrop-blur-sm bg-[#2593D2]/30`} style={{ height: '30px', width: '30px' }} onClick={()=>history.push("/editProfile")}>
                    <FaPen className="text-white text-sm m-[8px]" />
                </motion.div>}
            </motion.div>
            <motion.div className={`relative text-sm px-3 md:px-5 h-36 ${showMore ? 'overflow-auto' : 'overflow-hidden'} md:mb-10`}>
                {about && about !== null ?  about : edit ?  <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">Add About</motion.div> : <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">No About</motion.div>}
            </motion.div>
            {about && about !==null && <motion.div className={`md:px-5 px-3 relative flex flex-1 cursor-pointer text-sm text-center justify-center items-center align-center`}>
                <motion.div className="bg-white px-3 ring-1 ring-[#2593D2] w-fit drop-shadow-lg rounded-lg" onClick={() => setShowMore(!showMore)}>
                    {showMore ? "SHOW LESS" : "SHOW MORE"}
                </motion.div>
            </motion.div>}
        </motion.div>
    )
}

export default About