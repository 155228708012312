import {SET_LOGGEDIN,SET_TOKEN,SET_USER_DETAILS,SET_USER,SET_TEMP_USER,SET_SWITCH_PROFILE_MODE,
    SET_SCREEN_NAME,SET_PROFILE_EDIT_MODE,SET_CALL_USER_API,SET_FOLLOW_DETAIL,SET_LOAD_ALL_POST,SET_COUNTRY_LIST,SET_OPEN_NOTIFICATION_BOX} from './constants'

export const setLoggedIn = (value)=>{
    if(!value){
        localStorage.removeItem('token')
    }
    return{
        type:SET_LOGGEDIN,
        payload:value
    }
}

export const setUserDetails = value=>{
    return{
        type:SET_USER_DETAILS,
        payload:value
    }
}

export const setUser = value=>{
    return{
        type:SET_USER,
        payload:value
    }
}

export const setToken = value =>{
    return{
        type:SET_TOKEN,
        payload:value
    }
}

export const setTempUser = value =>{
    return{
        type:SET_TEMP_USER,
        payload:value
    }
}

export const setSwitchProfileMode = value =>{
    return {
        type : SET_SWITCH_PROFILE_MODE,
        payload: value
    }
}

export const setScreenName = value =>{
    return {
        type : SET_SCREEN_NAME,
        payload:value
    }
}

export const setEditProfileMode = value =>{
    return {
        type : SET_PROFILE_EDIT_MODE,
        payload:value
    }
}

export const setCallUserApi = value =>{
    return {
        type : SET_CALL_USER_API,
        payload:value
    }
}

export const setFollowDetail = value=>{
    return{
        type:SET_FOLLOW_DETAIL,
        payload:value
    }
}

export const setLoadAllPost = value =>{
    return {
        type: SET_LOAD_ALL_POST,
        payload:value
    }
}

export const setCountryList = value =>{
    return {
        type:SET_COUNTRY_LIST,
        payload:value
    }
}

export const setOpenNotificationBox = value =>{
    return {
        type:SET_OPEN_NOTIFICATION_BOX,
        payload:value
    }
}