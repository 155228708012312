import AWS from 'aws-sdk';

const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
    secretAccessKey:process.env.REACT_APP_AWS_S3_SECRET_KEY,
    region : 'ap-southeast-2'
  });

export const fileUpload = (data)=>{
    const params = {
        Bucket : process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Key:data.name,
        ContentType : data.type,
        Body : data
    }
    return s3.upload(params).promise();
}