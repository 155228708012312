import React from 'react';
import {motion} from 'framer-motion'
import videoScreen from '../assets/Icons/videoScreen.png'

const Video = ( ) =>{
    return (
        <motion.div className="relative min-h-screen items-center flex flex-col overflow-hidden text-center justify-center">
                <img src={videoScreen} className="h-96 w-96"/>
                <motion.div className="text-5xl font-bold text-black">
                Get ready for the future of communication! Our new video conferencing platform is coming soon and it's going to change the game.
                </motion.div>
        </motion.div>
    )
}

export default Video