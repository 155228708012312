import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import axios from "axios";
import API from "../../axios/API";
import { IoCloseOutline } from "react-icons/io5";
import { FiCheck, FiPlus } from "react-icons/fi";
import { motion } from "framer-motion";
import { MdLocationPin } from "react-icons/md";
import { useSelector } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import profilePic from "../../assets/EditLogo.jpg";
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { setAlert } from "../../store/theme/actions";
import profileImage from "../../assets/dummyProfile.webp";

import { ImSpinner10 } from "react-icons/im";

const initialFormValues = {
  userId: "",
  postType: "",
  postedBy: "",
  location: "",
  description: "",
  medias: [],
};

const CreatePost = ({ onClose }) => {
  const [postType, setPostType] = useState([]);
  const [countries, setCountries] = useState([]);
  const [userList, setUserList] = useState([]);
  const [openCountry, setOpenCountry] = useState(false);
  const [formData, setFormData] = useState(initialFormValues);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth.userDetails);
  const fullDetails = useSelector((state) => state.auth);
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  const [textLength, setTextLength] = useState(0)

  const [load, setload] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    setFormData({
      ...formData,
      userId: userDetails._id,
      postedBy: userDetails._id,
    });
    axios
      .all([
        API.get("/menu/postTypes"),
        API.get("/menu/countries"),
        API.get("/user/suggestionName"),
      ])
      .then(
        axios.spread((postResp, conResp, userResp) => {
          setPostType(
            postResp.data.body.map((c) => ({
              text: c.postType,
              value: c.postTypeId,
            }))
          );
          setCountries(
            conResp.data.body.map((c) => ({ text: c.name, value: c.code }))
          );
          setUserList(userResp.data.data);
        })
      );
  }, []);

  const [selectedImages, setSelectedImages] = useState([]);
  const [fileUpload, setFileUpload] = useState(null);

  const handleImageChange = useCallback((event) => {
    const file = event.target;
    setFileCount((prevFileCount) => prevFileCount + 1);
    const reader = new FileReader();
    setFileUploadProgress(true);
    reader.onload = async () => {
      const base64Data = await reader.result.split(",")[1];
      let values = {
        data: base64Data,
        fileName: file.files[0].name,
        contentType: file.files[0].type,
      };
      API.post(`/post/uploadMediaPost`, values).then((res) => {
        setSelectedImages((prevImages) => [
          ...prevImages,
          res.data.upload.Locations,
        ]);
        setFileUploadProgress(false);
      });
    };
    reader.readAsDataURL(file.files[0]);
    // API.post(`/post/uploadMediaPost`, formData, {
    //   onUploadProgress: (p) => {
    //     const percentCompleted = Math.round((p.loaded * 100) / p.total);
    //     setFileUploadProgress(true);
    //     setFileUpload({
    //       index: fileCount,
    //       fileName: file.files[0].name,
    //       percentCompleted,
    //     });
    //   },
    // }).then((res) => {
    //   setSelectedImages((prevImages) => [
    //     ...prevImages,
    //     res.data.upload.Location,
    //   ]);
    //   setFileUploadProgress(false);
    // });
  }, []);

  const handleImageDelete = (event, id) => {
    event.preventDefault();
    const updatedImages = selectedImages.filter((image, i) => i !== id);
    setSelectedImages(updatedImages);

    const remainingFiles = updatedImages.length;
    inputRef.current.value = null;
    inputRef.current.files = remainingFiles;
    setFormData({ ...formData, medias: updatedImages });
  };

  const handlePostType = (value) => {
    setFormData({ ...formData, postType: value });
  };

  const handlelocation = (value) => {
    setFormData({ ...formData, location: value });
    setOpenCountry(false);
  };

  const handleDescription = (value) => {
    setTextLength(value.toString().length)
    setFormData({ ...formData, description: value });
  };

  const uploadPost = async () => {
    formData.userId = userDetails._id;
    formData.postedBy = userDetails._id;
    formData.medias = selectedImages;
    const hashRegex = /#.*/;
    const tagsRegex = /(\([^)]+\))/g;
    const hashTagsArray = formData.description.match(hashRegex);
    formData.hashTags = [...new Set(hashTagsArray)];
    const tagsArray = formData.description.match(tagsRegex);
    formData.postType = formData.postType !== null && formData.postType !== undefined && formData.postType !== '' ? formData.postType : 2
    formData.tags = [...new Set(tagsArray)].map((c) => {
      return c.split("(")[1].split(")")[0];
    });
    if (
      (formData.medias === null || formData.medias.length === 0) &&
      (formData.description === null || formData.description === "")
    ) {
      dispatch(
        setAlert({
          type: "error",
          title: "Error",
          subtitle: "Please upload either your thoughts or medias to post",
          active: true,
        })
      );
    } else {
      setload(true);
      await API.post("/post/uploadPost", formData)
        .then(async (res) => {
          console.log(res);
          if (res.status === 200) {
            dispatch(
              setAlert({
                type: "success",
                title: "Success",
                subtitle: "Post Uploaded Successfully",
                active: true,
              })
            );
            onClose(false);
          } else {
            dispatch(
              setAlert({
                type: "error",
                title: "Error",
                subtitle: "Something went wrong",
                active: true,
              })
            );
            onClose(false);
          }
          setload(false);
        })
        .catch((err) => {
          console.log(err);
          setload(false);
        });
    }
  };

  const users =
    userList &&
    userList.length > 0 &&
    userList.map((c) => {
      return { id: c._id, display: c.userName, avatar: c.profileImage };
    });

  const renderSuggestion = (
    suggestion,
    search,
    highlightedIndex,
    index,
    focus
  ) => {
    return (
      <div
        className={`mention bg-[white] shadow-lg flex flex-row text-black py-2 px-2 text-base hover:font-bold hover:text-md ${focus ? "focus" : ""
          }`}
        key={index}
      >
        <motion.div className="mr-2 ml-2">
          <img
            src={suggestion.avatar !== null ? suggestion.avatar : profileImage}
            alt="Avatar"
            className="mention-avatar rounded-lg"
            width={20}
            height={20}
          />
        </motion.div>
        <motion.div className="mr-2 ml-2">{suggestion.display}</motion.div>
      </div>
    );
  };

  const renderImagePreviews = () => {
    const imageExtensionRegex = /\.(jpg|jpeg|png|gif|bmp|svg|JPG|JPEG)$/;
    const videoExtensionRegex = /\.(mp4|avi|mov|wmv|flv|mkv|MOV)$/;
    return (
      <div className="flex flex-row justify-around mt-4">
        {selectedImages.map((image, i) => (
          <div key={i} className="relative mr-4">
            {image.match(imageExtensionRegex) && (
              <img
                src={image}
                alt="Preview"
                style={{ height: "100px", width: "100px" }}
              />
            )}
            {image.match(videoExtensionRegex) && (
              <video controls width="100" height="100">
                <source src={image} type="video/mp4" />
              </video>
            )}
            <button
              id="deleteIcon"
              className="absolute top-0 right-0 rounded-full backdrop-blur-sm bg-white/30"
              onClick={(event) => handleImageDelete(event, i)}
            >
              <IoCloseOutline className="text-[red] " />
            </button>
          </div>
        ))}
        {selectedImages.length > 0 && <motion.div className="relative m-4 p-10 flex flex-col bg-[white] h-[100px] w-[100px] p-4">
          <motion.div className="text-2xl w-full h-full m-4 p-10">
            <FiPlus className="mx-10 my-10" />
          </motion.div>
        </motion.div>}
        {fileUploadProgress && fileUpload && (
          <>
            <div className="relative">
              {/* <img src={profilePic} style={{ height: '100px', width: '100px' }} /> */}
              <div className="bg-[grey] h-32 w-32 rounded-lg"></div>
              <div className="absolute inset-0 bg-black/60 flex items-center justify-center">
                {fileUpload && (
                  <div className="relative">
                    <div
                      className="h-20 w-20 rounded-full  border-blue-500 border-4 border-t-4 border-gray-200 border-solid animate-spin"
                      style={{
                        background: `conic-gradient(
          from -90deg,
          transparent ${100 - fileUpload.percentCompleted}%,
          #blue-500 0
        )`,
                      }}
                    />
                    <div className="absolute top-1/2 left-1/2 text-white font-bold transform -translate-x-1/2 -translate-y-1/2">
                      {fileUpload.percentCompleted}%
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <motion.div className="relative flex flex-col w-full text-black">
      <motion.div className="flex mt-1 ml-3">
        <img
          src={
            userDetails && userDetails.profileImage
              ? userDetails.profileImage
              : profilePic
          }
          alt={`Profile Picture`}
          className="rounded-full w-16 h-16 object-cover mb-2 shadow-lg"
        />
        <motion.div className=" items-center mt-2 ml-4">
          <h5 className="text-lg font-bold mr-2">
            {userDetails && userDetails.firstName} {userDetails && userDetails.lastName}
          </h5>
          <select
            className="mt-2 mb-2 w-full bg-gray-300 rounded-sm"
            onChange={(ev) => handlePostType(ev.target.value)}
          >
            <option value={null} disabled={true}>
              Select Post Type
            </option>
            {postType && postType.length
              ? postType.map((c) => {
                return <option value={c.value}>{c.text}</option>;
              })
              : ""}
          </select>
        </motion.div>
      </motion.div>
      <div className="flex flex-col flex-start px-4 py-3 w-full text-black">
        <motion.div className="flex flex-row justify-end text-end align-end items-end">
          {textLength + '/' + '450'}
        </motion.div>
        <MentionsInput
          onChange={(ev) => handleDescription(ev.target.value)}
          markup="@[__display__](__id__)"
          style={{ width: "inherit", outline: "none !important" }}
          placeholder={`What's on your mind, ${userDetails && userDetails.userName
            }?`}
          maxLength={450}
          value={formData.description}
          className="px-2 rounded-lg resize-none border-0 h-48 outline-none focus:outline-none"
        >
          <Mention
            trigger="@"
            data={users}
            renderSuggestion={renderSuggestion}
            displayTransform={(id, display, avata) => `${display}`}
          />
        </MentionsInput>
      </div>
      <motion.div className="row w-full mt-5 px-4 py-3">
        <div className="flex flex-col">
          <div className="flex-1 bg-gray-100 p-8 rounded-lg border-2 border-gray-400">
            <label
              htmlFor="file-upload"
              className={`rounded-md cursor-pointer flex flex-col ${selectedImages.length === 0 ? "items-center" : ""
                }`}
            >
              {selectedImages.length === 0 && (
                <BsFillFileEarmarkPlusFill className="relative mb-2" />
              )}
              {selectedImages.length === 0 ? (
                <>
                  <h3 className="text-lg font-medium text-center">
                    Add Photos/Videos
                  </h3>
                  <span className="text-gray-400 text-sm">
                    or drag and drop
                  </span>
                </>
              ) : (
                <motion.div className="relative mt-5 flex flex-row">
                  {/* {selectedImages.map((image,i) => (
                  <motion.div key={i} className='px-4'>
                    <motion.div className='relative w-fit'>
                      <img src={image} alt="Preview" style={{ height: '50px', width: '50px' }} />
                      <button id="deleteIcon"
                        className="absolute top-0 right-0 rounded-full backdrop-blur-sm bg-white/30"
                        onClick={(event) => handleImageDelete(event, i)}
                      >
                        <IoCloseOutline className='text-[red] ' />
                      </button>
                    </motion.div>
                  </motion.div>
                ))} */}
                  {selectedImages.length > 0 && renderImagePreviews()}
                </motion.div>
              )}
              <input
                id="file-upload"
                name="file"
                accept=".png, .jpeg, .jpg, .mp4, .avi, .mov"
                type="file"
                className="sr-only"
                onChange={handleImageChange}
              />
            </label>
          </div>

          <input
            type="text"
            disabled={true}
            placeholder="Add to your post"
            value={
              formData &&
              formData.location &&
              countries.filter((x) => x.value === formData.location)[0].text
            }
            className="text-black font-medium p-4 rounded-lg border-2 border-gray-400 mt-4"
          />
          <div className="relative lg:inset-y-0 md:-inset-y-5 right-0 flex items-center mr-4">
            <motion.div
              className="relative flex flex-col w-full"
              style={{ marginTop: "-13%" }}
            >
              <motion.div className="location">
                <button
                  className="absolute cursor-pointer right-3 rounded-full backdrop-blur-sm bg-white/30`"
                  onClick={() => setOpenCountry(!openCountry)}
                >
                  <MdLocationPin className="text-red-600 font-bold text-2xl" />
                </button>
              </motion.div>
            </motion.div>
          </div>
          {openCountry && (
            <motion.div className="text-black absolute right-0 ring-1 text-left ring-white  bg-white drop-shadow-xl h-[200px] overflow-auto">
              {countries.map((c) => {
                return (
                  <motion.div
                    className={`px-2 py-2 cursor-pointer flex flex-row justify-between ${c.value === formData.location ? "bg-[skyblue]" : "bg-none"
                      }`}
                    key={c.value}
                    onClick={() => handlelocation(c.value)}
                  >
                    {c.text}
                    {c.value && formData && c.value === formData.location && (
                      <FiCheck
                        className={
                          "text-[green] relative mr-2 ml-2 text-xl font-bold"
                        }
                      />
                    )}
                  </motion.div>
                );
              })}
            </motion.div>
          )}
          <button
            disabled={load || fileUploadProgress}
            type="submit"
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg flex justify-center mt-4 w-full"
            onClick={() => uploadPost()}
          >
            {load ? (
              <ImSpinner10 className="relative animate-spin text-2xl" />
            ) : fileUploadProgress ? (
              "File upload in progress"
            ) : (
              "Post"
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default CreatePost;
