import React, { useEffect, useState } from "react";
import addPostIcon from "../assets/Icons/addpost.png";
import searchIcon from "../assets/Icons/search.png";
import profilePic from "../assets/dummyProfile.webp";
import { motion } from "framer-motion";
import micIcon from "../assets/Icons/mic.png";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setLoadAllPost, setScreenName } from "../store/auth/actions";
import API from "../axios/API";
import Dialog from "../Components/Dialog";
import CreatePost from "../Modules/Post/CreatePost";

const TopBar = () => {
  const [search, setSearch] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchResult, setSearchResult] = useState([]);
  const screenName = useSelector((state) => state.auth.screenName);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [countries, setCountries] = useState([]);
  const [openCreatePost, setOpenCreatePost] = useState(false);
  const loadAllPostCount = useSelector((state) => state.auth.loadAllPost);

  const handleChange = (event) => {
    setSearch(event.target.value);
    if (event.target.value.toString().length === 0) {
      setSearchResult([]);
    }
    if (event.target.value.toString().length > 0) {
      API.get(`/user/user-search/${event.target.value}`).then((res) => {
        if (res.status === 200) {
          setSearchResult(res.data.data);
        }
      });
    }
  };

  useEffect(() => {
    API.get("/menu/countries").then((countryResp) => {
      setCountries(
        countryResp.data.body.map((c) => ({ text: c.name, value: c.code }))
      );
    });
  }, []);

  const routeToPage = () => {
    dispatch(setScreenName("Profile"));
    history.push("/profile");
  };

  const getCountry = (value) => {
    let city = countries.filter((c) => c.value === value);
    return city && city.length > 0 ? city[0].text : "";
  };

  const redirectToProfile = (value) => {
    history.push(`/profile/${value._id}`);
    setSearch("");
    setSearchResult([]);
  };

  const loadPost = (value) => {
    dispatch(setLoadAllPost(loadAllPostCount + 1));
    setOpenCreatePost(value);
  };

  return (
    <motion.div className="relative bg-[lightGrey] flex flex-col">
      <motion.div className="relative w-full flex flex-row justify-end">
        <motion.div
          className={`relative md:top-2 right-0 md:rounded-tl-lg md:rounded-bl-lg  bg-[#111721] md:px-2 sm:px-1 py-1 flex flex-row justify-center items-center align-center text-center`}
        >
          <motion.div
            className={`md:w-96 h-[55px] p-3 flex flex-row justify-start sm:gap-2 md:gap-5 text-white`}
          >
            {screenName && screenName === "Home" && (
              <motion.div className={`relative w-full`}>
                {/* <motion.div className="md:hidden block -mt-1 mr-2"><img src={addPostIcon} alt="" height={100} width={100} onClick={()=>setOpenCreatePost(!openCreatePost)} /></motion.div> */}
                <motion.div className="hidden md:block">
                  <img
                    src={addPostIcon}
                    alt=""
                    height={30}
                    width={30}
                    onClick={() => setOpenCreatePost(!openCreatePost)}
                  />
                </motion.div>
              </motion.div>
            )}
            <motion.div
              className={`relative w-full flex flex-row justify-start bg-white rounded-tl-full rounded-bl-full gap-3`}
            >
              <motion.div className={"relative px-1 top-0 left-2 py-1.5"}>
                <img src={searchIcon} alt="" height={30} width={30} />
              </motion.div>
              <motion.div className={"relative w-[250px] py-1"}>
                <input
                  type="text"
                  className="bg-white outline-none text-[black]"
                  placeholder="Search..."
                  value={search}
                  onChange={(ev) => handleChange(ev)}
                ></input>
              </motion.div>
              <motion.div className={"relative px-1 -top-1.5 -right-1 py-1"}>
                <img src={micIcon} alt="" height={30} width={30} />
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.div
            className="relative cursor-pointer mx-2"
            onClick={() => routeToPage()}
          >
            <img
              src={
                userDetails && userDetails.profileImage
                  ? userDetails.profileImage
                  : profilePic
              }
              // height={50}
              // width={50}
              style={{ height: '50px', width: '50px' }}
            />
          </motion.div>
        </motion.div>
      </motion.div>
      {search &&
        search !== "" &&
        searchResult &&
        searchResult.length &&
        searchResult.length > 0 ? (
        <motion.div className="absolute z-10 w-[450px] bg-white top-[90px] right-0 drop-shadow-lg ring-white">
          {searchResult.map((c) => {
            return (
              <>
                {c._id && c._id !== userDetails._id && (
                  <motion.div
                    className="relative px-5 py-2 hover:bg-[#111721] hover:text-white text-black cursor-pointer"
                    onClick={() => redirectToProfile(c)}
                  >
                    <motion.div
                      className={"relative flex flex-row justify-start gap-10"}
                    >
                      <motion.div className={"relative  w-6 h-6"}>
                        <img
                          src={
                            c && c.profileImage ? c.profileImage : profilePic
                          }
                          alt=""
                          className="rounded-full"
                        />
                      </motion.div>
                      <motion.div className={"relative text-sm font-medium"}>
                        {c.firstName} {c.lastName}
                      </motion.div>
                      <motion.div
                        className={
                          "relative text-[lightGrey] text-sm font-medium"
                        }
                      >
                        {c && c.accountTypeId && c.accountTypeId === "1"
                          ? "Agriculture"
                          : "Aquaculture"}{" "}
                        {c && c.designation && `| ${c.designation}`}{" "}
                        {c && c.countryCode && isNaN(parseInt(c.countryCode))
                          ? `| ${getCountry(c.countryCode)}`
                          : ""}
                      </motion.div>
                    </motion.div>
                  </motion.div>
                )}
              </>
            );
          })}
        </motion.div>
      ) : (
        ""
      )}
      <Dialog
        injectClass={"w-[852px]"}
        title={"Add Post"}
        showDialog={openCreatePost}
        onClose={() => setOpenCreatePost(false)}
      >
        <CreatePost onClose={(ev) => loadPost(ev)} />
      </Dialog>
    </motion.div>
  );
};

export default TopBar;
