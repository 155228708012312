import React from 'react'
import { motion } from 'framer-motion'
import { IoArrowBackCircleSharp } from 'react-icons/io5'
import logo from '../../assets/minlogo.jpeg'
import otpLogo from '../../assets/otpLogo.png'
import FieldInput from '../../Components/FieldInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from '../../Components/Button'
import { useHistory } from 'react-router-dom'
import API from '../../axios/API'
import { setAlert } from '../../store/theme/actions'
import { useDispatch } from 'react-redux'

const initialFormValues = {
    otp: ''
}

const OTP = ({ history }) => {

    const dispatch = useDispatch()

    const forgotPassSchema = Yup.object().shape({
        otp: Yup.string().required("OTP is required")
    })

    const forgotPass = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: forgotPassSchema,
        onSubmit: (values) => {
            let details = {
                emailEnable: history.location.state.state.emailEnable,
                token: history.location.state.state.token,
                phone: history.location.state.state.emailEnable ? null : history.location.state.state.userId,
                email: history.location.state.state.emailEnable ? history.location.state.state.userId : null,
                otp: values.otp
            }
            API.post('/auth/verifyOTP', details).then((res) => {
                if (res.status === 200 && res.data.status === 200) {
                    history.push('/auth/reset_password', { state: { userId: history.location.state.state.userId } })
                } else {
                    dispatch(setAlert({ type: 'error', title: 'Error', subtitle: res.data.message, active: true }))
                }
            })
        }
    })

    return (
        <motion.div className={'min-h-screen flex flex-col items-center justify-center  relative bg-[lightGrey]'}>
            <motion.div className={'bg-white relative dropshadow-lg rounded-2xl px-2 py-4 md:w-2/4 w-80'}>
                <motion.div className={'flex flex-row justify-between'}>
                    <motion.div className="sticky">
                        <IoArrowBackCircleSharp className="h-6 w-6 cursor-pointer" onClick={() => history.push('/auth/forgot_password')} />
                    </motion.div>
                    <motion.div>
                        <img src={logo} className="h-14 w-14" />
                    </motion.div>
                </motion.div>
                <motion.div className={'flex flex-col items-center text-center'}>
                    <motion.div>
                        <img src={otpLogo} className="h-28 w-28" />
                    </motion.div>
                    <motion.div className="text-2xl mt-2">
                        <p>Enter 6 Digit Code</p>
                    </motion.div>
                    <motion.div className="text-sm mt-2 text-[#A4A5AA]">
                        <p>Enter the code we sent and we will process your account</p>
                    </motion.div>
                </motion.div>
                <motion.div className="mt-2 mb-2  px-14">
                    <FieldInput label={'Enter OTP'} fieldMeta={forgotPass.getFieldMeta('otp')} fieldProps={forgotPass.getFieldProps("otp")} fieldHelper={forgotPass.getFieldHelpers('otp')} edit={true} dataLoaded={true} />
                </motion.div>
                <motion.div className='mt-2 mb-2 px-10 flex flex-row text-center justify-center items-center'>
                    <Button injectClass={'w-64 text-xl  bg-[#2593D2]'} onClick={() => forgotPass.submitForm()}>Continue</Button>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default OTP