import React, { useEffect, useState } from "react";
import API from "../axios/API";
import { motion } from "framer-motion";
import NotificationLoader from "../Loaders/NotificationLoader";
import { useHistory } from "react-router-dom";
import { setOpenNotificationBox } from "../store/auth/actions";
import { useDispatch } from "react-redux";
import notificationpng from "../assets/Icons/notification.svg";
import dummyProfile from "../assets/dummyProfile.webp";

const Notification = () => {
  const [notifications, setNotifications] = useState();
  const [notificationLoader, setNotificationLoader] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    API.get("/common/getAllNotification").then((res) => {
      if (res.status === 200) {
        setNotifications(res.data.body);
        setNotificationLoader(false);
      }
    });
  }, []);

  const makeRead = (value) => {
    if (value.type === "post") {
      API.post("/common/makeReadNotification", {
        notificationId: value._id,
      }).then((res) => {
        dispatch(setOpenNotificationBox(false));
        history.push(`/post/${value.postId}`);
      });
    }
  };

  const timeAgo = (uploadTime) => {
    const now = new Date();
    const timeDifference = now - new Date(uploadTime);

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return years === 1 ? `${years} Y ago` : `${years} Y ago`;
    } else if (months > 0) {
      return months === 1 ? `${months} M ago` : `${months} M ago`;
    } else if (weeks > 0) {
      return weeks === 1 ? `${weeks} W ago` : `${weeks} W ago`;
    } else if (days > 0) {
      return days === 1 ? `${days} D ago` : `${days} D ago`;
    } else if (hours > 0) {
      return hours === 1 ? `${hours} H ago` : `${hours} H ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? `${minutes} M ago` : `${minutes} M ago`;
    } else {
      return seconds === 1 ? `${seconds} S ago` : `${seconds} S ago`;
    }
  };

  return (
    <motion.div className="p-1 text-black">
      {notificationLoader ? (
        <NotificationLoader />
      ) : (
        <motion.div className="relative md:pb-0 pb-10">
          {notifications && notifications.length > 0 ? (
            notifications.map((c) => {
              return (
                <motion.div
                  className={`border-blue-300 mt-2 mb-2 rounded-lg shadow rounded-md p-1 md:p-2 w-full mx-auto ${
                    c.isRead === false && "bg-sky-50"
                  }`}
                  onClick={() => makeRead(c)}
                >
                  <motion.div className="flex space-x-4">
                    <motion.div className="rounded-full">
                      <img
                        src={
                          c.secondaryUser.profileImage !== null
                            ? c.secondaryUser.profileImage
                            : dummyProfile
                        }
                        className="h-10 w-10"
                        alt="image"
                      />
                    </motion.div>
                    <motion.div className="flex-1 flex flex-row justify-between space-y-6 py-1">
                      <motion.div className="h-2 w-[235px] md:w-[300px] rounded">
                        {c.message}
                      </motion.div>
                      <motion.div className="text-center items-center justify-center h-8 flex">
                        {timeAgo(c.createdAt)}
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              );
            })
          ) : (
            <motion.div className="flex flex-1 flex-col text-center min-h-screen items-center justify-center align-center">
              <img
                src={notificationpng}
                alt="notification"
                className="h-48 w-48 opacity-50"
              />
              <motion.div className="text-xl font-bold text-black">
                No Notification
              </motion.div>
            </motion.div>
          )}
        </motion.div>
      )}
    </motion.div>
  );
};

export default Notification;
