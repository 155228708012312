import { motion, AnimatePresence } from "framer-motion"
import { IoMdClose } from "react-icons/io"
const Dialog = ({ children, showDialog, title, onClose, injectClass = '' }) => {
    const close = () => {
        if (typeof onClose === 'function') onClose()
    }

    return (
        <AnimatePresence>
            {
                showDialog &&
                <motion.div initial="hidden" animate="visible" exit="hidden" className="flex items-center text-[black] justify-center fixed top-0 bottom-0 left-0 right-0 bg-black text-gray-700 dark:text-white bg-opacity-60 z-[900]">
                    {/* <motion.div className="rounded-full p-2 bg-[red] -top-10 right-10 text-white relative flex flex-row justify-center"><motion.div className="p-2 m-2">{centerIcon}</motion.div></motion.div> */}
                    <motion.div className={`bg-white  dark:bg-ldark sm:max-w-[300px] xs:max-w-[300px] lg:max-w-[600px] xl:max-w-[600px] 2xl:max-w-[600px] md:max-w-[600px] rounded-lg shadow-xl flex flex-col max-h-[90vh] ${injectClass}`}>
                        {/* <motion.div className="relative top-0 left-0 right-0 flex justify-center p-4" style={{ borderBottom: "2px solid" }}>
                  <h2 className="text-lg font-bold text-center w-full"> {title}</h2>
                  <motion.div className={`relative cursor-pointer rounded-full bg-[#000]/30 ml-auto`} style={{ height: '30px', width: '30px' }} onClick={close}>
                    <IoMdClose className="text-white text-sm m-[8px]"  onClick={close} />
                  </motion.div>
                </motion.div> */}
                        <div className="flex justify-between items-center" style={{ borderBottom: "2px solid", height:"35px"}}>
                            <span className="text-sm text-[black] bg-[white] font-bold mx-auto">
                                {title}
                            </span>
                            <IoMdClose size="20px" className="relative cursor-pointer font-bold tex-sm text-[red] mx-2" onClick={close} />
                        </div>

                        <div className="flex mt-4 w-full overflow-auto">
                            {children}
                        </div>
                    </motion.div>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default Dialog