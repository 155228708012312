import React, { useEffect, useState } from "react";
import homeScreen from "../assets/Icons/homeScreen.png";

import { motion } from "framer-motion";

import CreatePost from "./Post/CreatePost";
import API from "../axios/API";
import Dialog from "../Components/Dialog";
import ViewPost from "./Post/ViewPost";
import { useDispatch, useSelector } from "react-redux";
import { setLoadAllPost, setCountryList } from "../store/auth/actions";
import ViewPostLoader from "../Loaders/ViewpostLoader";


const HomePage = () => {

  const [openCreatePost, setOpenCreatePost] = useState(false);
  const [feedLists, setFeedLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const loadAllPostCount = useSelector(state => state.auth.loadAllPost)
  const dispatch = useDispatch()

  useEffect(() => {
    API.get('/menu/countries').then((conResp) => {
      dispatch(setCountryList(conResp.data.body.map((c) => ({ text: c.name, value: c.code }))))
    })
    API.get("/post/getAllPost").then((res) => {
      setLoading(true)
      if (res.status === 200) {
        let postDetails = res.data.body
        // let sortedPost = postDetails.sort((a, b) => {
        //   const dateA = new Date(a.postDetailsCreatedAt);
        //   const dateB = new Date(b.postDetailsCreatedAt);
        //   return dateB - dateA; // Sort in descending order (latest first)
        // });
        setFeedLists(postDetails);
        // if (postDetails.length === sortedPost.length) {
        // }
        setLoading(false);
      }
    });
  }, [loadAllPostCount]);


  const loadPost = (value) => {
    dispatch(setLoadAllPost(loadAllPostCount + 1))
    setOpenCreatePost(value)
  }

  return (
    <motion.div
      className={`relative min-h-full bg-[lightGrey] text-black ${!loading && feedLists && feedLists.length === 0 ? 'flex flex-col text-center justify-center items-center' : ''} overflow-auto mt-2 w-full mb-10`}>
      {
        loading ? <ViewPostLoader /> : <>
          {feedLists && feedLists.length > 0 ? feedLists.map((c, i) => (

            <ViewPost details={c} />

          )) : <>
            <img src={homeScreen} className="h-96 w-96" />
            <motion.div className="text-5xl font-bold text-black">
              There is no post to view. Please upload your first post to the feed.
            </motion.div>
          </>}
        </>
      }
      <Dialog
        injectClass={"w-[852px]"}
        title={"Add Post"}
        showDialog={openCreatePost}
        onClose={() => setOpenCreatePost(false)}
      >
        <CreatePost onClose={(ev) => loadPost(ev)} />
      </Dialog>
    </motion.div>
  );
};

export default HomePage