import React from 'react';
import { motion } from 'framer-motion'

const ViewPostLoader = () => {
    return (
        [1,2,3,4,5].map((c)=>{
            return <motion.div className="lg:mx-4 mx-1 rounded-lg bg-white animate-pulse my-3">
            <div className="grid grid-cols-2 mx-1 lg:mx-4 rounded-lg  relative">
                {/*================== POST TOP SECTION ===============*/}
                <div className="col-span-2 lg:col-span-1   py-4 rounded-lg">
                    {/* <<<POST {PROFILE} */}
                    <motion.div className="relative flex flex-1 px-3 gap-3 flex-column justify-start">
                        <motion.div className="postedUser">
                            <motion.div className="rounded-lg bg-slate-400  h-8 w-8"></motion.div>
                        </motion.div>
                        <motion.div className='m-2'>
                            <motion.div className="h-2 w-full bg-slate-400  rounded">
                            </motion.div>
                            <motion.div className="mt-2 text-[grey] flex font-md flex-row text-[9px] lg:text-[14px] justify-start">
                                <span className="h-2 w-2 bg-slate-400  rounded"></span>

                                {/* <span className="mr-1 ml-1">10.3 M views</span> */}
                                <span className="h-2 w-2 bg-slate-400  rounded mr-1 ml-1"></span>

                                <span className="h-2 w-2 bg-slate-400  rounded ml-1"></span>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                    {/* POST {PROFILE}>>> */}

                    {/* <<< POST{ POST-PIC & INTERACTION} */}
                    <motion.div className="mt-2 justify-center  relative   ">
                        <motion.div className="relative ">
                            <div className="px-10 lg:px-20 py-6  overflow-hidden">
                                <motion.div className="h-[120px] md:h-[240px] lg:h-[200px] bg-slate-400  rounded"></motion.div>
                            </div>

                            <br />

                            <motion.div className="flex flex-row justify-end mx-14">
                                <motion.div className="relative h-4  w-4 rounded-full bg-slate-400  relative mx-2 cursor-pointer"></motion.div>

                                <motion.div
                                    className="h-4  w-4  rounded-full bg-slate-400 relative mx-2"
                                ></motion.div>

                                <motion.div
                                    className="h-4  w-4 rounded-full bg-slate-400  relative mx-2"></motion.div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                    {/* ---POST {POST-PIC & INTERACTION} >>> */}
                </div>

                {/* ================THOUGHT SECTION================= */}
                <div className="col-span-2 lg:col-span-1 lg:mx-6">
                    <motion.div className="relative  w-full flex">
                        {/* Thoughts section */}
                        <motion.div className="py-2 w-full rounded-lg">
                            <div
                                className="bg-white  p-3 rounded-lg "
                            // style={{ boxShadow: " 1px 1px 4px -1px rgba(101,101,101,0.75)" }}
                            >
                                <>
                                    <motion.div className="heading ">
                                        <motion.div className="flex flex-row justify-start">
                                            <motion.div className="h-2 w-32 bg-slate-400  rounded mr-1">
                                            </motion.div>
                                            <motion.div className="h-2 w-16 bg-slate-400  rounded ml-1">
                                            </motion.div>
                                        </motion.div>
                                    </motion.div>
                                    <hr className="mt-2 h-2" />
                                </>
                                {/* {<<<COMMENT} */}
                                <><div className=" h-32 bg-slate-400  rounded w-full flex justify-center text-gray-400 text-sm"></div></>
                            </div>
                            <motion.div className="comment mt-2 relative Section overflow-hidden shadow-inner px-2 lg:px-0">
                                <motion.div className="input rounded-lg flex flex-row justify-between ">
                                    <motion.div className="relative bottom-0 inset-x-0 rounded-lg bg-slate-400  h-10 lg:h-24 max-h-53 w-full px-5 pr-16"></motion.div>
                                    <motion.div className="absolute flex right-10 top-2 lg:top-10 px-2 text-2xl items-center">
                                        <motion.div className="items-center cursor-pointer rounded-full h-2 bg-slate-400 "></motion.div>
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </motion.div>
        })
    )
}

export default ViewPostLoader