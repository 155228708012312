import React from 'react';
import { motion } from 'framer-motion'
import logo from '../assets/minlogo.jpeg'

const Loaders = () => {
    return (
        <motion.div className="relative flex flex-col justify-center items-center text-center align-center min-h-screen">
            <motion.div animate={{ y: [-10, 0, -10], transition: { yoyo: Infinity } }}>
                <img src={logo} height={100} width={100} />
            </motion.div>
        </motion.div>
    )
}

export default Loaders