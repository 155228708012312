import React,{useEffect,useState} from "react"
import { motion } from 'framer-motion'
import { FaPen } from "react-icons/fa"
import defEdu from '../../../assets/Icons/defEdu.png'
import API from '../../../axios/API'
import { useHistory } from "react-router-dom"

const Education = ({edu,edit}) => {

    const history =  useHistory()
    const [countries,setCountries] = useState([])

    useEffect(()=>{
        API.get('/menu/countries').then((countryResp)=>{
            setCountries(countryResp.data.body.map((c) => ({ text: c.name, value: c.code })))
        })
    },[])

    return (
        <motion.div className={`relative w-full ${edu === null || edu === 'null' && 'h-72'} text-black flex flex-col md:drop-shadow-xl md:rounded-3xl bg-white mt-2 md:px-2 md:py-4`}>
            <motion.div className={`flex flex-row justify-between md:m-4 m-3`}>
                <motion.div className={`text-xl font-bold`}>
                    Education
                </motion.div>
                {edit && <motion.div className={`relative cursor-pointer rounded-full backdrop-blur-sm bg-[#2593D2]/30`} style={{ height: '30px', width: '30px' }} onClick={()=>history.push("/editProfile")}>
                    <FaPen className="text-white text-sm m-[8px]" />
                </motion.div>}
            </motion.div>
            <>
                {
                    edu ?  edu.educations.map((c) => {
                       return <>
                            {
                                c.isActive ?  <motion.div className={`relative flex flex-col px-5 md:px-10 mt-2 mb-2`}>
                                <motion.div className={`flex flex-row justify-start gap-5`}>
                                    <motion.div className={`bg-white drop-shadow-lg rounded-xl h-fit`}>
                                        <img alt="" src={c && c.schoolLogo ? c.schoolLogo : defEdu} height={50} width={50} />
                                    </motion.div>
                                    <motion.div className={`flex flex-row justify-between w-full`}>
                                        <motion.div className={`flex flex-col`}>
                                            <motion.div className={`text-black text-md font-bold`}>
                                                {c.schoolName}
                                            </motion.div>
                                            <motion.div className={`text-sm text-[grey]`}>
                                            {countries && countries.length && countries.map((e)=>{if(e.value === c.schoolLocation)return e.text})}
                                            </motion.div>
                                            <motion.div className={`text-black text-sm font-bold`}>
                                                {c.degree}
                                            </motion.div>
                                        </motion.div>
                                        <motion.div className={`flex flex-col items-center justify-center`}>
                                            {c.fromMonth} {c.fromYear}  - {c.isPresent ? 'Present' : `${c.toMonth} ${c.toYear}`}
                                        </motion.div>
                                    </motion.div>
                                </motion.div>
                                <motion.div className="h-[1px] bg-[grey] w-full mt-2"></motion.div>
                            </motion.div> : ""
                            }  
                        </>
                    }) : edit ? <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">Add Education</motion.div> : <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">No Education</motion.div>
                }
            </>
        </motion.div>
    )
}

export default Education