import { SET_ALERT,SET_NOTIFICATION_ALERT,SET_OPENSIDEBAR } from './constants'
import themeStore from './stores'

const themeReducers = (state=themeStore,action) =>{
    switch (action.type){
        case SET_ALERT:
            return{...state,alert: action.payload}
        case SET_NOTIFICATION_ALERT:
            return{...state,notificationAlert: action.payload}
        case SET_OPENSIDEBAR : 
            return {...state,sidebar:action.payload}
        default:
            return state
    }
}

export default themeReducers