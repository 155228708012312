import React, { useState } from 'react';
import Toolbar from "../Components/Toolbar"
import HomePage from "../Modules/Home"
import { motion } from 'framer-motion'
import Profile from "../Modules/Profile/Profile"
import { combinedRoutes } from '../routes'
import { Switch, Route, withRouter } from "react-router-dom"
import TopBar from "../Components/TopBar"
import { useEffect } from "react"
import API from "../axios/API"
import { setFollowDetail, setOpenNotificationBox, setScreenName, setUserDetails } from "../store/auth/actions"
import { useDispatch, useSelector } from "react-redux"
import Notification from '../Modules/Notification';

const HomeRoot = () => {

    const dispatch = useDispatch()
    const screenName = useSelector(state => state.auth.screenName)
    const callUserApi = useSelector(state => state.auth.callUserApi)
    const openNotificationBox = useSelector(state => state.auth.openNotificationBox)
    const [screenList, setScreenList] = useState([
        { screenName: 'Home', link: 'home' },
        { screenName: 'Video', link: 'video' },
        { screenName: 'Shop', link: 'shop' },
        { screenName: 'notify' },
        { screenName: 'Jobs', link: 'jobs' },
        { screenName: 'extras' }])

    useEffect(() => {
        screenList.map((c) => {
            if (window.location.href.split('/').includes(c.link)) {
                dispatch(setScreenName(c.screenName))
            } else if (window.location.href.split('/')[3] === '') {
                dispatch(setScreenName('Home'))
            }
        })
        API.get('/user/getUserInfo').then((res) => {
            if (res.data.status === 200) {
                dispatch(setUserDetails(res.data.data.userDetail))
                dispatch(setFollowDetail(JSON.parse(res.data.data.followDetail)))
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [callUserApi])

    // useEffect(()=>{
    //     if(openNotificationBox){
    //         document.addEventListener('click',dispatch(setOpenNotificationBox(!openNotificationBox)))
    //     }
    // },[])

    return (
        <>
            {/* desktop */}
            <motion.div className="md:block hidden  bg-[lightGrey]">
                <motion.div className="absolute h-full w-full text-white flex justify-start">
                    <Toolbar />
                    <motion.div className="w-full flex flex-col h-full">
                        {
                            screenName && screenName !== 'Profile' && <motion.div className="w-full text-black ion-toolbar">
                                <TopBar />
                            </motion.div>
                        }
                        <motion.div className={`text-left h-full relative ${!openNotificationBox ? 'overflow-auto' : 'overflow-hidden'} bg-[lightGrey] text-black dark:text-white`}>
                            {openNotificationBox && <motion.div className="w-[450px] shadow-lg min-h-screen overflow-auto absolute bg-[white] text-black z-10"><Notification /></motion.div>}
                            <Switch>
                                {
                                    combinedRoutes.map(c => (
                                        <Route key={c.link} path={c.link} exact component={c.component} />
                                    ))
                                }
                            </Switch>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>
            {/* Mobile */}
            <motion.div className="block md:hidden relative ion-toolbar">
                <motion.div className="w-full flex flex-col h-full bg-[white] z-10">
                    {
                        screenName && screenName !== 'Profile' && <motion.div className="w-full text-black">
                            <TopBar />
                        </motion.div>
                    }
                    <motion.div className="m-1 text-left h-full overflow-auto bg-white text-black dark:text-white">
                        <Switch>
                            {
                                combinedRoutes.map(c => (
                                    <Route key={c.link} path={c.link} exact component={c.component} />
                                ))
                            }
                        </Switch>
                    </motion.div>
                </motion.div>
                <motion.div className="w-full fixed inset-x-0 bottom-0 bg-[#40444B] ion-footer">
                    <Toolbar />
                </motion.div>
            </motion.div>
        </>

    )
}

export default withRouter(HomeRoot)