import React from "react"
import {motion} from 'framer-motion'
import { FaPen } from "react-icons/fa"
import { useHistory } from "react-router-dom"

const projects = [
    {projectName:'Automated Farm',projectDescription:'You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more. For example, use md:overscroll-contain to apply the overscroll-contain utility at only medium screen sizes and above.'},
    {projectName:'Fishing Drone',projectDescription:`Sure, go ahead, laugh if you want to. I've seen your type before: Flashy, making the scene, flaunting convention. Yeah, I know what you're thinking. What's this guy`}
]

const Projects = ({project,edit}) =>{

    const history =  useHistory()
    return (
        <motion.div className={`relative w-full ${project === null || project === 'null' && 'h-72'} text-black flex flex-col md:drop-shadow-xl md:rounded-3xl bg-white md:px-2 md:py-4`}>
            <motion.div className={`flex flex-row justify-between md:m-4 m-3`}>
                <motion.div className={`text-xl font-bold`}>
                   Projects
                </motion.div>
                {edit && <motion.div className={`relative cursor-pointer rounded-full backdrop-blur-sm bg-[#2593D2]/30`} style={{ height: '30px', width: '30px' }} onClick={()=>history.push("/editProfile")}>
                    <FaPen className="text-white text-sm m-[8px]" />
                </motion.div>}
            </motion.div>
            <>
            {
                project ? project.projects.map((c)=>{
                    return <>
                        {
                            c.isActive ? <motion.div className="flex flex-col gap-5 px-5 md:px-10">
                            <motion.div className="flex flex-col">
                                <motion.div className="text-black font-bold text-sm">
                                    {c.projectTitle}
                                </motion.div>
                                <motion.div className="text-black text-xs overflow-auto h-24">
                                    {c.projectDescription}
                                </motion.div>
                            </motion.div>
                            <motion.div className="h-[1px] bg-[grey] w-full mt-2 mb-5"></motion.div>
                        </motion.div> : ''
                        }
                    </>
                }) : edit ? <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">Add Projects</motion.div> : <motion.div className="flex flex-col h-full text-xl font-bold text-center justify-center items-center align-center">No Projects</motion.div>
            }
            </>
        </motion.div>
    )
}

export default Projects