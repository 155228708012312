import { GiCheckMark } from 'react-icons/gi'
import { RiArrowDownSLine } from 'react-icons/ri'
import { AnimatePresence, motion } from 'framer-motion'
// import { errorAnim } from '../commons/anims'
import { useEffect, useMemo, useRef, useState } from 'react'
// import FlowText from './FlowText'
import { createPortal } from "react-dom"
import { useHistory } from 'react-router-dom'

const SelectOptionAnim = {
    hidden: {
        height: 0,
        opacity: 0
    },
    visible: {
        height: 'auto',
        opacity: 1,
        zIndex: 10000,
    }
}

const FieldSelect = ({valuePadding=true, fieldProps, fieldMeta, label, edit, options,labelSide='up', bgColor,injectClass, fieldHelper, position = 'bottom', loading = false, dataLoaded = false, onChange, disabled = false, heading,title,labelLength,radioOption = false,arrowEnd=false,fullWidth=false }) => {

    const input = useRef(null)
    const wrapRef = useRef(null)
    const [showError, setShowError] = useState(false)
    const [filter, setFilter] = useState('')
    const [focussed, setFocussed] = useState(false)
    const [pos, setPos] = useState({ width: 0, top: 0, left: 0 })
    const history = useHistory()

    const makeFocus = () => {
        if (input.current !== null)
            input.current.focus()
    }
    const toggleFocus = () => {
        if (focussed) {
            setFilter('')
            if (input.current !== null) input.current.blur()
        }
        setFocussed(!focussed)
        document.body.removeEventListener('click', toggleFocus)
    }
    const handleBlur = () => {
        setTimeout(() => {
            if (focussed) toggleFocus()
        }, 200)
    }

    useEffect(() => {
        if (focussed) {
            document.body.addEventListener('click', toggleFocus)
            let e = wrapRef.current.getBoundingClientRect()
            let o = { width: `${e.width}px`, left: `${e.x}px` }
            if ((e.top + e.height + 270) > window.innerHeight) {
                o.bottom = `${window.innerHeight - e.top}px`
            } else {
                o.top = `${(e.top + e.height)}px`
            }
            setPos(o)
        }

        return () => document.body.removeEventListener('click', toggleFocus)
    }, [focussed]) // eslint-disable-line

    const toggleError = (val) => setShowError(val)
    const setValue = (value) => {
        fieldHelper.setValue(value)
        if (typeof onChange === 'function') onChange(value)
    }
    const filteredOptions = useMemo(() => {
        return options.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim())))
    }, [filter, options]) // eslint-disable-line
    const displayText = useMemo(() => {
        let a = options.filter(c => c.value === fieldProps.value)
        return a.length > 0 ? a[0].text : false
    }, [fieldProps.value, options])// eslint-disable-line

    const [select, setSelect] = useState(false)

    return (
        // <div className="relative mt-2 mb-3">
        //     <label className={'mt-2 mb-2 text-formLabel-small-black'}>{label}</label>
        //     <div ref={wrapRef} className={`group relative flex flex-col ${disabled ? 'bg-lightGrey' : 'bg-transparent'} ${edit ? 'ring-1 ring-gray-300 dark:ring-gray-600' : ''} mt-2 mb-2 px-2 pt-1 rounded-lg ${focussed ? ' focus-within:ring-tcolor dark:focus-within:ring-tcolor' : ''} transition-all duration-100 cursor-pointer ${loading ? 'animate-pulse' : ''}`} onClick={makeFocus}>
        //         <div className="relative p-1">
        //             {!edit && dataLoaded &&
        //                 <div className={`pb-2 h-8 ${fieldProps.value ? '' : 'text-gray-500 line-through'}`}>{displayText || ' '}</div>
        //             }
        //             {
        //                 !dataLoaded &&
        //                 <div className="w-3/4 h-2 my-2 rounded-lg animate-pulse bg-gray-300"></div>
        //             }
        //             {
        //                 edit && dataLoaded &&
        //                 <>
        //                     <div className={`pb-3  pl-3 min-h-8 w-[96%] ${fieldProps.value ? '' : 'text-formValue-small-black'} ${focussed ? 'hidden' : ''}`}>{displayText || `Select`}</div>
        //                     <input ref={input} disabled={disabled} className={`pl-3 bg-transparent pr-10 w-full outline-none ${!focussed ? 'h-0 w-0 absolute' : ''}`} type="text" value={filter} autoComplete="off" onFocus={toggleFocus} onBlur={handleBlur} onChange={ev => setFilter(ev.target.value)} />
        //                 </>
        //             }
        //             {edit &&
        //                 <span className="absolute right-2 top-1 text-xl flex">
        //                     {fieldMeta.error && fieldMeta.touched && <FaExclamationTriangle className="text-red-500 cursor-pointer" onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} />}
        //                     <HiSelector className="text-gray-400 ml-2" />
        //                 </span>
        //             }
        //             <AnimatePresence>
        //                 {fieldMeta.error && fieldMeta.touched && showError &&
        //                     <motion.span variants={errorAnim} initial="hidden" animate="visible" exit="hidden" className="absolute -top-1 right-16 inline-block bg-gray-300 dark:bg-ldark py-0.5 px-2 rounded text-sm text-red-500">
        //                         {fieldMeta.error}
        //                     </motion.span>
        //                 }
        //             </AnimatePresence>
        //         </div>
        //     </div>
        //     {
        //         createPortal(
        //             <AnimatePresence>
        //                 {
        //                     focussed &&
        //                     <motion.div variants={SelectOptionAnim} initial="hidden" animate="visible" exit="hidden" className={`fixed overflow-y-auto max-h-[270px] bg-lightGrey dark:bg-mdark ring-1 ring-bluegray-300 dark:ring-ldark p-2 rounded`} style={pos}>
        //                         {label && <span className="inline-block mb-2 text-xs text-tcolor dark:text-gray-400">{label}</span>}
        //                         {heading && <span className="inline-block mb-2 text-xs text-tcolor dark:text-gray-400">{heading}</span>}
        //                         {
        //                             filteredOptions.map((c, i) => (
        //                                 <div key={i} onClick={() => setValue(c.value)} className="py-2 px-3 hover:bg-tcolor hover:text-white rounded-lg flex justify-between items-center text-formValue-small-black dark:text-gray-300 dark:hover:text-white">
        //                                     {c.text}
        //                                     {c.value === fieldProps.value && <GiCheckMark className="text-green-500" />}
        //                                 </div>
        //                             ))
        //                         }
        //                         {filteredOptions.length === 0 && <span className="block px-5 pb-2 text-gray-500"> </span>}
        //                     </motion.div>
        //                 }
        //             </AnimatePresence>, document.body)
        //     }
        // </div>
        <motion.div className={`mt-2 mb-3`}>
            <motion.div className={`relative w-full flex ${labelSide === 'side' ? 'flex-row justify-start gap-5' : 'flex-col'}`}>
                {
                    label &&
                    <motion.div className="flex flex-row  justify-between">
                        <label style={{ width: labelLength ? `${labelLength}px` : 'auto' }} className={`${labelSide === 'side' ? 'mt-4' : 'mt-2'} mb-2`}>{label}</label>
                    </motion.div>
                }
                <div ref={wrapRef} className={` ${injectClass} group relative flex flex-col ${disabled ? 'bg-[lightGrey]' : bgColor ? bgColor : 'bg-transparent'}  ${edit ? 'ring-1 ring-gray-300 dark:ring-gray-600' : ''} mt-2 mb-2 px-2 pt-1 rounded-lg ${focussed ? ' focus-within:ring-black dark:focus-within:ring-tcolor' : ''} transition-all duration-100 cursor-pointer ${loading ? 'animate-pulse' : ''}`} onClick={makeFocus}>
                    <div className="relative p-1">
                        {!edit && dataLoaded &&
                            <div className={`pb-2 h-8 ${fieldProps.value ? '' : 'text-gray-500 line-through'}`}>{displayText || ' '}</div>
                        }
                        {
                            !dataLoaded &&
                            <div className="w-3/4 h-2 my-2 rounded-lg animate-pulse bg-gray-300"></div>
                        }
                        {
                            edit && dataLoaded &&
                            <>
                                <div className={`${valuePadding && 'pb-3 pl-3' } overflow-hidden text-start h-7 ${title || label ||fullWidth ? 'w-full' : 'w-12'}  ${focussed ? 'hidden' : ''}`}>{displayText || `${label || title ? `Select ${label ? label : title ? title : ''}` : ''}`}</div>
                                <input ref={input} disabled={disabled} className={`pl-3 bg-transparent pr-10 w-full outline-none ${!focussed ? 'h-0 w-0 absolute' : 'h-7'}`} type="text" value={filter} autoComplete="off" onFocus={toggleFocus} onBlur={handleBlur} onChange={ev => setFilter(ev.target.value)} />
                            </>
                        }
                        {edit &&
                            <span className={`${arrowEnd ? 'right-2' : 'right-5'} absolute  top-1 text-xl flex`}>
                                <RiArrowDownSLine className="text-gray-400" />
                            </span>
                        }
                    </div>
                </div>
                {
                    createPortal(
                        <AnimatePresence>
                            {
                                focussed &&
                                <motion.div variants={SelectOptionAnim} initial="hidden" animate="visible" exit="hidden" className={`fixed overflow-y-auto max-h-[270px] bg-white dark:bg-mdark ring-1 ring-lightGrey dark:ring-ldark p-2 rounded`} style={pos}>
                                    {
                                        filteredOptions.map((c, i) => (
                                            <div key={i} onClick={() => setValue(c.value)} className={`py-2 px-3 hover:bg-[#D0D0D1] ${c.value === fieldProps.value ? 'bg-[#2593D2] text-white' : 'bg-white text-black'} hover:text-black rounded-lg flex justify-between items-center`}>
                                                {c.text}
                                                {/* {c.value === fieldProps.value && <GiCheckMark className="text-green" />} */}
                                            </div>
                                        ))
                                    }
                                    {filteredOptions.length === 0 && <span className="block px-5 pb-2 text-gray-500"> </span>}
                                </motion.div>
                            }
                        </AnimatePresence>, document.body)
                }
                <div className="relative flex flex-row justify-start">
                    {
                        edit && fieldMeta.error && fieldMeta.touched && <p style={{ color: 'red' }}>{fieldMeta.error}</p>
                    }
                </div>
                {
                    radioOption && labelSide === 'side'&&
                    < motion.div className="relative p-2 mt-5 ring-1 ring-black rounded-full w-[2px] h-[2px] flex flex-col" onClick={() => setSelect(!select)} >
                    <>
                        {
                            select ? <motion.div className="absolute top-1 right-1 ring ring-1 ring-black rounded-full bg-[red] p-1 w-[1px] h-[1px]"></motion.div> : <motion.div className="absolute ring ring-1 top-1 right-1  ring-black rounded-full bg-black p-1 w-[1px] h-[1px]"></motion.div>
                        }
                    </>
                    </motion.div>
                }
            </motion.div>
        </motion.div>
    )
}

export default FieldSelect