const authStore = {
    isLoggedIn: true,
    userDetails :{},
    token:{},
    user:{},
    profileMode:'profile',
    screenName: '',
    profileEditMode:false,
    callUserApi:false,
    followDetail:{},
    loadAllPost:0,
    countryList: [],
    openNotificationBox:false,
}

export default authStore