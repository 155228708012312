import { motion } from "framer-motion"

const Button =({injectClass,onClick,loading,children,disabled})=>{
    return (
            <button type="button" disabled={disabled} className={` bg-[#2593D2] text-sm px-2.5 py-2 ${injectClass} text-white rounded-lg`} onClick={ () => onClick ? onClick() : null }>
                {children}
            </button>
    )
}

export default Button