const themeStore = {
    alert: {title: '', subtitle: '', active: false, type: 'error'},
    notificationAlert: {title:'',subtitle: '', active: false, type: 'post',imageUrl:''},
    loading: false,
    sidebar:false,
    // topBar: true,
    // companyTopBar: false,
    // communityTopBar:false,
    // pageTitle:''
}
export default themeStore