import { motion } from 'framer-motion'


const NotificationLoader = () => {
    return (
        <>
            {
                [1,2,3,4,5,6,7,8,9,10].map((c)=>{
                    return <motion.div className="border-blue-300 mt-2 mb-2 rounded-lg shadow rounded-md p-2 max-w-sm w-full mx-auto">
            <motion.div className="animate-pulse flex space-x-4">
                <motion.div className="rounded-full bg-slate-700 h-10 w-10"></motion.div>
                <motion.div className="flex-1 space-y-6 py-1">
                    <motion.div className="h-2 bg-slate-700 rounded"></motion.div>
                    <motion.div className="space-y-3">
                        <motion.div className="grid grid-cols-3 gap-4">
                            <motion.div className="h-2 bg-slate-700 rounded col-span-2"></motion.div>
                            <motion.div className="h-2 bg-slate-700 rounded col-span-1"></motion.div>
                        </motion.div>
                        <motion.div className="h-2 bg-slate-700 rounded"></motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>
        </motion.div>
                })
            }
        </>
    )
}

export default NotificationLoader