import React,{useEffect,useState} from 'react';
import API from '../../axios/API';
import ViewPostLoader from '../../Loaders/ViewpostLoader';
import ViewPost from './ViewPost';


const SinglePost = ({match})=>{

    const [details,setDetails] = useState()
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
        API.get(`/post/getPostById/${match.params.postId}`).then((res)=>{
            if(res.status === 200){
                setDetails(res.data.body)
                setLoading(false)
            }
        })
    },[])

    return(
        <>
            {
                loading ? <ViewPostLoader/> : <ViewPost details={details}/>
            }
        </>
    )
}

export default SinglePost