import React, { useLayoutEffect, useState } from "react"
import { motion } from 'framer-motion'
import blueLogin from '../../assets/bluelogin.png'
import greenLogin from '../../assets/greenlogin.png'
import logo from '../../assets/minlogo.jpeg'
import greenLogo from '../../assets/greenLogo.jpeg'
import * as Yup from 'yup'
import { useFormik } from "formik"
import FieldInput from '../../Components/FieldInput'
import Button from "../../Components/Button"
import { FaPhoneAlt, FaFacebook, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { useHistory } from "react-router-dom"
import { FcGoogle } from "react-icons/fc"
import API from '../../axios/API'
import { setToken, setLoggedIn } from "../../store/auth/actions"
import { setAlert } from "../../store/theme/actions"
import { useDispatch } from "react-redux"
import { LoginSocialGoogle } from 'reactjs-social-login'
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth"

import { Plugins } from "@capacitor/core"
import axios from "axios"

const initialValues = {
  userId: '',
  password: '',
}

const Login = () => {

  const [blueTheme, setBlueTheme] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()
  const [mobileDevice, setMobileDevice] = useState(false)

  const loginFormSchema = Yup.object().shape({
    userId: Yup.string().required("Mobile number is required"),
    password: Yup.string().required("Password is required")
  })

  const loginForm = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: loginFormSchema,
    onSubmit: (values) => {
      const obj = values
      obj.userId = values.userId.replace(/\s/g, '')
      API.post('/auth/login', (values)).then((res) => {
        if (res.data.status === 200) {
          dispatch(setToken(res.data.data.body))
          dispatch(setAlert({ type: 'success', title: 'Success', subtitle: "Logged In Successfully", active: true }))
          dispatch(setLoggedIn(true))
          localStorage.setItem('token', res.data.data.body)
          history.push('/')
        } else {
          dispatch(setAlert({ type: 'error', title: 'Error', subtitle: res.data.data.message, active: true }))
        }
      })
    }
  })


  useLayoutEffect(() => {
    setMobileDevice(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
    // if (!isPlatform('capacitor')) {
    //   GoogleAuth.init();
    // }
    setTimeout(() => {
      setBlueTheme(!blueTheme)
    }, 5000)
  }, [blueTheme])


  const responseSuccessGoogle = (response) => {
    console.log(response)
    API.post('/auth/googleLogin', response).then(res => {
      if (res.data.status === 200) {
        dispatch(setAlert({ type: 'success', title: 'Success', subtitle: "Logged In Successfully", active: true }))
        dispatch(setToken(res.data.data.body))
        dispatch(setLoggedIn(true))
        localStorage.setItem('token', res.data.data.body)
        history.push('/')
      } else if (res.data.status === 500) {
        dispatch(setAlert({ type: 'error', title: 'Error', subtitle: "Account Doesn't Exist", active: true }))
      }
    })
  }

  const openSocialLink = (link) => {
    window.open(link, 'blank')
  }
  const isMobile = window.innerWidth < 768;

  const handleSignIn = async () => {
    const result = await GoogleAuth.signIn();
    let details = {
      email: result.email,
      email_verified: true
    }
    API.post('/auth/googleLogin', (details)).then(res => {
      if (res.data.status === 200) {
        dispatch(setAlert({ type: 'success', title: 'Success', subtitle: "Logged In Successfully", active: true }))
        dispatch(setToken(res.data.data.body))
        dispatch(setLoggedIn(true))
        localStorage.setItem('token', res.data.data.body)
        history.push('/')
      } else if (res.data.status === 500) {
        dispatch(setAlert({ type: 'error', title: 'Error', subtitle: "Account Doesn't Exist", active: true }))
      }
    }).catch(error => {
      console.log("rajError", JSON.stringify(error))
    })
  }

  return (
    <>

      {/* <ImSpinner10 className="relative animate-spin"/> */}
      {/* system */}
      <motion.div className="relative overflow-hidden hidden md:block">
        <motion.div className="fixed inset-y-0 right-0 bottom-0 top-0 w-1/3 md:block hidden">
          {blueTheme && <svg xmlns="http://www.w3.org/2000/svg" width="769.984" height="1079.998" viewBox="0 0 769.984 1079.998">
            <path id="Subtraction_2" data-name="Subtraction 2" d="M20562.986,24788H19872.65c-.068-.059-7.012-6.117-16.77-18.135a252.4,252.4,0,0,1-16.332-22.625,279.523,279.523,0,0,1-17.512-31.625,302.43,302.43,0,0,1-26.213-90.137c-1.209-9.209-2.039-18.668-2.467-28.115-.451-9.943-.473-20.152-.059-30.342.434-10.686,1.354-21.641,2.734-32.561,1.445-11.432,3.436-23.135,5.912-34.781,2.324-10.93,5.18-21.91,8.484-32.635,3.152-10.234,6.82-20.582,10.9-30.76a466.668,466.668,0,0,1,27.648-56.928c9.219-16.26,20.043-33.441,33.09-52.525,11.605-16.977,23.83-33.82,35.652-50.107l.059-.078c13.363-18.408,27.18-37.441,40.047-56.607,6.924-10.316,13.023-19.836,18.646-29.105,6.309-10.4,11.883-20.26,17.045-30.141,5.529-10.592,10.551-21.207,14.93-31.551,4.684-11.064,8.824-22.279,12.307-33.332a345.584,345.584,0,0,0,14.682-73.516,300.554,300.554,0,0,0-17.977-132.623c-12.771-34.287-31.877-67.527-56.787-98.795a434.08,434.08,0,0,0-31.855-35.686c-9.068-9.1-18.572-17.852-28.246-26a307.787,307.787,0,0,0-27.922-21.289h690.338v1080h0v0h0Z" transform="translate(-19793.004 -23708)" fill="#2593d2" />
          </svg>}
          {
            !blueTheme && <svg xmlns="http://www.w3.org/2000/svg" width="769.984" height="1079.998" viewBox="0 0 769.984 1079.998">
              <path id="Subtraction_2" data-name="Subtraction 2" d="M20562.986,24788H19872.65c-.068-.059-7.012-6.117-16.77-18.135a252.4,252.4,0,0,1-16.332-22.625,279.523,279.523,0,0,1-17.512-31.625,302.43,302.43,0,0,1-26.213-90.137c-1.209-9.209-2.039-18.668-2.467-28.115-.451-9.943-.473-20.152-.059-30.342.434-10.686,1.354-21.641,2.734-32.561,1.445-11.432,3.436-23.135,5.912-34.781,2.324-10.93,5.18-21.91,8.484-32.635,3.152-10.234,6.82-20.582,10.9-30.76a466.668,466.668,0,0,1,27.648-56.928c9.219-16.26,20.043-33.441,33.09-52.525,11.605-16.977,23.83-33.82,35.652-50.107l.059-.078c13.363-18.408,27.18-37.441,40.047-56.607,6.924-10.316,13.023-19.836,18.646-29.105,6.309-10.4,11.883-20.26,17.045-30.141,5.529-10.592,10.551-21.207,14.93-31.551,4.684-11.064,8.824-22.279,12.307-33.332a345.584,345.584,0,0,0,14.682-73.516,300.554,300.554,0,0,0-17.977-132.623c-12.771-34.287-31.877-67.527-56.787-98.795a434.08,434.08,0,0,0-31.855-35.686c-9.068-9.1-18.572-17.852-28.246-26a307.787,307.787,0,0,0-27.922-21.289h690.338v1080h0v0h0Z" transform="translate(-19793.004 -23708)" fill="#25d181" />
            </svg>
          }
        </motion.div>
        <motion.div className="min-h-screen text-center items-center justify-center flex flex-col overflow-hidden">
          <motion.div className={"drop-shadow-lg rounded-3xl bg-white w-2/3"}>
            <motion.div className={'grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2'}>
              <motion.div className={`${blueTheme ? 'bg-gradient-to-b rounded-tl-3xl rounded-bl-3xl rounded-br-[50px] from-cyan-500 to-blue-500' : 'bg-gradient-to-b rounded-tl-3xl rounded-bl-3xl rounded-br-[50px] from-emerald-200 to-green-500'}`}>
                <motion.div className="text-white text-start text-2xl drop-shadow-lg">
                  {blueTheme ? <p className="px-4 py-4 bg-lightGrey">Aquaculture</p> : <p className="px-4 py-4 bg-lightGrey">Agriculture</p>}
                </motion.div>
                <motion.div className={'text-center items-center justify-center'}>
                  <img src={blueTheme ? blueLogin : greenLogin} className={`${blueTheme ? 'w-96 h-96' : 'h-96'}`} />
                </motion.div>
                <motion.div className="text-white text-sm drop-shadow-lg">
                  <p className="px-4 py-4">Read our <span className="underline cursor-pointer" onClick={() => history.push("/privacy-policy")}>privacy policy</span> and <span className="underline cursor-pointer" onClick={() => history.push('/terms-condition')}>Terms & Conditions</span> for more info</p>
                </motion.div>
              </motion.div>
              <motion.div className={`${blueTheme ? 'bg-gradient-to-b from-cyan-500 to-blue-500 rounded-tr-3xl rounded-br-3xl' : 'bg-gradient-to-b from-emerald-200 to-green-500 rounded-tr-3xl rounded-br-3xl'}`}>
                <motion.div className="h-full text-center items-center rounded-tl-[50px]  bg-white rounded-tr-3xl rounded-br-3xl justify-center flex flex-col">
                  <motion.div className="w-3/5 drop-shadow-lg rounded-lg bg-white p-4 mt-5 mb-5">
                    <motion.div className="text-center flex flex-col items-center justify-center">
                      <img src={blueTheme ? logo : greenLogo} style={{ width: '20%', height: '20%' }} />
                    </motion.div>
                    <motion.div className="mt-4 relative">
                      <FieldInput border={false} theme={blueTheme ? 'text-blue-500' : 'text-[#25D181]'} label={'Enter Mobile No or Email.'} placeholder={'Mobile or Email'} fieldProps={loginForm.getFieldProps('userId')} fieldMeta={loginForm.getFieldMeta('userId')} fieldHelper={loginForm.getFieldHelpers('userId')} edit={true} dataLoaded={true} />
                      <FieldInput border={false} theme={blueTheme ? 'text-blue-500' : 'text-[#25D181]'} label={'Password'} type={'password'} fieldProps={loginForm.getFieldProps('password')} fieldMeta={loginForm.getFieldMeta('password')} fieldHelper={loginForm.getFieldHelpers('password')} edit={true} dataLoaded={true} />
                      <Button injectClass={`${blueTheme ? 'w-full' : 'w-full bg-[#25D181]'}`} onClick={() => loginForm.submitForm()}>Sign In</Button>
                      <motion.div className="relative justify-center flex flex-col items-center text-center">
                        {!isMobile && (<motion.div className="justify-center mt-2 mb-3 sticky items-center text-center cursor-pointer">
                          <LoginSocialGoogle
                            client_id={process.env.REACT_APP_GG_APP_ID || ''}
                            scope="openid profile email"
                            discoveryDocs="claims_supported"
                            access_type="offline"
                            onResolve={({ provider, data }) => {
                              responseSuccessGoogle(data)
                            }}
                            onReject={err => {
                              console.log(err);
                            }}
                          >
                            <FcGoogle />
                          </LoginSocialGoogle>
                        </motion.div>)}
                        <motion.div className="text-center mt-2 justify-center flex flex-row align-center">
                          <p>Create new account</p>&nbsp;?&nbsp;<a className={`${blueTheme ? 'text-blue-500 cursor-pointer' : 'text-[#25D181] cursor-pointer'}`} onClick={() => history.push('/auth/signup')}>Sign up</a>
                        </motion.div>
                        <motion.div className="text-center text-[gray] cursor-pointer underline mt-2 justify-center flex flex-row align-center">
                          <a onClick={() => history.push('/auth/forgot_password')}>Forgot Password</a>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className="relative">
                    <motion.div className="flex flex-row justify-between mt-2 mb-3 sticky">
                      <motion.div className="px-6 py-4 text-xl relative">
                        <FaFacebook className={`${blueTheme ? 'text-blue-500' : 'text-[#25D181]'} cursor-pointer`} onClick={() => openSocialLink("https://www.facebook.com/profile.php?id=100091406623203")} />
                      </motion.div>
                      {/* <motion.div className="px-6 py-4 text-xl relative">
                      <FaInstagram className={`${blueTheme ? 'text-blue-500' : 'text-[#25D181]'} cursor-pointer`} />
                    </motion.div>
                    <motion.div className="px-6 py-4 text-xl relative">
                      <FaTwitter className={`${blueTheme ? 'text-blue-500' : 'text-[#25D181]'} cursor-pointer`} />
                    </motion.div> */}
                      <motion.div className="px-6 py-4 text-xl relative">
                        <FaLinkedinIn className={`${blueTheme ? 'text-blue-500' : 'text-[#25D181]'} cursor-pointer`} onClick={() => openSocialLink("https://www.linkedin.com/company/aqua-agri-face/?viewAsMember=true")} />
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.div className={'grid grid-cols-2 relative sm:grid-cols-1 lg:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 py-4 gap-4'}>
            <motion.div className="flex flex-row relative text-left items-left flex-1">
              <motion.div className="flex relative flex-row mt-2 mb-2">
                <FaPhoneAlt className={`${blueTheme ? 'text-blue-500 absolute mr-3 ml-2 mt-1 mb-1' : 'text-[#25D181] absolute mr-3 ml-2 mt-1 mb-1'}`} />&nbsp;<p className="text-[#9E9E9E] mr-2 ml-8">+254788872121</p>
              </motion.div>
              <motion.div className="flex relative flex-row mt-2 mb-2">
                <MdEmail className={`${blueTheme ? 'text-blue-500 absolute mr-3 ml-2 mt-1 mb-1' : 'text-[#25D181] absolute mr-3 ml-2 mt-1 mb-1'}`} />&nbsp;<p className="text-[#9E9E9E] mr-2 ml-8">info@aquaagriface.com</p>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
      {/* Mobile */}
      <motion.div className={`${blueTheme ? 'bg-gradient-to-b  from-cyan-500 to-blue-500' : 'bg-gradient-to-b  from-emerald-200 to-green-500'} relative h-screen overflow-hidden block md:hidden ion-toolbar`}>
        <motion.div className={`flex flex-col overflow-hidden md:hidden block ${blueTheme ? 'bg-gradient-to-b  from-cyan-500 to-blue-500' : 'bg-gradient-to-b  from-emerald-200 to-green-500'}`}>
          <motion.div className="flex flex-row justify-between">
            {blueTheme ? <p className="px-4 py-4 text-white">Aquaculture</p> : <p className="px-4 py-4 text-white">Agriculture</p>}
            <motion.div className={'text-center items-center justify-center'}>
              <img src={blueTheme ? blueLogin : greenLogin} className={`${blueTheme ? 'w-52' : 'w-64'} p-0`} />
            </motion.div>
          </motion.div>
          <motion.div className="h-full text-center items-center flex flex-col">
            <motion.div className="w-4/5 drop-shadow-lg rounded-3xl bg-white p-4 mb-5">
              <motion.div className="text-center flex flex-col items-center justify-center">
                <img src={blueTheme ? logo : greenLogo} style={{ width: '20%', height: '20%' }} />
              </motion.div>
              <motion.div className="mt-4 relative">
                <FieldInput border={false} theme={blueTheme ? 'text-blue-500' : 'text-[#25D181]'} label={'Enter Mobile No or Email.'} placeholder={'Mobile or Email'} fieldProps={loginForm.getFieldProps('userId')} fieldMeta={loginForm.getFieldMeta('userId')} fieldHelper={loginForm.getFieldHelpers('userId')} edit={true} dataLoaded={true} />
                <FieldInput border={false} theme={blueTheme ? 'text-blue-500' : 'text-[#25D181]'} label={'Password'} type={'password'} fieldProps={loginForm.getFieldProps('password')} fieldMeta={loginForm.getFieldMeta('password')} fieldHelper={loginForm.getFieldHelpers('password')} edit={true} dataLoaded={true} />
                <Button injectClass={`${blueTheme ? 'w-full' : 'w-full bg-[#25D181]'}`} onClick={() => loginForm.submitForm()}>Sign In</Button>
                <motion.div className="relative justify-center flex flex-col items-center text-center">
                  {isMobile && !mobileDevice && (<motion.div className="justify-center mt-2 mb-3 sticky items-center text-center cursor-pointer">
                    <LoginSocialGoogle
                      client_id={process.env.REACT_APP_GG_APP_ID || ''}
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={({ provider, data }) => {
                        responseSuccessGoogle(data)
                      }}
                      onReject={err => {
                        console.log(err);
                      }}
                    >
                      <FcGoogle />
                    </LoginSocialGoogle>
                  </motion.div>)}
                  {mobileDevice && <motion.div className="justify-center mt-2 mb-3 sticky items-center text-center cursor-pointer" onClick={() => handleSignIn()}>
                    <FcGoogle />
                  </motion.div>}
                  <motion.div className="text-center mt-2 justify-center flex flex-row align-center">
                    <p>Create new account</p>&nbsp;?&nbsp;<a className={`${blueTheme ? 'text-blue-500 cursor-pointer' : 'text-[#25D181] cursor-pointer'}`} onClick={() => history.push('/auth/signup')}>Sign up</a>
                  </motion.div>
                  <motion.div className="text-center text-[gray] cursor-pointer underline mt-2 justify-center flex flex-row align-center">
                    <a onClick={() => history.push('/auth/forgot_password')}>Forgot Password</a>
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
            <motion.div className="relative">
              <motion.div className="flex flex-row justify-between sticky">
                <motion.div className="px-6 py-4 text-xl relative">
                  <FaFacebook className={`text-white cursor-pointer`} onClick={() => openSocialLink("https://www.facebook.com/profile.php?id=100091406623203")} />
                </motion.div>
                {/* <motion.div className="px-6 py-4 text-xl relative">
                   <FaInstagram className={`${blueTheme ? 'text-blue-500' : 'text-[#25D181]'} cursor-pointer`} />
                 </motion.div>
                 <motion.div className="px-6 py-4 text-xl relative">
                   <FaTwitter className={`${blueTheme ? 'text-blue-500' : 'text-[#25D181]'} cursor-pointer`} />
                 </motion.div> */}
                <motion.div className="px-6 py-2 text-xl relative">
                  <FaLinkedinIn className={`text-white cursor-pointer`} onClick={() => openSocialLink("https://www.linkedin.com/company/aqua-agri-face/?viewAsMember=true")} />
                </motion.div>
              </motion.div>
            </motion.div>
            <motion.div className="text-white text-sm drop-shadow-lg">
              <p className="px-4 py-4">Read our <span className="underline cursor-pointer" onClick={() => history.push("/privacy-policy")}>privacy policy</span> and <span className="underline cursor-pointer" onClick={() => history.push('/terms-condition')}>Terms & Conditions</span> for more info</p>
            </motion.div>
            <motion.div className="flex flex-row relative text-white justify-between">
              <motion.div className="flex relative flex-row mt-2 mb-2">
                <FaPhoneAlt className={`absolute mt-1 mb-1`} />&nbsp;<p className="text-white mr-2 ml-4">+254788872121</p>
              </motion.div>
              <motion.div className="flex relative flex-row mt-2 mb-2">
                <MdEmail className={`absolute mt-1 mb-1`} />&nbsp;<p className="text-white mr-2 ml-4">info@aquaagriface.com</p>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  )
}

export default Login