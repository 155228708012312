import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import profileImage from "../../assets/dummyProfile.webp";
import { MdLocationDisabled, MdLocationPin } from "react-icons/md";
import { FiFlag, FiHeart, FiSend } from "react-icons/fi";
import {
  FaFlag,
  FaPause,
  FaPlay,
  FaCommentDots,
  FaRegComment,
  FaVolumeUp,
  FaVolumeDown,
  FaVolumeMute,
} from "react-icons/fa";
import { AiFillMinusCircle, AiOutlineExpand } from "react-icons/ai";
import { IoIosSend } from "react-icons/io";
import {
  BsBookmark,
  BsFillFlagFill,
  BsFillBookmarkFill,
  BsFillHeartFill,
} from "react-icons/bs";
import { BiLinkAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper";
import API from "../../axios/API";
import { useHistory } from "react-router-dom";
import Dialog from "../../Components/Dialog";
import Button from "../../Components/Button";

import { setAlert } from "../../store/theme/actions";

const ViewPost = ({ details }) => {
  const [togg, settogg] = useState(false);
  const [cmt, setcmt] = useState(false);
  const [viewPostDetails, setViewPostDetails] = useState(details);
  const [activity, setactivity] = useState({ bookmark: false });
  const history = useHistory();
  const [shareConfirmationPopup, setshareConfirmationPopup] = useState(false);
  const [shareDetails, setShareDetails] = useState();
  const dispatch = useDispatch();
  const [postComments, setPostComments] = useState("");
  const [replyDetails, setReplyDetails] = useState(null);
  const [currentType, setCurrentType] = useState("image");
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const userAgent = navigator.userAgent;
  const [volume, setVolume] = useState(1);
  const countries = useSelector((state) => state.auth.countryList);
  const [commentDisable, setCommentDisable] = useState(false);

  useEffect(() => {
    if (
      details &&
      details.medias &&
      details.medias !== undefined &&
      details.medias.medias.length > 0
    ) {
      const imageExtensionRegex = /\.(jpg|jpeg|png|gif|bmp|svg|JPG|JPEG)$/;
      const videoExtensionRegex = /\.(mp4|avi|mov|wmv|flv|mkv|MOV)$/;
      if (details.medias.medias[0].links.match(imageExtensionRegex)) {
        setCurrentType("image");
        setIsPlaying(false);
      } else if (details.medias.medias[0].links.match(videoExtensionRegex)) {
        setCurrentType("video");
      }
    }
  }, []);

  const sharingFun = (value) => {
    setShareDetails(value);
    setshareConfirmationPopup(true);
  };

  const sharePost = (shareType) => {
    let details = {
      postDetails: shareDetails,
      postType: shareType,
    };
    API.post("/post/sharePost", details).then((res) => {
      if (res.status === 200) {
        dispatch(
          setAlert({
            type: "success",
            title: "Success",
            subtitle: "Post Shared Successfully",
            active: true,
          })
        );
        setshareConfirmationPopup(false);
      }
    });
  };

  const routeToProfile = (value) => {
    history.push(`/profile/${value}`);
  };

  const reactPost = (value, postDetails) => {
    let detailsToSend = {
      reactions: value,
      postId: postDetails._id,
    };
    API.post("/post/likePost", detailsToSend).then((res) => {
      if (res.status === 200) {
        details.isLiked = value;
        details.likes = res.data.body;
        setViewPostDetails({
          ...viewPostDetails,
          likes: res.data.body,
          isLiked: value,
        });
      }
    });
  };

  const sendComments = (value) => {
    setCommentDisable(true);
    let details = {
      postId: value._id,
      parentCommentId: replyDetails !== null ? replyDetails : null,
      comment: postComments,
    };
    if (
      details.comment.toString().length > 0 &&
      details.comment !== null &&
      details.comment !== "" &&
      details.comment !== undefined
    ) {
      API.post("/post/comments", details).then((res) => {
        if (res.status === 200) {
          setCommentDisable(false);
          setPostComments("");
          setViewPostDetails({
            ...viewPostDetails,
            comments: res.data.body.comments,
          });
          setReplyDetails(null);
        }
      });
    }
  };

  const savePost = (value, postDetails) => {
    let detailsToSend = {
      reactions: value,
      postId: postDetails._id,
    };
    API.post("/post/savePost", detailsToSend).then((res) => {
      if (res.status === 200) {
        details.isSavedPost = value;
        setViewPostDetails({ ...viewPostDetails, isSavedPost: value });
      }
    });
  };

  const replaceDescription = (value) => {
    const text = value;

    // Define the regex pattern to find mentions with ids in the format @[Some string ](some string)
    const mentionRegex = /@\[([^[\]]+)\]\(([^()]+)\)/g;

    // Split the text using the regex pattern to find mentions
    const parts = text.split(mentionRegex);
    // Map through the parts and create hyperlinks for the mentions
    const taggedText = parts.map((part, index) => {
      if (index % 3 === 1) {
        // Part after the '@[' and before '](' - this is the mention text
        return (
          <a
            className="cursor-pointer text-[blue]"
            key={index}
            href={`/profile/${parts[index + 1]}`}
          >
            {part}
          </a>
        );
      } else if (index % 3 === 2) {
        // Part after the '](some string)' - this is the id part, you can access it using parts[index + 1]
        return null; // You can also use parts[index + 1] for other purposes if needed
      } else {
        // Regular text without mentions
        return part;
      }
    });
    return taggedText;
  };

  const handleVideoEnded = () => {
    videoRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const getMediaContent = (value) => {
    const imageExtensionRegex = /\.(jpg|jpeg|png|gif|bmp|svg|JPG|JPEG)$/;
    const videoExtensionRegex = /\.(mp4|avi|mov|wmv|flv|mkv|MOV)$/;
    if (value.match(imageExtensionRegex)) {
      return (
        <img
          src={value}
          alt="Your Image"
          className="rounded-lg"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "100%",
            maxWidth: "100%",
          }}
        />
      );
    } else if (value.match(videoExtensionRegex)) {
      if (userAgent.includes("Safari")) {
        return (
          <video
            ref={videoRef}
            id="current-video"
            onEnded={handleVideoEnded}
            onTimeUpdate={handleTimeUpdate}
            className="rounded-lg"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          >
            <source src={value} type="video/mp4" />
          </video>
        );
      } else {
        return (
          <video
            ref={videoRef}
            id="current-video"
            onEnded={handleVideoEnded}
            onTimeUpdate={handleTimeUpdate}
            className="rounded-lg"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "100%",
              maxWidth: "100%",
            }}
            src={value}
          ></video>
        );
      }
    }
  };

  const replyComment = (value, id) => {
    setReplyDetails(value._id);
    document.getElementById(id).focus();
  };

  const timeAgo = (uploadTime) => {
    const now = new Date();
    const timeDifference = now - new Date(uploadTime);

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return years === 1 ? `${years} year ago` : `${years} years ago`;
    } else if (months > 0) {
      return months === 1 ? `${months} month ago` : `${months} months ago`;
    } else if (weeks > 0) {
      return weeks === 1 ? `${weeks} week ago` : `${weeks} weeks ago`;
    } else if (days > 0) {
      return days === 1 ? `${days} day ago` : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
    } else {
      return seconds === 1 ? `${seconds} second ago` : `${seconds} seconds ago`;
    }
  };

  const getCurrentType = (value, index) => {
    const imageExtensionRegex = /\.(jpg|jpeg|png|gif|bmp|svg|JPG|JPEG)$/;
    const videoExtensionRegex = /\.(mp4|avi|mov|wmv|flv|mkv|MOV)$/;
    if (value[index].links.match(imageExtensionRegex)) {
      setCurrentType("image");
      videoRef.current.pause();
      setIsPlaying(false);
    } else if (value[index].links.match(videoExtensionRegex)) {
      setCurrentType("video");
    }
  };

  // ProgressBar.js
  const ProgressBar = ({ value, onChange }) => {
    return (
      <input
        className="relative"
        type="range"
        min="0"
        step="0.01"
        max="100"
        value={value}
        onChange={onChange}
      />
    );
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleProgressChange = (e) => {
    const newTime = (e.target.value / 100) * videoRef.current.duration;
    setCurrentTime(newTime);
    videoRef.current.currentTime = newTime;
  };

  const toggleFullscreen = () => {
    const video = document.getElementById("current-video");
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      // Firefox
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
      // IE/Edge
      video.msRequestFullscreen();
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    videoRef.current.volume = newVolume; // Set volume of the video element
  };

  const volumeOnOff = (e) => {
    const newVolume = parseFloat(e);
    setVolume(newVolume);
    videoRef.current.volume = newVolume; // Set volume of the video element
  };

  return (
    <>
      {viewPostDetails &&
        viewPostDetails !== undefined &&
        viewPostDetails.postDetails &&
        viewPostDetails.postDetails !== undefined && (
          <motion.div className="lg:mx-4 mx-1 rounded-lg bg-white text-black my-3">
            <motion.div className="text-black px-3">
              {viewPostDetails && viewPostDetails.sharedPost ? (
                viewPostDetails.sharedUser.accountTypeId === "1" ? (
                  <motion.div className="flex flex-row flex-1">
                    <motion.div
                      className="text-black font-bold mr-2 cursor-pointer"
                      onClick={() =>
                        routeToProfile(viewPostDetails.sharedUser._id)
                      }
                    >
                      {viewPostDetails.sharedUser.firstName +
                        " " +
                        viewPostDetails.sharedUser.lastName}
                    </motion.div>
                    <motion.div className="text-black">
                      shared this post
                    </motion.div>
                  </motion.div>
                ) : (
                  <motion.div className="flex flex-row flex-1">
                    <motion.div
                      className="text-black font-bold mr-2 cursor-pointer"
                      onClick={() =>
                        routeToProfile(viewPostDetails.sharedUser._id)
                      }
                    >
                      {viewPostDetails.sharedUser.businessName}
                    </motion.div>
                    <motion.div className="text-black">
                      shared this post
                    </motion.div>
                  </motion.div>
                )
              ) : (
                ""
              )}
            </motion.div>
            <div className="grid grid-cols-2 mx-1 lg:mx-4 rounded-lg  relative">
              {/*================== POST TOP SECTION ===============*/}
              <div className="col-span-2 lg:col-span-1   py-4 rounded-lg">
                {/* <<<POST {PROFILE} */}
                <motion.div className="relative flex flex-1 px-3 gap-3 flex-column justify-start">
                  <motion.div className="postedUser">
                    <img
                      src={
                        viewPostDetails &&
                          viewPostDetails.postedUser &&
                          viewPostDetails.postedUser.profileImage
                          ? viewPostDetails.postedUser.profileImage
                          : profileImage
                      }
                      // height={50}
                      // width={50}
                      style={{ height: '50px', width: '50px' }}
                      alt="User Image"
                      className="rounded-lg"
                    />
                  </motion.div>
                  <motion.div>
                    {viewPostDetails &&
                      viewPostDetails.postedUser !== undefined && (
                        <motion.div
                          className="text-black font-bold cursor-pointer"
                          onClick={() =>
                            routeToProfile(viewPostDetails.postedUser._id)
                          }
                        >
                          {viewPostDetails &&
                            viewPostDetails.postedUser !== null &&
                            viewPostDetails.postedUser !== undefined &&
                            viewPostDetails.postedUser &&
                            viewPostDetails.postedUser.accountTypeId === "1"
                            ? viewPostDetails.postedUser.firstName +
                            " " +
                            viewPostDetails.postedUser.lastName
                            : viewPostDetails.postedUser.businessName}
                        </motion.div>
                      )}
                    <motion.div className="text-[grey] flex font-md flex-row text-[9px] lg:text-[14px] justify-start">
                      <span className="mr-1">
                        {timeAgo(
                          viewPostDetails &&
                            viewPostDetails.postDetails !== undefined &&
                            viewPostDetails.postDetails.createdAt !== undefined
                            ? viewPostDetails.postDetails.createdAt
                            : ""
                        )}
                      </span>
                      <span className="mr-1 ml-1">-</span>
                      {/* <span className="mr-1 ml-1">10.3 M views</span> */}
                      {/* <span className="mr-1 ml-1"> -</span> */}
                      <span className="mr-1 ml-1">
                        {viewPostDetails.likes.length} Likes
                      </span>
                      <span className="mr-1 ml-1">-</span>
                      <span className="ml-1">
                        {viewPostDetails.comments.length &&
                          viewPostDetails.comments.length}{" "}
                        Comments
                      </span>
                    </motion.div>
                  </motion.div>
                </motion.div>
                {/* POST {PROFILE}>>> */}

                {/* <<< POST{ POST-PIC & INTERACTION} */}
                <motion.div className="mt-2 justify-center  relative   ">
                  <motion.div className="relative ">
                    <div className="px-10 lg:px-20 py-6  overflow-hidden">
                      {viewPostDetails.medias.medias.length == 0 ? (
                        <div className="max-h-[50vh] h-auto lg:h-[280px] rounded-lg overflow-y-auto">
                          {replaceDescription(
                            viewPostDetails.postDetails.description
                          )}
                        </div>
                      ) : (
                        <Swiper
                          effect={"cards"}
                          grabCursor={true}
                          modules={[EffectCards]}
                          className="h-[280px] md:h-[490px] lg:h-[400px] "
                          style={{ zIndex: 0 }}
                          onSlideChange={(ev) =>
                            getCurrentType(
                              viewPostDetails.medias.medias,
                              ev.realIndex
                            )
                          }
                        >
                          {viewPostDetails &&
                            viewPostDetails.medias !== null &&
                            viewPostDetails.medias.medias.map((c) => (
                              <SwiperSlide className=" w-full bg-white/90   rounded-lg">
                                {getMediaContent(c.links)}
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      )}
                    </div>

                    <br />

                    <motion.div className=" flex justify-between">
                      <motion.div className="flex lg:flex-row flex-col">
                        <motion.div className="flex flex-row my-2">
                          {currentType === "video" ? (
                            isPlaying ? (
                              <FaPause
                                onClick={togglePlay}
                                className="text-[#23297A] relative text-xl mx-2 cursor-pointer"
                              />
                            ) : (
                              <FaPlay
                                onClick={togglePlay}
                                className="text-[#23297A]  relative text-xl mx-2 cursor-pointer"
                              />
                            )
                          ) : (
                            ""
                          )}
                          {currentType === "video" && (
                            <ProgressBar
                              className="relative text-xl mx-2 cursor-pointer"
                              value={
                                (currentTime / videoRef.current.duration) * 100
                              }
                              onChange={handleProgressChange}
                            />
                          )}
                        </motion.div>
                        <motion.div className="flex flex-row my-2">
                          {currentType === "video" && (
                            <AiOutlineExpand
                              className="relative text-xl mx-2 cursor-pointer"
                              onClick={toggleFullscreen}
                            />
                          )}
                          {currentType === "video" && (
                            <motion.div className="relative flex flex-row  justify-between mx-2 cursor-pointer text-xl">
                              {volume > 0.5 ? (
                                <FaVolumeUp onClick={() => volumeOnOff(0)} />
                              ) : volume < 0.5 && volume > 0 ? (
                                <FaVolumeDown />
                              ) : (
                                volume === 0 && (
                                  <FaVolumeMute onClick={() => volumeOnOff(1)} />
                                )
                              )}
                              <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={volume}
                                className="mx-6"
                                onChange={handleVolumeChange}
                              />
                            </motion.div>
                          )}
                        </motion.div>
                        {/* <AiFillMinusCircle className="relative text-2xl mx-2 cursor-pointer" />
                  <BsFillFlagFill className="relative text-2xl mx-2 cursor-pointer" />
                  <FiSend className=" relative mx-2 text-2xl  cursor-pointer" /> */}
                      </motion.div>
                      <motion.div className="flex">
                        {/* <BiLinkAlt className=" relative text-2xl mx-2 cursor-pointer" /> */}
                        <IoIosSend
                          title="Share post"
                          className="text-2xl text-[#4285F4]  relative mx-2 cursor-pointer"
                          onClick={() => sharingFun(viewPostDetails)}
                        />

                        {viewPostDetails && viewPostDetails.isLiked ? (
                          <BsFillHeartFill
                            onClick={() => {
                              reactPost(false, viewPostDetails);
                            }}
                            title="Un-like"
                            className="text-[#1FB141] relative mx-2 text-2xl  cursor-pointer"
                          />
                        ) : (
                          <FiHeart
                            onClick={() => {
                              reactPost(true, viewPostDetails);
                            }}
                            title="Like"
                            className=" relative mx-2 text-2xl  cursor-pointer"
                          />
                        )}

                        {viewPostDetails && !viewPostDetails.isSavedPost ? (
                          <BsBookmark
                            onClick={() => {
                              savePost(true, viewPostDetails);
                            }}
                            title="Save post"
                            className=" relative mx-2 text-2xl  cursor-pointer"
                          />
                        ) : (
                          <BsFillBookmarkFill
                            onClick={() => {
                              savePost(false, viewPostDetails);
                            }}
                            title="Unsave Post"
                            className=" relative mx-2 text-2xl  cursor-pointer"
                          />
                        )}
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
                {/* ---POST {POST-PIC & INTERACTION} >>> */}
              </div>

              {/* ================THOUGHT SECTION================= */}
              <div className="col-span-2 lg:col-span-1 lg:mx-6">
                <motion.div className="relative  w-full flex">
                  {/* Thoughts section */}
                  <motion.div className="py-2 w-full rounded-lg">
                    <div
                      className="bg-white  p-3 rounded-lg "
                      style={{
                        boxShadow: " 1px 1px 4px -1px rgba(101,101,101,0.75)",
                      }}
                    >
                      {viewPostDetails.medias.medias.length != 0 && (
                        <>
                          <motion.div className="heading ">
                            <motion.div className="flex flex-row justify-start">
                              <motion.div className="text-black font-bold mr-1">
                                Thoughts
                              </motion.div>
                              {viewPostDetails.location !== null &&
                                viewPostDetails.location !== "" && (
                                  <MdLocationPin className="relative ml-1 mr-1 mt-1" />
                                )}
                              <motion.div className="text-[grey] font-normal ml-1">
                                {countries.map((c) => {
                                  if (c.value === viewPostDetails.location) {
                                    return c.text;
                                  }
                                })}
                              </motion.div>
                            </motion.div>
                            <motion.div
                              className={`sub-heading text-sm my-2  ${togg ? "overflow-y-auto" : "overflow-hidden"
                                }`}
                              initial={{ height: "16px" }}
                              animate={{ height: togg ? "30vh" : "10vh" }}
                            >
                              {replaceDescription(
                                viewPostDetails.postDetails.description
                              )}
                            </motion.div>
                            {viewPostDetails.postDetails.description.slice()
                              .length > 200 && (
                                <motion.div
                                  onClick={() => {
                                    settogg(!togg);
                                    setcmt(!cmt);
                                  }}
                                  className="cursor-pointer text-sm bg-white text-gray-600 px-3 ring-1 ring-gray-600 w-fit drop-shadow-md rounded-lg"
                                >
                                  {togg ? "Show less" : "Show More"}
                                </motion.div>
                              )}
                          </motion.div>
                          <hr className="mt-2 h-2" />
                        </>
                      )}

                      <motion.div
                        onClick={() => {
                          settogg(false);
                          setcmt(!cmt);
                        }}
                        className="comments flex pb-2 items-center text-black font-bold cursor-pointer"
                      >
                        <h3>Comments</h3>{" "}
                        <div>
                          <FaCommentDots
                            className="relative mx-3 "
                            title="Comments"
                          />
                        </div>
                      </motion.div>
                      {/* {<<<COMMENT} */}
                      {viewPostDetails.comments == null ||
                        viewPostDetails.comments.length === 0 ? (
                        <>
                          <div className="w-full flex justify-center text-gray-400 text-sm">
                            No Comments yet
                          </div>
                        </>
                      ) : (
                        <motion.div
                          animate={{ height: togg || cmt ? "0" : "30vh" }}
                          className={` overflow-y-auto `}
                        >
                          <>
                            {" "}
                            {viewPostDetails.comments.map((item1) =>
                              item1.comments.map((item) => {
                                return (
                                  <motion.div className=" w-full">
                                    <motion.div className="relative flex flex-1 gap-3 flex-column justify-start">
                                      <motion.div className="postedUser">
                                        <img
                                          src={
                                            item.user &&
                                              item.user.length &&
                                              item.user[0].profileImage !== null
                                              ? item.user[0].profileImage
                                              : profileImage
                                          }
                                          height={50}
                                          width={50}
                                          alt="User Image"
                                          className="rounded-lg"
                                        />
                                      </motion.div>
                                      <motion.div>
                                        <motion.div className="text-black font-bold">
                                          {item.user &&
                                            item.user[0].accountTypeId === "1"
                                            ? item.user[0].firstName +
                                            " " +
                                            item.user[0].lastName
                                            : item.user[0].businessName}
                                        </motion.div>
                                        <motion.div className="relative text-[grey] flex text-sm flex-row justify-start">
                                          <span className="mr-1">
                                            {timeAgo(item.createdAt)}
                                          </span>
                                          {/* <span className="mr-1 ml-1"> -</span>
                                <span className="mr-1 ml-1">5.8 M Likes</span>
                                <span className="mr-3 ml-3 relative mt-1">
                                  <FiHeart />
                                </span>
                                <span className="mr-3 ml-3 relative mt-1">
                                  <FaFlag />
                                </span> */}
                                          <span className="mr-3 ml-3 relative mt-1">
                                            <FaCommentDots
                                              onClick={() =>
                                                replyComment(item, details._id)
                                              }
                                            />
                                          </span>
                                        </motion.div>
                                      </motion.div>
                                    </motion.div>
                                    <motion.div className="pl-16 text-sm">
                                      {item.comments}
                                    </motion.div>
                                    {/* <<<SUBCOMMENT */}
                                    {item.subComments.length > 0
                                      ? item.subComments.map((c) => {
                                        return (
                                          <>
                                            {c &&
                                              c.comments !== undefined && (
                                                <div>
                                                  <motion.div className="pl-16 mt-2 mb-1 w-full relative flex flex-1 gap-3 flex-column ">
                                                    <motion.div className="postedUser">
                                                      <img
                                                        src={
                                                          c.user &&
                                                            c.user.length &&
                                                            c.user[0]
                                                              .profileImage !==
                                                            null
                                                            ? c.user[0]
                                                              .profileImage
                                                            : profileImage
                                                        }
                                                        height={50}
                                                        width={50}
                                                        alt="User Image"
                                                        className="rounded-lg"
                                                      />
                                                    </motion.div>

                                                    <motion.div className="w-full">
                                                      <motion.div className="text-black font-bold w-full ">
                                                        {c.user &&
                                                          c.user.length &&
                                                          c.user.length > 0
                                                          ? c.user[0]
                                                            .accountTypeId ===
                                                            "1"
                                                            ? c.user[0]
                                                              .firstName +
                                                            " " +
                                                            c.user[0]
                                                              .lastName
                                                            : c.user[0]
                                                              .businessName
                                                          : ""}
                                                      </motion.div>
                                                      <motion.div className="relative text-[grey] w-full  flex text-[10px] md:text-sm flex-row justify-start">
                                                        <span className=" mx-2">
                                                          {timeAgo(
                                                            c.createdAt
                                                          )}
                                                        </span>
                                                        {/* <span className=" mx-2 "> -</span>
                                      <span className=" mx-2 ">5.8 M Likes</span>
                                      <span className=" mx-2 mt-1 relative ">
                                        <FiHeart />
                                      </span> */}
                                                      </motion.div>
                                                    </motion.div>
                                                  </motion.div>
                                                  <motion.div className="pl-28  text-sm">
                                                    {c.comments}
                                                  </motion.div>
                                                </div>
                                              )}
                                          </>
                                        );
                                      })
                                      : ""}
                                    {/* SUBCOMMENT>>> */}

                                    {/* <motion.div className="text-center text-sm text-[grey] ">
                            7 more reply
                          </motion.div> */}

                                    <hr className="my-2" />
                                  </motion.div>
                                );
                              })
                            )}
                            {/* <motion.div className="text-center text-sm text-[grey]">
                        3 More
                      </motion.div> */}
                          </>
                        </motion.div>
                      )}
                      {/* HERE WE CAN MAP OUR COMMENT ARRAY */}

                      {/* COMMENT>>> */}
                    </div>
                    <motion.div className="comment mt-2 relative Section overflow-hidden shadow-inner px-2 lg:px-0">
                      <motion.div className="input rounded-lg flex flex-row justify-between ">
                        <textarea
                          className="rounded-lg bg-[#F5F5F5] focus:outline-none px-5 pr-16 w-full py-2 h-10 lg:h-24  max-h-52"
                          placeholder="Comment..."
                          style={{
                            boxShadow:
                              "1px 1px 5px -1px rgba(101,101,101,0.75) inset",
                          }}
                          id={details._id}
                          value={postComments}
                          onChange={(ev) => setPostComments(ev.target.value)}
                        />
                        <button
                          disabled={commentDisable}
                          className="absolute flex right-10 top-2 lg:top-10 px-2 text-2xl items-center"
                          onClick={() => sendComments(details)}
                        >
                          <FiSend className="items-center cursor-pointer" />
                        </button>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </div>
              <Dialog
                title={"Share Post"}
                showDialog={shareConfirmationPopup}
                onClose={() => setshareConfirmationPopup(false)}
              >
                <motion.div className="text-black px-2 py-2">
                  You want to share this post as public or private.Please choose
                  an option.
                  <motion.div className="flex flex-row w-full justify-between mt-2">
                    <Button
                      injectClass={"bg-[grey]"}
                      onClick={() => setshareConfirmationPopup(false)}
                    >
                      Cancel
                    </Button>
                    <motion.div className="flex text-end justify-end flex-row w-full">
                      <Button
                        className="mr-2"
                        injectClass={"bg-[green]"}
                        onClick={() => sharePost(2)}
                      >
                        Share as Public
                      </Button>
                      &nbsp;&nbsp;
                      <Button className="ml-2" onClick={() => sharePost(1)}>
                        Share as Private
                      </Button>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </Dialog>
            </div>
          </motion.div>
        )}
    </>
  );
};

export default ViewPost;
