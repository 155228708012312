import './App.css';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { reducer } from './store/reducer'
import { BrowserRouter as Router } from 'react-router-dom'
import Root from './Root/Root'
import { Suspense } from 'react'
import Loaders from './Loaders/Loaders'
import React,{useEffect} from 'react'

const store = createStore(reducer)

function App() {

    return (
        <Provider store={store}>
            <Suspense fallback={<Loaders/>}>
            <Router>
                <div className="font h-100" style={{ fontFamily: 'Graphik, Helvetica, Arial, sans-serif' }}>
                    <Root />
                </div>
            </Router>
            </Suspense>
        </Provider>
    );
}

export default App;
