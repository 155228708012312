import { motion, AnimatePresence } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FaExclamationTriangle, FaEye, FaEyeSlash, FaGalacticSenate } from 'react-icons/fa'
import { RiSearchLine } from 'react-icons/ri'
// import getSymbolFromCurrency from 'currency-symbol-map'


const FieldInput = ({ row, theme, disabled = false, type = "text", labelSide = 'up', bgColor, fieldProps, injectClass, fieldMeta, border = true, fieldHelper, label, autoComplete = "true", placeholder, edit, loading = false, dataLoaded = false, ml, length, onChange, lengthCheck, minDate = '', startIcon = '', bottomIcon = '', helpText, labelLength, radioOption = false, miniBox = false, splitBox = false, splitText = '' }) => {
    const input = useRef(null)
    length = lengthCheck
    const wrapRef = useRef(null)
    const [showError, setShowError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const handleFocus = () => {
        input.current.focus()
    }

    const inputType = useMemo(() => {
        if (type === 'password') return showPassword ? 'text' : 'password'
        return type
    }, [type, showPassword])

    const inputChange = (e) => {
        if (type === 'file' && typeof onChange === 'function') {
            if (fieldHelper && typeof fieldHelper.setValue === 'function') {
                fieldHelper.setValue(e.target.files)
                fieldHelper.setTouched(true)
            }
        } else if (typeof onChange === 'function') {
            onChange(e.target.value)
        }
    }

    const toggleError = (val) => setShowError(val)
    useEffect(() => {
        if (type === 'file' && fieldMeta && fieldMeta.touched === false) {
            input.current.value = null
            fieldHelper.setValue('')
        }
    }, [fieldMeta.touched, fieldProps.value])

    const [select, setSelect] = useState(false)


    return (
        <motion.div className={`mt-2 mb-2`}>
            <motion.div className={`relative w-full flex ${labelSide === 'side' ? 'flex-row justify-start gap-5' : 'flex-col'}`}>
                {
                    lengthCheck && label &&
                    <motion.div className="flex flex-row justify-between">
                        <label className={`mt-2 ${border ? 'mb-2' : ''}`}>{label}</label>
                        <label className={`mt-2 ${border ? 'mb-2' : ''}`}>{fieldProps.value !== null && fieldProps.value !== undefined && fieldProps.value.length ? (lengthCheck - fieldProps.value.length) : lengthCheck}/{lengthCheck}</label>
                    </motion.div>
                }
                {
                    label && !lengthCheck &&
                    <motion.div className={`flex flex-row justify-between ${theme}`}>
                        <label style={{ width: labelLength ? `${labelLength}px` : 'auto' }} className={`${labelSide === 'side' ? 'mt-4' : 'mt-2'} ${border ? 'mb-2' : ''}`}>{label}</label>
                    </motion.div>
                }
                {
                    helpText && <motion.div className="flex flex-row justify-between">
                        <label className={`mt-2 ${border ? 'mb-2' : ''}`}>{helpText}</label>
                    </motion.div>
                }
                {
                    splitBox && <div className='flex gap-5'>
                        <div ref={wrapRef} className={`${injectClass} group flex flex-col ${border && 'ring-1'} ring-lightGrey ${startIcon !== '' ? 'px-2' : ''} pt-2 ${border && 'mt-2'} mb-2 rounded-lg focus-within:ring-[black] transition-all duration-100 ${disabled === true ? 'bg-[lightGrey]' : bgColor ? bgColor : ''}`} onClick={handleFocus}>
                            <div className="relative">
                                <span>{splitText}</span>
                            </div>
                        </div>
                        <div className="flex-grow">

                            <div ref={wrapRef} className={`${injectClass} group flex flex-col ${border && 'ring-1'} ring-lightGrey ${startIcon !== '' ? 'px-2' : ''} pt-2 ${border && 'mt-2'} mb-2 rounded-lg focus-within:ring-[black] transition-all duration-100 ${disabled === true ? 'bg-[lightGrey]' : bgColor ? bgColor : ''}`} onClick={handleFocus}>
                                <div className="relative">
                                    {
                                        edit && dataLoaded &&
                                        <input type={inputType} ref={input} className={`pb-4 ${startIcon !== '' ? 'pl-3' : 'pl-3'} bg-transparent ${!miniBox ? 'pr-10' : ''}  w-full ${border ? 'outline-none' : 'border-b border-black outline-none'}`} {...fieldProps} autoComplete={autoComplete} placeholder={placeholder} disabled={disabled} maxLength={ml ? `${length}` : ''} onKeyUp={inputChange} />
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {!splitBox &&
                    <div ref={wrapRef} className={`${injectClass} group flex flex-col ${border && 'ring-1'} ring-lightGrey ${startIcon !== '' ? 'px-2' : ''} pt-2 ${border && 'mt-2'} mb-2 rounded-lg focus-within:ring-[black] transition-all duration-100 ${disabled === true ? 'bg-[lightGrey]' : bgColor ? bgColor : ''}`} onClick={handleFocus}>
                        <div className="relative">
                            {
                                edit && dataLoaded && type == "textarea" ? <textarea className={`pb-1.5 pl-3 bg-transparent pr-10 w-full ${row ? '' : 'h-28'} ${border ? 'outline-none' : 'border-b border-black outline-none'} resize-none`} ref={input} {...fieldProps} maxLength={ml ? `${length}` : ''} rows={row} /> :
                                    type == 'date' ? <input type={inputType} ref={input} className={`pb-4 pl-3 bg-transparent pr-10 w-full h-7 ${border ? 'outline-none' : 'border-b border-black outline-none'}`} {...fieldProps} autoComplete={autoComplete} placeholder={placeholder} disabled={disabled} min={minDate} /> :
                                        <input type={inputType} ref={input} className={`pb-4 ${startIcon !== '' ? 'pl-3' : 'pl-3'} bg-transparent ${!miniBox ? 'pr-10' : ''}  w-full ${border ? 'outline-none' : 'border-b border-black outline-none'}`} {...fieldProps} autoComplete={autoComplete} placeholder={placeholder} disabled={disabled} maxLength={ml ? `${length}` : ''} onKeyUp={inputChange} />
                            }
                            {edit &&
                                <span className={`absolute ${type === 'password' ? 'right-9' : 'right-2'} -top-0 text-xl flex`}>
                                    {type === 'password' && !showPassword && <FaEyeSlash className="text-gray-500 cursor-pointer ml-2" onClick={() => setShowPassword(true)} />}
                                    {type === 'password' && showPassword && <FaEye className="text-gray-500 cursor-pointer ml-2" onClick={() => setShowPassword(false)} />}
                                </span>
                            }
                            {/* {edit && startIcon !== '' &&
         <div className="absolute -left-2 top-0 text-base flex">
             <p className="ml-2">{getSymbolFromCurrency(startIcon)}</p>
         </div>
     } */}
                            {edit && bottomIcon !== '' &&
                                <div className="absolute -left-2 top-1 text-xl flex">
                                    <RiSearchLine className="text-black ml-2" />
                                </div>
                            }
                        </div>
                    </div>
                }



                <div className="relative flex flex-row justify-start">
                    {
                        edit && fieldMeta.error && fieldMeta.touched && <p style={{ color: 'red' }}>{fieldMeta.error}</p>
                    }
                </div>
                {
                    radioOption && labelSide === 'side' &&
                    < motion.div className="relative p-2 mt-5 ring-1 ring-black rounded-full w-[2px] h-[2px] flex flex-col" onClick={() => setSelect(!select)} >
                        <>
                            {
                                select ? <motion.div className="absolute top-1 right-1 ring ring-1 ring-black rounded-full bg-[red] p-1 w-[1px] h-[1px]"></motion.div> : <motion.div className="absolute ring ring-1 top-1 right-1  ring-black rounded-full bg-black p-1 w-[1px] h-[1px]"></motion.div>
                            }
                        </>
                    </motion.div>
                }
            </motion.div>
        </motion.div >
    )
}

export default FieldInput