import React from 'react';
import {motion} from 'framer-motion'
import textScreen from '../assets/Icons/textScreen.png'

const Message = ( ) =>{
    return (
        <motion.div className="relative min-h-screen items-center flex flex-col overflow-hidden text-center justify-center">
                <img src={textScreen} className="h-96 w-96"/>
                <motion.div className="text-5xl font-bold text-black">
                Our upcoming update will make messaging easier and more convenient than ever before. Stay tuned for more details.
                </motion.div>
        </motion.div>
    )
}

export default Message