import React from 'react';
import {motion} from 'framer-motion'
import shopScreen from '../assets/Icons/shoppingScreen.png'

const Shop = ( ) =>{
    return (
        <motion.div className="relative min-h-screen items-center flex flex-col overflow-hidden text-center justify-center">
                <img src={shopScreen} className="h-96 w-96"/>
                <motion.div className="text-5xl font-bold text-black">
                Get ready to shop 'til you drop! Our upcoming release will feature some of the hottest trends of the season. Stay tuned for more details.
                </motion.div>
        </motion.div>
    )
}

export default Shop