import {SET_LOGGEDIN,SET_TOKEN,SET_USER,SET_USER_DETAILS,SET_TEMP_USER,SET_SWITCH_PROFILE_MODE,SET_SCREEN_NAME,
    SET_PROFILE_EDIT_MODE,SET_CALL_USER_API,SET_FOLLOW_DETAIL,SET_LOAD_ALL_POST,SET_COUNTRY_LIST, SET_OPEN_NOTIFICATION_BOX} from './constants'
import authStore from "./stores";

const authReducers = (state = authStore,action) =>{
    switch (action.type){
        case SET_LOGGEDIN:
            return {...state,isLoggedIn:action.payload}
        case SET_TOKEN:
            return {...state,token:action.payload}
        case SET_USER:
            return {...state,user:action.payload}
        case SET_USER_DETAILS:
            return {...state,userDetails:action.payload}
        case SET_TEMP_USER :
            return {...state,tempUser:action.payload}
        case SET_SWITCH_PROFILE_MODE :
            return {...state,profileMode :action.payload}
        case SET_SCREEN_NAME : 
            return {...state,screenName : action.payload}
        case SET_PROFILE_EDIT_MODE : 
            return {...state,profileEditMode : action.payload}
        case SET_CALL_USER_API : 
            return {...state,callUserApi : action.payload}
        case SET_FOLLOW_DETAIL : 
            return {...state,followDetail:action.payload}
        case SET_LOAD_ALL_POST :
            return {...state, loadAllPost : action.payload}
        case SET_COUNTRY_LIST :
            return {...state, countryList: action.payload}
        case SET_OPEN_NOTIFICATION_BOX:
            return {...state, openNotificationBox : action.payload}
        default:
            return state
    }
}

export default authReducers