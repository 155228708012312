import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { IoArrowBackCircleSharp } from 'react-icons/io5'
import logo from '../../assets/minlogo.jpeg'
import forgotPasss from '../../assets/forgotPasss.png'
import FieldInput from '../../Components/FieldInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from '../../Components/Button'
import { useHistory } from 'react-router-dom'
import API from '../../axios/API'
import { setAlert } from '../../store/theme/actions'
import { useDispatch } from 'react-redux'

const initialFormValues = {
    password: ''
}

const ResetPassword = ({ history }) => {

    // const history = useHistory()
    const dispatch = useDispatch()
    const [resetSuccess, setResetSuccess] = useState(false)

    const forgotPassSchema = Yup.object().shape({
        password: Yup.string().required("Password is required"),
        confirmPassword: Yup.string().required("Confirm Password is required")
    })

    const forgotPass = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: forgotPassSchema,
        onSubmit: (values) => {
            if (values.password === values.confirmPassword) {
                let details = {
                    userId: history.location.state.state.userId,
                    password: values.password
                }
                API.post('/auth/changePassword', details).then((res) => {
                    if (res.status === 200) {
                        setResetSuccess(true)
                    }
                })
            } else {
                dispatch(setAlert({ type: 'error', title: 'Error', subtitle: 'Password reset failed', active: true }))
            }
        }
    })

    return (
        <>
            {
                !resetSuccess && <motion.div className={'min-h-screen flex flex-col items-center justify-center  relative bg-[lightGrey]'}>
                    <motion.div className={'bg-white relative dropshadow-lg rounded-2xl px-2 py-4 md:w-2/4 w-80'}>
                        <motion.div className={'flex flex-row justify-between'}>
                            <motion.div className="sticky">
                                <IoArrowBackCircleSharp className="h-6 w-6 cursor-pointer" onClick={() => history.push('/auth/otp')} />
                            </motion.div>
                            <motion.div>
                                <img src={logo} className="h-14 w-14" />
                            </motion.div>
                        </motion.div>
                        <motion.div className={'flex flex-col items-center text-center'}>
                            <motion.div className="text-2xl mt-2">
                                <p>Reset Password </p>
                            </motion.div>
                            <motion.div className="text-sm mt-2 text-[#A4A5AA]">
                                <p>Set the new password for your account so you can login</p>
                                <p>and access all the features .</p>
                            </motion.div>
                        </motion.div>
                        <motion.div className="mt-2 mb-2  px-14">
                            <FieldInput label={'Enter new password'} type={'password'} fieldMeta={forgotPass.getFieldMeta('password')} fieldProps={forgotPass.getFieldProps("password")} fieldHelper={forgotPass.getFieldHelpers('password')} edit={true} dataLoaded={true} />
                            <FieldInput label={'Confirm password'} type={'password'} fieldMeta={forgotPass.getFieldMeta('confirmPassword')} fieldProps={forgotPass.getFieldProps("confirmPassword")} fieldHelper={forgotPass.getFieldHelpers('confirmPassword')} edit={true} dataLoaded={true} />
                        </motion.div>
                        <motion.div className='mt-2 mb-2 px-10 flex flex-row text-center justify-center items-center'>
                            <Button injectClass={'w-64 text-xl bg-[#2593D2]'} onClick={() => forgotPass.submitForm()}>Reset Password</Button>
                        </motion.div>
                    </motion.div>
                </motion.div>
            }
            {
                resetSuccess && <motion.div className={'min-h-screen flex flex-col items-center justify-center  relative bg-[lightGrey]'}>
                    <motion.div className={'bg-white relative dropshadow-lg rounded-2xl px-2 py-4 w-1/3'}>
                        <motion.div className={'flex flex-row justify-end text-end items-end'}>
                            <motion.div>
                                <img src={logo} className="h-14 w-14" />
                            </motion.div>
                        </motion.div>
                        <motion.div className={'flex flex-col items-center text-center'}>
                            <motion.div className="text-2xl mt-2">
                                <p>Password Updated</p>
                            </motion.div>
                            <motion.div className="text-sm mt-2 text-[#A4A5AA] mb-10">
                                <p>Your password has been updated !</p>
                            </motion.div>
                        </motion.div>
                        <motion.div className='mt-2 mb-2 px-10 flex flex-row text-center justify-center items-center'>
                            <Button injectClass={'w-64 text-xl bg-[#2593D2]'} onClick={() => history.push('/auth/login')}>Done</Button>
                        </motion.div>
                    </motion.div>
                </motion.div>
            }
        </>
    )
}

export default ResetPassword