import React from "react";
import { motion } from "framer-motion";

const Videos = ({ videoSet }) => {
  const userAgent = navigator.userAgent;

  return (
    <motion.div
      className={`relative w-full grid md:grid-cols-4 sm:grid-cols-1 md:gap-5 sm:gap-3 mt-2 `}
    >
      {videoSet.map((c) => {
        return (
          <motion.div className={`relative flex flex-row`}>
            {userAgent.includes("Safari") ? (
              <video
                alt=""
                style={{ height: "200px", width: "400px" }}
                controls
              >
                <source src={c} type="video/mp4" />
              </video>
            ) : (
              <video
                src={c}
                alt=""
                style={{ height: "200px", width: "400px" }}
                controls
              ></video>
            )}
            {/* <motion.div
              className={`absolute bottom-0 right-0 m-2 text-xs text-white px-3 rounded-lg backdrop-blur-sm bg-white/30 py-1`}
            >
              <motion.div className={"relative"}></motion.div>
            </motion.div> */}
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default Videos;
