import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FaPen, FaPlus } from "react-icons/fa";
import profilePic from "../../assets/dummyProfile.webp";
import plusIcon from "../../assets/Icons/plus.png";
import emailIcon from "../../assets/Icons/email.png";
import phoneIcon from "../../assets/Icons/phone.png";
import msgIcon from "../../assets/Icons/message.png";
import hamIcon from "../../assets/Icons/hamburger.png";
import "../../custom/profileCustom.css";
import coverPng from "../../assets/singupbackground.png";
import ProfilePostSwitch from "../../Components/ProfilePostSwitch";
import About from "./ProfileSections/About";
import Professional from "./ProfileSections/Professionalsummary";
import Experience from "./ProfileSections/Experience";
import License from "./ProfileSections/Licence";
import Projects from "./ProfileSections/Projects";
import Education from "./ProfileSections/Education";
import Language from "./ProfileSections/Language";
import Skills from "./ProfileSections/Skills";
import Media from "./ProfileSections/Media";
import { useDispatch, useSelector } from "react-redux";
import Posts from "./PostSections/Posts";
import { useHistory } from "react-router-dom";
import API from "../../axios/API";
import Loaders from "../../Loaders/Loaders";
import {
  setCallUserApi,
  setEditProfileMode,
  setScreenName,
  setSwitchProfileMode,
  setUserDetails,
} from "../../store/auth/actions";
import { setAlert } from "../../store/theme/actions";
import proPic from "../../assets/dummyProfile.jpg";
import Dialog from "../../Components/Dialog";
import { FiMessageCircle } from "react-icons/fi";

const Profile = ({ match }) => {
  const [url, setUrl] = useState(true);
  const [plusMenu, setPlusMenu] = useState(false);
  const isProfileMode = useSelector((state) => state.auth.profileMode);
  const [profileDetails, setProfileDetails] = useState();
  const [followDetails, setFollowDetails] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const editMode = useSelector((state) => state.auth.profileEditMode);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [callUserDetail, setCallUserDetail] = useState(false);
  const callUserApi = useSelector((state) => state.auth.callUserApi);
  const followDetail = useSelector((state) => state.auth.followDetail);
  const [openFollowersList, setOpenFollwersList] = useState(false);
  const [openFollowingList, setOpenFollowingList] = useState(false);
  const [followersList, setFollowersList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const [unfollowDialog, setUnfollowDialog] = useState(false);
  const [followingDetail, setFollowingDetail] = useState();
  const [removeDialog, setRemoveDialog] = useState(false);
  const [followButtonDisable, setFollowButtonDisable] = useState(false);
  const [mediaDetails, setMediaDetails] = useState([]);
  const [imageCount, setImageCount] = useState([]);
  const [videoCount, setVideoCount] = useState([]);
  console.log(userDetails);
  useEffect(() => {
    dispatch(setSwitchProfileMode("profile"));
    dispatch(setScreenName("Profile"));
    if (match.params.profileId === undefined) {
      API.get("/user/getUserInfo").then((res) => {
        if (res.status === 200) {
          // setMediaDetails(JSON.parse())
          setMediaDetails(res.data.data.mediaDetails.filter((x) => x !== ""));
          let media = res.data.data.mediaDetails.filter((x) => x !== "");
          media !== undefined &&
            media.length > 0 &&
            media.map((c) => {
              const imageExtensionRegex =
                /\.(jpg|jpeg|png|gif|bmp|svg|JPG|JPEG)$/;
              const videoExtensionRegex = /\.(mp4|avi|mov|wmv|flv|mkv|MOV)$/;
              if (c.match(imageExtensionRegex)) {
                imageCount.push(c);
                setImageCount(imageCount);
              } else if (c.match(videoExtensionRegex)) {
                videoCount.push(c);
                setVideoCount(videoCount);
              } else {
              }
            });
          setFollowDetails(JSON.parse(res.data.data.followDetail));
          setProfileDetails(res.data.data.userDetail);
          dispatch(setEditProfileMode(true));
          setLoading(false);
        }
      });
    } else {
      API.get(`/user/getUserInfo/${match.params.profileId}`).then((res) => {
        if (res.status === 200) {
          setMediaDetails(res.data.data.mediaDetails.filter((x) => x !== ""));
          setFollowDetails(JSON.parse(res.data.data.followDetail));
          setProfileDetails(res.data.data.userDetail);
          dispatch(setEditProfileMode(false));
          setLoading(false);
        }
      });
    }
  }, [callUserDetail]);

  const followUser = () => {
    setFollowButtonDisable(true);
    let details = {
      userId: userDetails._id,
      userToFollow: profileDetails._id,
    };
    API.post("/user/userToFollow", details).then((res) => {
      if (res.status === 200) {
        dispatch(setCallUserApi(!callUserApi));
        setCallUserDetail(!callUserDetail);
        dispatch(
          setAlert({
            type: "success",
            title: "Success",
            subtitle: res.data.data,
            active: true,
          })
        );
        setFollowButtonDisable(false);
      }
    });
  };

  const unFollowUser = () => {
    let details = {
      userId: userDetails._id,
      userToUnFollow: profileDetails._id,
    };
    API.post("/user/userToUnFollow", details).then((res) => {
      if (res.status === 200) {
        dispatch(setCallUserApi(!callUserApi));
        setCallUserDetail(!callUserDetail);
        dispatch(
          setAlert({
            type: "success",
            title: "Success",
            subtitle: res.data.data,
            active: true,
          })
        );
      }
    });
  };

  const getFollowersList = () => {
    API.get("/user/getFollowersList/followerList").then((res) => {
      if (res.status === 200) {
        setOpenFollwersList(true);
        setFollowersList(res.data.data);
      }
    });
  };

  const getFollowingList = () => {
    API.get("/user/getFollowersList/followingList").then((res) => {
      if (res.status === 200) {
        setOpenFollowingList(true);
        setFollowingList(res.data.data);
      }
    });
  };

  const openConfirmation = (type, value) => {
    if (type === "unFollow") {
      setFollowingDetail(value);
      setUnfollowDialog(true);
    } else {
      setFollowingDetail(value);
      setRemoveDialog(true);
    }
  };

  const unFollowUsers = () => {
    let details = {
      userId: userDetails._id,
      userToUnFollow: followingDetail._id,
    };
    API.post("/user/userToUnFollow", details).then((res) => {
      if (res.status === 200) {
        dispatch(setCallUserApi(!callUserApi));
        setCallUserDetail(!callUserDetail);
        setUnfollowDialog(false);
        getFollowingList();
        dispatch(
          setAlert({
            type: "success",
            title: "Success",
            subtitle: res.data.data,
            active: true,
          })
        );
      }
    });
  };

  const removeUsers = () => {
    let details = {
      userId: userDetails._id,
      userToRemove: followingDetail._id,
    };
    console.log(removeUsers);
  };

  return (
    <>
      {/* Desktop */}
      {
        <motion.div className="md:block hidden relative mb-10">
          {loading ? (
            <Loaders />
          ) : (
            <>
              <motion.div
                className={`sticky top-0 w-full flex flex-row justify-between gap-3`}
                style={{
                  zIndex: openFollowersList || openFollowingList ? "" : 1000,
                }}
              >
                {/* Left div */}
                <motion.div
                  className={`relative grow flex flex-row justify-between bg-white`}
                  style={{ boxShadow: "15px 9px 11px -15px", height: "190px" }}
                >
                  <motion.div className={`relative bg-white w-1/3 px-4 py-4`}>
                    <motion.div
                      className={`relative flex flex-row justify-start gap-3`}
                    >
                      <motion.div className={`relative`}>
                        <img
                          src={
                            profileDetails && profileDetails.profileImage
                              ? profileDetails.profileImage
                              : profilePic
                          }
                          alt=""
                          // height={150}
                          // width={150}
                          style={{ height: '150px', width: '150px' }}
                        />
                        {editMode && (
                          <motion.div
                            className={`absolute top-2 cursor-pointer -right-2 rounded-full backdrop-blur-sm bg-white/30`}
                            style={{ height: "40px", width: "40px" }}
                            onClick={() => history.push("/editProfile")}
                          >
                            <FaPen className="text-white m-3" />
                          </motion.div>
                        )}
                      </motion.div>
                      <motion.div className={`grid grid-row-2 py-2 px-4`}>
                        <motion.div className={`flex flex-row justify-between`}>
                          <motion.div className={`sticky text-black text-2xl`}>
                            <motion.div className="flex flex-row flex-1 gap-10 justify-between">
                              {profileDetails &&
                                profileDetails.firstName !== null &&
                                profileDetails.firstName !== undefined &&
                                profileDetails.firstName !== "" &&
                                profileDetails.lastName !== null &&
                                profileDetails.lastName !== undefined &&
                                profileDetails.lastName !== "" ? (
                                <p>
                                  {profileDetails.firstName}&nbsp;
                                  {profileDetails.lastName}
                                </p>
                              ) : (
                                <p>{profileDetails.businessName}</p>
                              )}
                              {profileDetails &&
                                profileDetails._id !== userDetails._id && (
                                  <button
                                    disabled={followButtonDisable}
                                    className="bg-[#F5F5F5] cursor-pointer text-[#2593D2] px-5 py-1 rounded-full text-base drop-shadow-lg"
                                    onClick={() =>
                                      followDetail &&
                                        followDetail.followingList &&
                                        followDetail.followingList.length &&
                                        followDetail.followingList.filter(
                                          (y) => y.userId === profileDetails._id
                                        ).length > 0
                                        ? unFollowUser()
                                        : followUser()
                                    }
                                  >
                                    {followDetail &&
                                      followDetail.followingList &&
                                      followDetail.followingList.length &&
                                      followDetail.followingList.filter(
                                        (y) => y.userId === profileDetails._id
                                      ).length > 0
                                      ? "Unfollow"
                                      : "Follow"}
                                  </button>
                                )}
                            </motion.div>
                            {profileDetails && profileDetails.userName && (
                              <p className="text-sm">
                                @{profileDetails.userName}
                              </p>
                            )}
                          </motion.div>
                          {/* <motion.div className='cursor-pointer' onClick={() => setPlusMenu(!plusMenu)}>
                    <img alt="" src={plusIcon} height={70} width={70} />
                  </motion.div> */}
                          {plusMenu && (
                            <motion.div
                              className={"absolute top-14 -right-44"}
                              style={{ zIndex: 1000 }}
                            >
                              {plusMenu && (
                                <motion.div
                                  className={
                                    "relative flex flex-col drop-shadow bg-white ring ring-1 px-2 ring-[white] w-[200px]"
                                  }
                                >
                                  <motion.div
                                    className={
                                      "relative cursor-pointer text-black font-bold text-sm"
                                    }
                                  >
                                    Post
                                  </motion.div>
                                  <motion.div
                                    className={
                                      "relative cursor-pointer text-black font-bold text-sm"
                                    }
                                  >
                                    Video (TV)
                                  </motion.div>
                                  <motion.div
                                    className={
                                      "relative cursor-pointer text-black font-bold text-sm"
                                    }
                                  >
                                    Article
                                  </motion.div>
                                  <motion.div
                                    className={
                                      "relative cursor-pointer text-black font-bold text-sm"
                                    }
                                  >
                                    Event
                                  </motion.div>
                                  <motion.div
                                    className={
                                      "relative cursor-pointer text-black font-bold text-sm"
                                    }
                                  >
                                    Go Live
                                  </motion.div>
                                  <motion.div
                                    className={
                                      "relative flex flex-row justify-start gap-2 cursor-pointer text-black font-bold text-sm"
                                    }
                                  >
                                    <motion.div className="bg-[#2593D2] p-2">
                                      <FaPlus className="text-white m-[1px]" />
                                    </motion.div>
                                    <motion.div>Create a shop page</motion.div>
                                  </motion.div>
                                  <motion.div
                                    className={
                                      "relative flex flex-row justify-start mt-2 gap-2 cursor-pointer text-black font-bold text-sm"
                                    }
                                  >
                                    <motion.div className="bg-[#2593D2] p-2">
                                      <FaPlus className="text-white m-[1px]" />
                                    </motion.div>
                                    <motion.div>
                                      Create a channel page
                                    </motion.div>
                                  </motion.div>
                                </motion.div>
                              )}
                            </motion.div>
                          )}
                        </motion.div>
                        <motion.div className="text-md text-black">
                          I'm Into{" "}
                          {profileDetails &&
                            profileDetails.preferenceTypeId &&
                            profileDetails.preferenceTypeId === "1" ? (
                            <span className="text-[#2593D2] font-bold">
                              Agriculture
                            </span>
                          ) : (
                            <span className="text-[#2593D2] font-bold">
                              Aquaculture
                            </span>
                          )}{" "}
                          {profileDetails &&
                            profileDetails.designation &&
                            `& I'm an `}{" "}
                          <span className="text-[#2593D2] font-bold">
                            {profileDetails && profileDetails.designation}
                          </span>
                        </motion.div>
                        <motion.div className=" flex flex-row justify-start">
                          {profileDetails && profileDetails.isPhoneEnable && (
                            <img
                              src={phoneIcon}
                              height={30}
                              width={30}
                              alt=""
                            />
                          )}
                          {profileDetails && profileDetails.isEmailEnable && (
                            <img
                              src={emailIcon}
                              height={30}
                              width={30}
                              alt=""
                            />
                          )}
                          <img src={msgIcon} height={30} width={30} alt="" />
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className={`relative flex flex-row`}>
                    <motion.div
                      class="absolute  overflow-hidden inline-block"
                      style={{ width: "190px" }}
                    >
                      <motion.div
                        class=" bg-white rotate-45 transform origin-top-right"
                        style={{ height: "330px" }}
                      ></motion.div>
                    </motion.div>
                    <img
                      src={
                        profileDetails &&
                          profileDetails &&
                          profileDetails.coverImage &&
                          profileDetails.coverImage !== null
                          ? profileDetails.coverImage
                          : coverPng
                      }
                      style={{ height: "190px", width: "300px" }}
                      alt=""
                    />
                    <motion.div
                      class="absolute bottom-0 right-0 overflow-hidden inline-block"
                      style={{ width: "190px" }}
                    >
                      <motion.div
                        class="bg-white rotate-45 transform origin-bottom-left"
                        style={{ height: "330px" }}
                      ></motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="relative w-1/3 flex flex-row justify-start"
                    style={{ height: "170px" }}
                  >
                    <motion.div className="relative w-full flex flex-col">
                      <motion.div className="absolute top-0 right-10 mt-2 mb-2 flex flex-row px-2 py-4 justify-between drop-shadow-lg rounded-lg w-[200px]">
                        <motion.div
                          class
                          Name="flex flex-col text-center"
                          onClick={() =>
                            followDetails &&
                              followDetails.followersList &&
                              followDetails.followersList.length > 0
                              ? getFollowersList()
                              : ""
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <motion.div className="text-xl text-center text-[#2593D2]">
                            {followDetails &&
                              followDetails.followersList &&
                              followDetails.followersList.length > 0
                              ? followDetails.followersList.length
                              : 0}
                          </motion.div>
                          <motion.div className="text-md text-black">
                            Followers
                          </motion.div>
                        </motion.div>
                        <motion.div
                          className="flex flex-col text-center"
                          onClick={() =>
                            followDetails &&
                              followDetails.followingList &&
                              followDetails.followingList.length > 0
                              ? getFollowingList()
                              : ""
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <motion.div className="text-xl text-center text-[#2593D2]">
                            {followDetails &&
                              followDetails.followingList &&
                              followDetails.followingList.length > 0
                              ? followDetails.followingList.length
                              : 0}
                          </motion.div>
                          <motion.div className="text-md text-black">
                            Following
                          </motion.div>
                        </motion.div>
                      </motion.div>
                      <motion.div className="absolute bottom-0 right-10 text-black flex flex-row justify-evenly">
                        {/* <motion.div className="cursor-pointer mr-10">
                          <img alt="" src={hamIcon} height={40} width={40} />
                        </motion.div> */}
                        <motion.div className="relative mt-2">
                          <ProfilePostSwitch />
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
                {/* Right div */}
              </motion.div>

              {isProfileMode === "profile" ? (
                <motion.div className="relative min-h-screen mt-1 mb-2 px-4">
                  {/* <Modal isOpen={isOpen} onRequestClose={toggleModal} className="w-full" style={{
              content: {
                width: '70%',
                margin: 'auto',
                top: '50%',
                left: '50%',
                borderRadius: '10px',
                border: 'none',
                background: '#fff',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                outline: 'none',
                marginTop: '35px'
              },
              overlay: {
                zIndex: '1000',
                backgroundColor: 'rgba(0, 0, 0, 0.7)'
              }
            }}>
              <motion.div className="modal-content relative">
                <motion.div className="relative top-0 left-0 right-0 flex justify-center p-4" style={{ borderBottom: "2px solid" }}>
                  <h2 className="text-lg font-bold text-center w-full">Followers</h2>
                  <motion.div className={`relative cursor-pointer rounded-full bg-[#000]/30 ml-auto`} style={{ height: '30px', width: '30px' }} onClick={toggleModal}>
                    <GrFormClose className="text-white text-sm m-[8px]" />
                  </motion.div>
                </motion.div>
                <motion.div className="flex flex-col md:drop-shadow-xl md:rounded-3xl bg-white mt-2 md:px-2 md:py-4 h-full">
                  {followers.map((follower) => (
                    <motion.div key={follower.id} className="flex flex-row right-2 mt-2" style={{ justifyContent: "space-between" }}>
                      <motion.div className="flex mt-1" style={{ marginLeft: "10px" }}>
                        <img
                          src={follower.profilePic}
                          alt={`${follower.username}'s profile picture`}
                          className="rounded-full w-16 h-16 object-cover mb-2 shadow-lg"
                        />
                        <motion.div className="items-center mt-1 ml-3">
                          <h2 className="text-lg font-bold mr-2">{follower.username}</h2>
                          <p className="text-gray-500">{follower.name}</p>
                        </motion.div>
                      </motion.div>
                      <motion.div className="flex items-center mt-2 ml-3">
                        <motion.div className={`relative cursor-pointer bg-[#E4E4E4] mr-2 mt-2`} style={{ height: '30px', width: '30px', borderRadius: "4px" }} >
                          <BsChatLeftDotsFill className="text-black text-sm m-[8px]" />
                        </motion.div>
                        <button className="bg-[#E4E4E4]  px-4 py-1 mt-2 rounded-md">
                          {follower.isFollowing ? 'Following' : 'Remove'}
                        </button>
                      </motion.div>
                    </motion.div>
                  ))}
                </motion.div>
              </motion.div>
              <style>{`:focus { outline: none; }`}</style>
            </Modal> */}

                  <motion.div
                    className={`relative grid grid-cols-2 gap-3 mt-2 mb-2`}
                  >
                    <motion.div className={`relative`}>
                      <About about={profileDetails.about} edit={editMode} />
                    </motion.div>
                    <motion.div className={`relative`}>
                      <Professional
                        edit={editMode}
                        professional={JSON.parse(profileDetails.professionals)}
                        general={profileDetails}
                      />
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className={"relative grid grid-cols-2 gap-3 mt-2 mb-2"}
                  >
                    <motion.div className={`relative`}>
                      <Experience
                        edit={editMode}
                        exp={JSON.parse(profileDetails.experiences)}
                      />
                    </motion.div>
                    <motion.div className={`relative`}>
                      <Education
                        edit={editMode}
                        edu={JSON.parse(profileDetails.educations)}
                      />
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className={"relative grid grid-cols-2 gap-3 mt-2 mb-2"}
                  >
                    <motion.div className={"relative grid grid-rows-1 gap-3"}>
                      <motion.div className={"row-end-2"}>
                        <motion.div className={`relative`}>
                          <License
                            edit={editMode}
                            license={JSON.parse(profileDetails.licenses)}
                          />
                        </motion.div>
                        <motion.div className={`relative mt-5`}>
                          <Projects
                            edit={editMode}
                            project={JSON.parse(profileDetails.projects)}
                          />
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className={`relative grid grid-rows-1 gap-3`}>
                      <motion.div className={`relative grid grid-cols-2 gap-3`}>
                        <motion.div className="relative h-32">
                          <Language
                            edit={editMode}
                            lang={JSON.parse(profileDetails.languages)}
                          />
                        </motion.div>
                        <motion.div className="relative h-32">
                          <Skills
                            edit={editMode}
                            skill={JSON.parse(profileDetails.skills)}
                          />
                        </motion.div>
                        <motion.div className={`relative col-span-2`}>
                          <Media
                            mediaDetails={mediaDetails}
                            imageCount={imageCount}
                            videoCount={videoCount}
                          />
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              ) : (
                <motion.div className="relative min-h-screen mt-1 mb-2 px-4">
                  <motion.div className="grid grid-rows-1 gap-5 px-4">
                    <Posts
                      imageCount={imageCount}
                      videoCount={videoCount}
                      profileId={
                        editMode ? userDetails._id : match.params.profileId
                      }
                    />
                  </motion.div>
                </motion.div>
              )}
            </>
          )}
        </motion.div>
      }
      {/* Mobile */}

      <motion.div
        className={`md:hidden block relative  bg-[lightgrey] overflow-hidden mb-20`}
      >
        {loading ? (
          <Loaders />
        ) : (
          <>
            <motion.div className={`relative grid grid-rows-2`}>
              {/* Top side */}
              <motion.div
                className={`w-full flex flex-row bg-white justify-between gap-3`}
              >
                <motion.div className={`relative flex flex-row bg-white`}>
                  <motion.div className={`relative bg-white px-4 py-4`}>
                    <img
                      src={
                        profileDetails && profileDetails.profileImage
                          ? profileDetails.profileImage
                          : profilePic
                      }
                      alt=""
                      height={110}
                      width={110}
                    />
                    {editMode && (
                      <motion.div
                        className={`absolute top-2 -right-2 rounded-full backdrop-blur-sm bg-white/30`}
                        style={{ height: "40px", width: "40px" }}
                        onClick={() => history.push("/editProfile")}
                      >
                        <FaPen className="text-black m-3" />
                      </motion.div>
                    )}
                  </motion.div>
                  <motion.div className={`relative grid grid-rows-2 ml-2`}>
                    <motion.div
                      className={`sticky flex flex-row mt-5 justify-between bg-white`}
                    >
                      <motion.div className={`relative flex flex-col`}>
                        <motion.div className={`text-xl text-black`}>
                          <p>
                            {profileDetails.firstName}&nbsp;
                            {profileDetails.lastName}
                          </p>
                        </motion.div>
                        <motion.div className={`text-md text-black`}>
                          {profileDetails && profileDetails.userName && (
                            <p className="text-sm">
                              @{profileDetails.userName}
                            </p>
                          )}
                        </motion.div>
                      </motion.div>
                      {/* <motion.div className={`sticky cursor-pointer`} onClick={() => setPlusMenu(!plusMenu)}>
                  <img alt="" src={plusIcon} height={50} width={50} />
                </motion.div> */}
                      {plusMenu && (
                        <motion.div
                          className={"absolute bg-white top-8 right-16 z-10"}
                          style={{ zIndex: 1000 }}
                        >
                          {plusMenu && (
                            <motion.div
                              className={
                                "relative bg-white flex flex-col drop-shadow bg-white ring ring-1 px-2 ring-[white] w-[200px]"
                              }
                            >
                              <motion.div
                                className={
                                  "relative cursor-pointer text-black font-bold text-sm"
                                }
                              >
                                Post
                              </motion.div>
                              <motion.div
                                className={
                                  "relative cursor-pointer text-black font-bold text-sm"
                                }
                              >
                                Video (TV)
                              </motion.div>
                              <motion.div
                                className={
                                  "relative cursor-pointer text-black font-bold text-sm"
                                }
                              >
                                Article
                              </motion.div>
                              <motion.div
                                className={
                                  "relative cursor-pointer text-black font-bold text-sm"
                                }
                              >
                                Event
                              </motion.div>
                              <motion.div
                                className={
                                  "relative cursor-pointer text-black font-bold text-sm"
                                }
                              >
                                Go Live
                              </motion.div>
                              <motion.div
                                className={
                                  "relative flex flex-row justify-start gap-2 cursor-pointer text-black font-bold text-sm"
                                }
                              >
                                <motion.div className="bg-[#2593D2] p-2">
                                  <FaPlus className="text-white m-[1px]" />
                                </motion.div>
                                <motion.div>Create a shop page</motion.div>
                              </motion.div>
                              <motion.div
                                className={
                                  "relative flex flex-row justify-start mt-2 gap-2 cursor-pointer text-black font-bold text-sm"
                                }
                              >
                                <motion.div className="bg-[#2593D2] p-2">
                                  <FaPlus className="text-white m-[1px]" />
                                </motion.div>
                                <motion.div>Create a channel page</motion.div>
                              </motion.div>
                            </motion.div>
                          )}
                        </motion.div>
                      )}
                    </motion.div>
                    <motion.div className="text-sm text-black">
                      I'm Into{" "}
                      {profileDetails &&
                        profileDetails.preferenceTypeId &&
                        profileDetails.preferenceTypeId === "1" ? (
                        <span className="text-[#2593D2] font-bold">
                          Agriculture
                        </span>
                      ) : (
                        <span className="text-[#2593D2] font-bold">
                          Aquaculture
                        </span>
                      )}{" "}
                      {profileDetails &&
                        profileDetails.designation &&
                        `& I'm an `}{" "}
                      <span className="text-[#2593D2] font-bold">
                        {profileDetails && profileDetails.designation}
                      </span>
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div className={`relative flex flex-row`}>
                  <motion.div
                    class="absolute  overflow-hidden inline-block"
                    style={{ width: "100px" }}
                  >
                    <motion.div
                      class=" bg-white rotate-45 transform origin-top-right"
                      style={{ height: "330px" }}
                    ></motion.div>
                  </motion.div>
                  <img
                    src={
                      profileDetails &&
                        profileDetails &&
                        profileDetails.coverImage &&
                        profileDetails.coverImage !== null
                        ? profileDetails.coverImage
                        : coverPng
                    }
                    style={{ height: "100px", width: "250px" }}
                    alt=""
                  />
                  <motion.div
                    class="absolute bottom-0 right-0 overflow-hidden inline-block"
                    style={{ width: "135px" }}
                  >
                    <motion.div
                      class="bg-white rotate-45 transform origin-bottom-left"
                      style={{ height: "330px" }}
                    ></motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
              {/* Bottom Side */}
              <motion.div
                className={`w-full bg-white px-2 m-0 flex flex-row justify-between`}
              >
                <motion.div className=" flex flex-row justify-start">
                  {profileDetails && profileDetails.isPhoneEnable && (
                    <motion.div style={{ height: "50px", width: "50px" }}>
                      <img src={phoneIcon} alt="" />
                    </motion.div>
                  )}
                  {profileDetails && profileDetails.isEmailEnable && (
                    <motion.div style={{ height: "50px", width: "50px" }}>
                      <img src={emailIcon} alt="" />
                    </motion.div>
                  )}
                  <motion.div style={{ height: "50px", width: "50px" }}>
                    <img src={msgIcon} alt="" />
                  </motion.div>
                </motion.div>
                <motion.div style={{ height: "fit-content !important" }}>
                  <motion.div
                    className={`relative mt-2 mb-2 flex flex-row ${!plusMenu && "bg-white"
                      } px-2 py-4 justify-between drop-shadow-lg rounded-lg w-[150px]`}
                  >
                    <motion.div className="flex flex-col text-center">
                      <motion.div
                        className="text-md text-center text-[#2593D2] cursor-pointer"
                        onClick={() =>
                          followDetails &&
                            followDetails.followersList &&
                            followDetails.followersList.length > 0
                            ? getFollowersList()
                            : ""
                        }
                      >
                        {followDetails &&
                          followDetails.followersList &&
                          followDetails.followersList.length > 0
                          ? followDetails.followersList.length
                          : 0}
                      </motion.div>
                      <motion.div className="text-sm text-black">
                        Followers
                      </motion.div>
                    </motion.div>
                    <motion.div className="flex flex-col text-center">
                      <motion.div
                        className="text-md text-center text-[#2593D2] cursor-pointer"
                        onClick={() =>
                          followDetails &&
                            followDetails.followingList &&
                            followDetails.followingList.length > 0
                            ? getFollowingList()
                            : ""
                        }
                      >
                        {followDetails &&
                          followDetails.followingList &&
                          followDetails.followingList.length > 0
                          ? followDetails.followingList.length
                          : 0}
                      </motion.div>
                      <motion.div className="text-sm text-black">
                        Following
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div className="text-black flex flex-col px-2 py-2">
                  {/* <motion.div className="cursor-pointer mb-2">
              <img alt="" src={hamIcon} height={40} width={40} />
            </motion.div> */}
                  <motion.div className="relative mt-2">
                    <ProfilePostSwitch />
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
            {isProfileMode === "profile" ? (
              <motion.div className="relative min-h-screen overflow-auto">
                <motion.div className={`relative grid grid-cols-1 gap-2`}>
                  <motion.div className={`relative`}>
                    <About edit={editMode} about={profileDetails.about} />
                  </motion.div>
                  <motion.div className={`relative`}>
                    <Experience
                      edit={editMode}
                      exp={JSON.parse(profileDetails.experiences)}
                    />
                  </motion.div>
                  <motion.div className={`relative`}>
                    <License
                      edit={editMode}
                      license={JSON.parse(profileDetails.licenses)}
                    />
                  </motion.div>
                  <motion.div className={`relative`}>
                    <Projects
                      edit={editMode}
                      project={JSON.parse(profileDetails.projects)}
                    />
                  </motion.div>
                  <motion.div className={`relative`}>
                    <Professional
                      edit={editMode}
                      professional={JSON.parse(profileDetails.professionals)}
                      general={profileDetails}
                    />
                  </motion.div>
                  <motion.div className={`relative`}>
                    <Education
                      edit={editMode}
                      edu={JSON.parse(profileDetails.educations)}
                    />
                  </motion.div>
                  <motion.div className={`relative`}>
                    <Skills
                      edit={editMode}
                      skill={JSON.parse(profileDetails.skills)}
                    />
                  </motion.div>
                  <motion.div className={`relative`}>
                    <Language
                      edit={editMode}
                      lang={JSON.parse(profileDetails.languages)}
                    />
                  </motion.div>
                  <motion.div className={`relative`}>
                    <Media
                      mediaDetails={mediaDetails}
                      imageCount={imageCount}
                      videoCount={videoCount}
                    />
                  </motion.div>
                </motion.div>
              </motion.div>
            ) : (
              <motion.div className="relative min-h-screen mt-1 mb-2 px-4">
                <motion.div className="grid grid-rows-1 gap-5 px-1">
                  <Posts
                    imageCount={imageCount}
                    videoCount={videoCount}
                    profileId={
                      editMode ? userDetails._id : match.params.profileId
                    }
                  />
                </motion.div>
              </motion.div>
            )}
          </>
        )}
      </motion.div>
      <Dialog
        title={"Followers"}
        showDialog={openFollowersList}
        onClose={() => setOpenFollwersList(false)}
      >
        <div className="py-2 w-full">
          {followersList && followersList.length && followersList.length > 0
            ? followersList.map((c) => {
              return (
                <>
                  {c !== null && (
                    <div className="px-2 mt-2 flex flex-row justify-between">
                      <div className="flex flex-row justify-start">
                        <img
                          src={c.profileImage ? c.profileImage : profilePic}
                          height={50}
                          width={50}
                          className="rounded-full"
                        />
                        <div className="flex flex-col px-2 m-1">
                          <div className="text-black text-md">
                            {c.firstName} {c.lastName && c.lastName}
                          </div>
                          <div className="text-[grey] text-sm">
                            {c.userName}
                          </div>
                        </div>
                      </div>
                      {/* <div className='flex flex-row justify-around'>
                  <FiMessageCircle className='text-black relative mt-2 mb-2 mr-2 ml-2' />
                  <div className="bg-[grey] h-fit px-2 py-1 mr-2 ml-2 rounded-full text-black text-sm cursor-pointer" onClick={()=>openConfirmation('unFollow',c)}>
                    Unfollow
                  </div>
                </div> */}
                    </div>
                  )}
                </>
              );
            })
            : "No Followers List"}
        </div>
      </Dialog>
      <Dialog
        title={"Following"}
        injectClass={"w-full"}
        showDialog={openFollowingList}
        onClose={() => setOpenFollowingList(false)}
      >
        <div className="py-2 w-full">
          {followingList && followingList.length && followingList.length > 0
            ? followingList.map((c) => {
              return (
                <>
                  {c !== null && (
                    <div className="px-2 mt-2 flex flex-row justify-between">
                      <div className="flex flex-row justify-start">
                        <img
                          src={c.profileImage ? c.profileImage : profilePic}
                          height={50}
                          width={50}
                          className="rounded-full"
                        />
                        <div className="flex flex-col px-2 m-1">
                          <div className="text-black text-md">
                            {c.firstName} {c.lastName && c.lastName}
                          </div>
                          <div className="text-[grey] text-sm">
                            {c.userName}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row justify-around">
                        <FiMessageCircle className="text-black relative mt-2 mb-2 mr-2 ml-2" />
                        <div
                          className="bg-[grey] h-fit px-2 py-1 mr-2 ml-2 rounded-full text-black text-sm cursor-pointer"
                          onClick={() => openConfirmation("unFollow", c)}
                        >
                          Unfollow
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })
            : "No Following List"}
        </div>
      </Dialog>
      <Dialog
        title={"Confirmation"}
        injectClass={"w-[450px]"}
        showDialog={unfollowDialog}
        onClose={() => setUnfollowDialog(false)}
      >
        <div className="py-2 w-full">
          <div className="px-2 mt-2 flex flex-col items-center">
            <div className="rounded-full">
              <img
                src={
                  followingDetail && followingDetail.profileImage
                    ? followingDetail.profileImage
                    : profilePic
                }
                height={50}
                width={50}
                className="rounded-full"
              />
            </div>
            {/* <div className="text-black text-md">{followingDetail && followingDetail.firstName} {followingDetail && followingDetail.lastName && followingDetail.lastName}</div> */}
            <div className="flex flex-row justify-start mt-2">
              <div className="text-black font-bold text-md mr-2 mt-[0.5]">
                Unfollow{" "}
              </div>
              <div className="mr-2 text-[grey] text-sm mt-1">
                @{followingDetail && followingDetail.userName}
              </div>
            </div>
            <div className="flex flex-col w-full mt-5">
              <hr />
              <div
                className="px-2 py-2 mr-2 ml-2 items-center text-center text-[red] text-md font-bold cursor-pointer"
                onClick={() => unFollowUsers()}
              >
                Unfollow
              </div>
              <hr />
              <div
                className="px-2 py-1 mr-2 ml-2 items-center text-center text-black text-sm cursor-pointer"
                onClick={() => setUnfollowDialog(false)}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        title={"Confirmation"}
        injectClass={"w-[450px]"}
        showDialog={removeDialog}
        onClose={() => setRemoveDialog(false)}
      >
        <div className="py-2 w-full">
          <div className="px-2 mt-2 flex flex-col items-center">
            <div className="rounded-full">
              <img
                src={
                  followingDetail && followingDetail.profileImage
                    ? followingDetail.profileImage
                    : profilePic
                }
                height={50}
                width={50}
                className="rounded-full"
              />
            </div>
            {/* <div className="text-black text-md">{followingDetail && followingDetail.firstName} {followingDetail && followingDetail.lastName && followingDetail.lastName}</div> */}
            <div className="flex flex-row justify-start mt-2">
              <div className="text-black font-bold text-md mr-2 mt-[0.5]">
                Remove Follower ?
              </div>
            </div>
            <div className="text-sm text-black">
              Aquaagriface won't tell @
              {followingDetail && followingDetail.userName} they were removed
              from your followers
            </div>
            <div className="flex flex-col w-full mt-5">
              <hr />
              <div
                className="px-2 py-2 mr-2 ml-2 items-center text-center text-[red] text-md font-bold cursor-pointer"
                onClick={() => removeUsers()}
              >
                Remove
              </div>
              <hr />
              <div
                className="px-2 py-1 mr-2 ml-2 items-center text-center text-black text-sm cursor-pointer"
                onClick={() => setRemoveDialog(false)}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Profile;
