import React from 'react';
import {motion} from 'framer-motion'
import campScreen from '../assets/Icons/campaingScreen.png'

const Campaign = ( ) =>{
    return (
        <motion.div className="relative min-h-screen items-center flex flex-col overflow-hidden text-center justify-center">
                <img src={campScreen} className="h-96 w-96"/>
                <motion.div className="text-5xl font-bold text-black">
                Exciting news on the way! We have a major announcement coming soon that you won't want to miss.
                </motion.div>
        </motion.div>
    )
}

export default Campaign